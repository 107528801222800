import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@app/_helpers/api-constants';
import { EncrDecrService, MangoApiService, BreadcrumbService, mangoUtils } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "@environments/environment";
import * as AWS from "aws-sdk";
import Swal from 'sweetalert2';

import { AWSBucketMask } from '@app/_helpers/aws-bucket-mask';

@Component({
  selector: 'app-tax-imports',
  templateUrl: './tax-imports.component.html'
})

export class TaxImportsComponents implements OnInit  {
    public companyId: any;
    public formDisabled: boolean = false;
    public dashboardSettingsForm: UntypedFormGroup;
    public importList: any = [];
    public typeReturnList: any = [];
    public files: File[] = [];
    public showAttachedFiles: boolean = false;
    public selectedType: any = null;
    public selectedReturnType: any = null;
    public csvData: any[] = [];
    public csvItem: any = {};
    public filteredItemsSize: number = -1;
    public awsBucket: any;
    public selectedFileType: any = null;
    public showOptions: boolean = false;
    public clientOption: any = null;
    public yearList: any = [];
    public staffList: any = [];
    public cTypeList: any = [];
    public engagementsTypes: any = [];
    public taxMapping: any = [];
    public taxTblMapping: any = [];
    public showTaxMapping: boolean = false;
    TaxOptionsForm: UntypedFormGroup;
    @ViewChild("fileUpload") fileUpload: any;

    constructor (private fb: UntypedFormBuilder,
        private breadcrumbService: BreadcrumbService,
        private encrDecSrvc: EncrDecrService,
        private mangoAPISrvc: MangoApiService,
        private mangoUtils: mangoUtils,
        private translate: TranslateService) {
        this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);

        this.breadcrumbService.setItems([
          { label: 'Tax Imports', icon: "ic-red" },
        ]);

        this.importList = [
          { label: "ProSeries", value: "ProSeries", fileName: 'ProSeries Export.csv' },
          { label: "Lacerte", value: "Lacerte", fileName: 'Lacerte Export.csv' },
          { label: "Drake", value: "Drake", fileName: 'Drake Export.csv' },
          { label: "UltraTax", value: "UltraTax",  fileName: 'UltraTax Export.csv' },
          { label: "ATX", value: "ATX",  fileName: 'ATX Export.csv' },
        ];

        this.typeReturnList = [
          { label: "1040", value: "1040"},
          { label: "1120", value: "1120"},
          { label: "1120S", value: "1120S"},
          { label: "1065", value: "1065"},
          { label: "1041", value: "1041"},
        ]

        this.yearList = this.getYears(6);
        this.setAWSOBject();
        this.initializeForm();
    }

    ngOnInit(): void {
      this.getEngagements();
      this.initLists();
    }

    initLists(): void {
      this.staffList = this.encrDecSrvc.getObject(AppConstants.staffList);
      this.staffList = this.staffList.filter((staff) => staff.Inactive == false).map((staff) => {
        return { ...staff, label: staff.StaffName, value: staff.StaffID}
      })
      this.staffList.unshift({ label: "None", value: null })
      this.cTypeList = this.encrDecSrvc.getObject(AppConstants.customerType);
      this.cTypeList = this.cTypeList.map((type) => {
        return { ...type, label: type.CustomerTypeDescription, value: type.CustomerTypeID}
      })
      this.cTypeList.unshift({ label: "None", value: null })
    }

    initializeForm(){
      this.TaxOptionsForm = this.fb.group({
        ClientOptions: ["AddOnly"],
        InvoiceOptions: ["InvoiceNoAdd"],
        SearchClientOption: ["SearchByClientName"],
        SelectedEngagements: [null],
        SelectedYear: [null],
        BillingPartner: [null],
        OrigPartner: [null],
        StaffAssignedID: [null],
        ClientType: [null],
        InvoiceDescription: [null],
        isReleaseTimeExpense: [false]
      });
    }

    getEngagements() {
      const parent = this;
      parent.mangoAPISrvc
        .getEngagementTypes(this.companyId)
        .subscribe(function (data: any) {

          const list = data.filter(
            (invoiceGroup) => invoiceGroup.Inactive == false
          );
          for (let i = 0; i < list.length; i++) {
            const item = list[i];

            parent.engagementsTypes.push({
              label: item.Description,
              value: item.EngagementTypeID,
              EngagementTypeID: item.EngagementTypeID,
              InvoiceDesc: item.InvoiceDescription
            });
          }
          parent.engagementsTypes.sort(
            parent.mangoUtils.compareValues("label", "asc")
          );
        }),
        (error) => {
          parent.mangoAPISrvc.notify(
            "error",
            this.translate.instant("error"),
            AppConstants.updateErrorMsg
          );
          parent.mangoAPISrvc.showLoader(false);
        };
    }

    getYears(backYears){
      const year = new Date().getFullYear() + 2;
      const arr = Array.from({length: backYears}, (v, i) => year - backYears + i + 1).sort().reverse();
      const mapYear = arr.map(itm=>{return {value: itm, label: itm.toString()}});
      return mapYear;
    }

    setAWSOBject() {
      /*;
        @note:
          Disable usage of aws credential, transfer flow to the backend.
        @note;

        @disable-aws-credential
      */
      /*;
      AWS.config.region = environment.AWS_REGION;
      this.awsBucket = new AWS.S3({
        params: { Bucket: environment.AWS_BUCKET_NAME },
      });
      */
      this.awsBucket = (
        AWSBucketMask
      );
    }
    onSelectedEngagement(opt){
      const dropdown = document?.querySelector('#itemEngagementSelected');
      const selectedEngagement = this.engagementsTypes.filter(itm=> itm.EngagementTypeID === opt.value);
      if(selectedEngagement.length > 0){
        if (dropdown) {
          const dropdownLabel = dropdown.querySelector('.p-dropdown-label');
          if (dropdownLabel) {
            dropdownLabel.textContent = selectedEngagement[0].label;
            this.TaxOptionsForm.controls['InvoiceDescription'].setValue(selectedEngagement[0].InvoiceDesc);
            this.TaxOptionsForm.controls['SelectedEngagements'].setValue(selectedEngagement[0].EngagementTypeID);
          }
        }
      }
    }

    selectType(opt) {
      const importType = this.importList.filter((type: any) => type.value === opt.value);
      if(this.selectedType == 'ProSeries'){
        this.typeReturnList = [
          { label: "1040", value: "1040"},
          { label: "1120", value: "1120"},
          { label: "1120S", value: "1120S"},
          { label: "1065", value: "1065"},
          { label: "1041", value: "1041"},
        ]
      }
      else if(this.selectedType == 'Lacerte'){
        this.typeReturnList = [
          { label: "1040", value: "1040"},
          { label: "1120", value: "1120"},
          { label: "1120S", value: "1120S"},
          { label: "1065", value: "1065"},
        ]
      }
      else if(this.selectedType == 'Drake'){
        this.typeReturnList = [
          { label: "1040", value: "1040"},
          { label: "1120", value: "1120"},
          { label: "1120S", value: "1120S"},
          { label: "1065", value: "1065"},
        ]
      }
      else{
        this.typeReturnList = [];
      }

      this.selectedReturnType = this.typeReturnList.length > 0 ? this.typeReturnList[0].value : null;
      this.selectedFileType = importType[0]['fileName'];

      this.reloadListing(this.selectedType);
      this.reloadMapping(this.selectedType);
      this.initializeForm();
    }

    onRemove(event) {
      this.files.splice(this.files.indexOf(event), 1);
    }

    showFiles() {
      Swal.fire({
        title: this.translate.instant("warning"),
        html: this.translate.instant('imports.warning_duplicate_names_alert'),
        icon: "warning",
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant("OK"),
        cancelButtonText: this.translate.instant("no_cancel"),
      }).then((result) => {
        if (result.value) {
          this.showAttachedFiles = !this.showAttachedFiles;
        }
      });
    }

    showOptionSetting(){
      this.showOptions = !this.showOptions;
    }

    async readCSVFile(excelFile: File) {
      return new Promise<string[]>((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(excelFile);
        reader.onload = (e) => {
            const csvSeparator = ','
            const csv: string = reader.result as string;
            const allCols = [];
            const lines = csv.split('\n');
            lines.forEach((element, idx) => {
              if(idx === 0){
                const cols: string[] = element.split(csvSeparator);
                allCols.push(...cols);
              }
            });
            if(allCols.length > 0) resolve(allCols);
        }
      });
    }

    displayTaxMapping() {
      if(!this.selectedType)
        return [];
      const taxvendor = this.selectedType.toUpperCase();
      let map = [...this.taxMapping];
      if(taxvendor !== 'ULTRATAX' && taxvendor !== 'ATX')
        map = map.filter((mapping) => mapping.ReturnType == this.selectedReturnType)

      if(!map[0])
        return [];

      const ret = Object.values(map[0]?.JSONMap)
      const headers = Object.keys(map[0]?.JSONMap)
      const required = map[0]?.JSONMap.Required
      this.taxTblMapping = this.mangoUtils.deduplicateArray(ret)
        ?.map((map: string, index) => {
          return { label: headers[index], value: map, required: required.includes(map) };
        })
        .filter((map) => map.label !== "Required");
    }

    async onSelect(event) {
      const self = this;
      const listOfAcceptedFiles = ["csv"].toString();
      const filePos = event.files[0].name.lastIndexOf(".");
      const fileType = event.files[0].name.substring(
        filePos + 1,
        event.files[0].name.length
      );
      const columns = await this.readCSVFile(event.files[0]);
      const missingCols = this.checkCSVMapping(columns, this.selectedReturnType, this.taxMapping);

      if(missingCols.length > 0 ){
        self.fileUpload.clear()
        self.showFiles();
        Swal.fire({
          icon: "error",
          title: `${this.translate.instant('taximports.missing_fields_title')}`,
          showCancelButton: false,
          allowEscapeKey: true,
          allowEnterKey: true,
          confirmButtonText: "OK",
          showDenyButton: true,
          denyButtonText: "Show Mapping",
          customClass: {
            actions: "swal-align-buttons"
          },
          html: this.generateMessage(missingCols)
        }).then((result)=>{
          if(result.isDenied) {
            this.showTaxMapping = true;
            this.displayTaxMapping();
          }
        });
      }
      else{
        this.files.push(...event.files);
        for (let index = 0; index < this.files.length; index++) {
          const file = this.files[index];
          setTimeout(() => {
            this.uploadFile(file);
          }, 200);
        }
      }
    }

    generateMessage(cols){
      let item = '<div style="text-align: left;">';
      item += this.translate.instant('taximports.missing_fields_body') + "<br/><br/>";

      for(let i = 0; i < cols.length; i++){
        item += cols[i] + "<br/>";
      }

      item += '</div>'
      return item;
    }

    checkCSVMapping(columns, returnType, mapping){

      const taxvendor = this.selectedType.toString().toUpperCase();
      const filteredMapping = (taxvendor === "ATX" || taxvendor === "ULTRATAX") ? mapping : mapping.filter(itm => itm.ReturnType === returnType);
      let missingCols = [];

      const jsonFields: any[] = filteredMapping.length > 0 ? filteredMapping[0]["JSONMap"]["Required"] : [];
      Object.keys(jsonFields).forEach((k)=>{

        const isMissing = columns.filter(itm=>{return itm.toString().toUpperCase().replace("\r", "") === jsonFields[k].toString().toUpperCase().replace("\r", "")});
        if(isMissing.length === 0){
          missingCols.push(jsonFields[k]);
        }
      });

      if (taxvendor === "ATX") {
        if (
          (missingCols.includes("Company Name") &&
            !missingCols.includes("First Name") &&
            !missingCols.includes("Last Name")) ||
          (!missingCols.includes("Company Name") &&
            missingCols.includes("First Name") &&
            missingCols.includes("Last Name"))
        ) {
          missingCols = missingCols.filter(
            (col) =>
              col !== "Company Name" &&
              col !== "First Name" &&
              col !== "Last Name"
          );
        }
      }
      return missingCols;
    }

    async uploadFile(fileObj) {
      const self = this;
      //let defaultObj = { "FName": "", "UniqueName": "", "Size": "", "FileType": "" };
      const UniqueName = this.mangoUtils.generateUUID() + "_" + fileObj.name;
      const filePos = fileObj.name.lastIndexOf(".");
      const fileType = fileObj.name.substring(filePos + 1, fileObj.name.length);
      const params = {
        Bucket: environment.AWS_BUCKET_NAME,
        Key: "documents/" + UniqueName,
        // ACL: "public-read",
        Body: fileObj,
      };
      self.mangoAPISrvc.showLoader(true);
      self.awsBucket.upload(
        params,

        function (err, data) {
          if (err) {
            console.log("error while saving file on s3 server", err);
            self.fileUpload.clear()
            self.mangoAPISrvc.showLoader(false)
            return;
          }
          self.onRemove(fileObj);
          setTimeout(() => {
            const uploadType: string = self.selectedType;
            const formData = new FormData();
            formData.append("csvFile", fileObj);

            self.mangoAPISrvc.uploadCSV(formData, uploadType).subscribe((data) => {

              self.mangoAPISrvc.notify("success", self.translate.instant("Success_notify"), self.translate.instant("imports.file_has_been_uploaded_successfully"));

              self.reloadListing(self.selectedType);
              self.showOptionSetting();
            }, (data) => {

              self.mangoAPISrvc.notify('error', self.translate.instant('error'), data.message);
            });
            self.showFiles();
            self.fileUpload.clear()
            self.mangoAPISrvc.showLoader(false);
          }, 200);
        },

        self.mangoAPISrvc
      );
    }

    reloadMapping(tax: string){
      const self = this;
      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc.getTaxCSVMapping(tax.toUpperCase()).subscribe((results: any) =>{

          self.taxMapping = results;
          self.mangoAPISrvc.showLoader(false);
      });
    }

    reloadListing(prefix: string) {
      const self = this;

      self.mangoAPISrvc.showLoader(true);
      self.mangoAPISrvc.getCSVList().subscribe((results: any) => {

        self.csvData = results.filter(
          (item) => item.FileName.indexOf(prefix) > 1
        );
        self.mangoAPISrvc.showLoader(false);
      });
    }

    removeCSV(csvItem) {
      Swal.fire({
        title: this.translate.instant("confirmation"),
        text: this.translate.instant('imports.Do_you_really_want_to_delete_file') + `: ${csvItem.FileName}?`,
        icon: "warning",
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: this.translate.instant("yes_delete"),
        cancelButtonText: this.translate.instant("no_cancel"),
      }).then((result) => {
        if (result.value) {
          this.confirmRemoveCSV(csvItem);
        }
      });
    }

    confirmRemoveCSV(csvItem: any) {
      const self = this;
      self.mangoAPISrvc.removeCSV(csvItem.CustomerCSVID).subscribe(
        (data: any) => {
          self.mangoAPISrvc.notify(
            "success",
            self.translate.instant("Success_notify"),
            AppConstants.deleteMessage
          );
          self.reloadListing(self.selectedType);
          self.refreshCache();
        },
        (response) => {
          //let data = response.json();
          self.mangoAPISrvc.notify(
            "error",
            self.translate.instant("error"),
            AppConstants.deleteErrorMsg
          );
        }
      );
    }

    processCSV(csvID, fileName) {
      const self = this;
      const formObj = self.TaxOptionsForm.value;
      const postData = {
        CsvID: csvID,
        FileName: fileName,
        tax: this.selectedType,
        ReturnType: this.selectedReturnType,
        ClientOption: formObj["ClientOptions"],
        InvoiceOption: formObj["InvoiceOptions"],
        SearchClientOption: formObj["SearchClientOption"],
        Engagement: formObj["SelectedEngagements"],
        Year: formObj["SelectedYear"],
        InvoiceDescription: formObj["InvoiceDescription"],
        ReleaseTimeExpense: formObj["isReleaseTimeExpense"],
        BillingPartner: formObj["BillingPartner"],
        OrigPartner: formObj["OrigPartner"],
        StaffAssignedID: formObj["StaffAssignedID"],
        ClientType: formObj["ClientType"]
      };

      self.mangoAPISrvc.showLoader(true);

      self.mangoAPISrvc.processTaxImport(postData).subscribe(
        (data) => {
          self.mangoAPISrvc.notify(
            "success",
            this.translate.instant("Success_notify"),
            this.translate.instant("imports.Your_file_is_queued_for_processing")
          );
          self.mangoAPISrvc.showLoader(false);
          //self.notificationsService.notify("success", this._translate.instant(`File "${fileName}" is queued for processing.`));
          self.reloadListing(this.selectedType);
          self.refreshCache();
        },
        (response) => {
          //let data = response.json();
          self.mangoAPISrvc.notify("error", self.translate.instant("error"), 'Error');
          self.mangoAPISrvc.showLoader(false);
        }
      );
    }

    refreshCache() {
      const self = this;
      self.encrDecSrvc.clientList = [];
      self.encrDecSrvc.activitiesList = [];
      self.encrDecSrvc.companyData = [];
      self.encrDecSrvc.termsList = [];
      const staffId = this.encrDecSrvc.getObject(AppConstants.staffID);
      self.mangoAPISrvc.showLoader(true);
      self.encrDecSrvc.removeObject(AppConstants.clientCount);
      self.encrDecSrvc.removeObject(AppConstants.clientGroupCategory);
      self.encrDecSrvc.removeObject(AppConstants.staffList);
      self.encrDecSrvc.removeObject(AppConstants.customerType);
      self.encrDecSrvc.removeObject(AppConstants.shotHands);
      self.encrDecSrvc.removeObject(AppConstants.timeAndExpenses);
      self.encrDecSrvc.removeObject(AppConstants.activityList);
      self.encrDecSrvc.removeObject(AppConstants.expenses);
      self.encrDecSrvc.removeObject(AppConstants.invoiceOptions);
      self.encrDecSrvc.removeObject(AppConstants.noteCategories);
      self.encrDecSrvc.removeObject(AppConstants.planInfo);
      self.encrDecSrvc.removeObject(AppConstants.userPermissionObj);
      self.encrDecSrvc.removeObject(AppConstants.dmsEmailTemplate);
      self.encrDecSrvc.removeObject(AppConstants.workLocations);
      self.encrDecSrvc.removeObject(AppConstants.userPermissions);
      self.encrDecSrvc.loadDefaultAPI();
      self.mangoAPISrvc.loadDefaultAPI(staffId).subscribe((results: any) => {
        self.encrDecSrvc.addObject(AppConstants.clientCount, results[0]);
        self.encrDecSrvc.addObject(AppConstants.clientGroupCategory, results[1]);
        self.encrDecSrvc.addObject(AppConstants.staffList, results[2]);
        self.encrDecSrvc.addObject(AppConstants.allStaffList, results[14]);
        //self.encrDecSrvc.addObject(AppConstants.dmsStaffList, results[3]);
        self.encrDecSrvc.addObject(AppConstants.customerType, results[3]);
        self.encrDecSrvc.addObject(AppConstants.shotHands, results[4]);
        self.encrDecSrvc.addObject(AppConstants.timeAndExpenses, results[5]);
        self.encrDecSrvc.addObject(AppConstants.activityList, results[6]);
        self.encrDecSrvc.addObject(AppConstants.expenses, results[7]);
        self.encrDecSrvc.addObject(AppConstants.invoiceOptions, results[8]);
        self.encrDecSrvc.addObject(AppConstants.noteCategories, results[9]);
        self.encrDecSrvc.addObject(AppConstants.planInfo, results[10]);
        self.encrDecSrvc.addObject(AppConstants.userPermissionObj, results[11]);
        self.encrDecSrvc.addObject(AppConstants.dmsEmailTemplate, results[12]);
        self.encrDecSrvc.addObject(AppConstants.workLocations, results[13]);
        self.encrDecSrvc.addObject(AppConstants.userPermissions, results[15]);

        self.initLists();
        setTimeout(() => {
          self.mangoAPISrvc.showLoader(false);
        }, 100);

      });
    }

  isFormInvalid() {
    const formObj = this.TaxOptionsForm.value;

    if (formObj["InvoiceOptions"] != null && formObj["InvoiceOptions"] != "InvoiceNoAdd") {
      if (formObj["SelectedEngagements"] != null && formObj["InvoiceDescription"] != null && formObj["SelectedYear"] && formObj["isReleaseTimeExpense"] != null) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      if (formObj["SelectedEngagements"] != null) {
        return false;
      }
      else {
        return true;
      }
    }
  }
}
