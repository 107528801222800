<p-dialog
  header="{{ 'edit-invoice' | translate }}"

  showEffect="fade"

  (onHide)="closeInvoiceDialog(false)"

  [(visible)]="isEditDialogOpen"

  [modal]="true"
  [style]="{width: '65vw'}"
  [maximizable]="false"
  [baseZIndex]="1"
  [draggable]="true"
  [resizable]="false"
>
  <form
    role="form"
    [formGroup]="invoiceForm"
    novalidate
  >
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog" >
      <div class="p-field p-col-12" style="height: 1vh;">
      </div>
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline" >
          <div class="p-field p-col-4" >
            <label class="required-field label-text">
              {{ 'client' | translate }}
            </label>
            <input
              pInputText
              type="text"
              formControlName="ClientName"
              disabled="true"
            >
          </div>
          <div class="p-field p-col-2">
              <label class="label-text">
                {{ 'Accounts.invoice-date' | translate }}
              </label>
              <p-calendar
                formControlName="InvoiceDate"
                showButtonBar="true"
                yearRange="2000:2099"
                (onSelect)="verifySystemLocking($event)"
                [monthNavigator]="true"
                [yearNavigator]="true"
              >
              </p-calendar>
          </div>
          <div class="p-field p-col-2" >
              <label class="label-text" >
                {{ 'invoice_number' | translate }}
              </label>
              <input
                pInputText
                class="p-text-right"
                type="text"
                formControlName="InvoiceNumber"
              >
          </div>
          <div class="p-field p-col-2" >
              <label class="label-text">
                {{ 'Accounts.invoice-amount' | translate }}
              </label>
              <input
                pInputText
                class="p-text-right"
                type="text"
                formControlName="InvoiceAmount"
                disabled="true"
              >
          </div>
          <div class="p-field p-col-2" >
              <label class="label-text" >
                {{ 'Accounts.invoice-previous-balance' | translate }}
              </label>
              <input
                pInputText
                class="p-text-right"
                type="text"
                formControlName="PreviousBalance"
              >
          </div>
        </div>
        <div class="p-formgroup-inline" >
          <div class="p-field p-col-4" >
            <label class="label-text" >
              {{ 'billing-invoicing.invoice-template' | translate }}
            </label>
            <p-dropdown
              [disabled]="activeObj.InvoiceType === eInvoiceTypes?.RETAINER_INVOICE"
              (click)="handleInvoiceTemplateClick()"
              formControlName="selectedInvoiceTemplate"
              placeholder="Select Invoice Template"
              [options]="invoiceTemplates"
            >
            </p-dropdown>
          </div>
          <div class="p-field p-col-2" >
            <label class="label-text" >
              {{ 'billing-invoicing.terms' | translate }}
            </label>
            <p-dropdown
              formControlName="customerTermId"
              placeholder="Select Terms"
              [options]="termsList"
            >
            </p-dropdown>
          </div>
          <div class="p-field p-col-2" >
            <label class="label-text">
              {{ 'billing-invoicing.discount' | translate }}
            </label>
            <input
              pInputText
              class="p-text-right"
              type="text"
              formControlName="Discount"
              disabled="true"
            >
          </div>
          <div class="p-field p-col-2" >
            <label class="label-text">
              {{ 'sales-tax' | translate }}
            </label>
            <input
              pInputText
              class="p-text-right"
              type="text"
              formControlName="TotalTax"
              disabled="true"
            >
            <label
              *ngIf="surchargeAmt > 0"
              class="label-text ic-size-11 ic-red"
            >
              {{ 'surcharges' | translate }}
              <span>
                : {{ surchargeAmt | currency:'USD':'symbol':'1.2-2' }}
              </span>
            </label>
          </div>
          <div class="p-field p-col-2" *ngIf="isSuperAdmin">
            <label class="label-text">
              Invoice Balance
            </label>
            <input
              pInputText
              class="p-text-right"
              type="text"
              formControlName="InvoiceBalance"
            >
          </div>
        </div>
        <div class="p-formgroup-inline" *ngIf="isSuperAdmin">
          <div class="p-field p-col-2" >
            <label class="label-text">
              Total Payments
            </label>
            <input
              pInputText
              class="p-text-right"
              type="text"
              formControlName="TotalPayments"
            >
          </div>
        </div>
      </div>
      <div class="p-field p-col-12" >
        <label class="label-text">
          {{ 'Accounts.invoice-memo' | translate }}
        </label>
        <app-mentions [(inputText)]="activeObj['DescriptionShort']"
        (inputTextChange)="validateForm($event)" maxlength="250" [rows]="5" style="width: 100%; resize: vertical;"></app-mentions>
        <div class="custom-warn-message" *ngIf="activeObj['DescriptionShort']?.length >= 250">
          You have reached the character limit for this field. Current length: {{ activeObj['DescriptionShort']?.length }} characters.
        </div>        
      </div>
      <div *ngIf="(lineItems.length > 0)"  class="p-field p-col-12 edit-invoice-padding" >
        <div class="p-d-flex p-jc-start">
          <h4 class="label-text p-mb-0" >
            {{ 'invoice_line_items' | translate }}
          </h4>
          <h6 *ngIf="!isInvoiceAmountCanEdit()" class="ic-red p-mt-2 p-mb-0 p-ml-3">
            {{ 'invoice.edit_not_allowed' | translate }}
          </h6>
        </div>
        <p-table
          styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
          editMode="row"
          dataKey="LineItem"
          (onRowReorder)="onRowChange($event)"
          [(selection)]="invoiceTemplates"
          [value]="lineItems"
          [rowHover]="true"
          [responsive]="true"
          [rows]="10"
          [reorderableColumns]="true"
          [showCurrentPageReport]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[10,25,50]"
          [filterDelay]="0"
          [paginatorPosition]="'top'"
          [paginator]="lineItems.length > 10"
        >
          <ng-template pTemplate="header" >
            <tr>
              <th class="width-7p p-text-center" >
                {{ 'line_number' | translate }}
              </th>
              <th *ngIf="recordsTable.unFilteredTimeSlips.length > 0" class="width-5p p-text-center" >
                {{ 'action' | translate }}
              </th>
              <th class="width-5p p-text-center" >
              </th>
              <th class="width-20p p-text-left" pSortableColumn="Description" >
                {{ 'engagement' | translate }}
                <p-sortIcon field="Description">
                </p-sortIcon>
              </th>
              <th class="p-text-left" >
                {{ 'activity-description' | translate }}
                <i class="pi pi-question-circle ic-blue p-ml-1 p-mb-1" pTooltip="If the text exceeds 1,000 characters or contains numerous line breaks, the invoice layout may be affected." tooltipPosition="top"></i>
                </th>
              <th class="width-10p p-text-right" >
                  Discount
              </th>
              <th class="width-10p p-text-right" >
                {{ 'amount' | translate }}
              </th>
              <th class="width-10p p-text-center" >
              </th>
            </tr>
          </ng-template>
          <ng-template
            let-rowData
            pTemplate="body"
            let-columns="columns"
            let-rowIndex="rowIndex"
            let-editing="editing"
          >
            <tr
              [pReorderableRow]="rowIndex"
              [pEditableRow]="rowData"
              [pSelectableRow]="rowData"
            >
              <td class="p-text-center" >
                <span>
                  <strong>
                    {{ rowData.LineItem }}
                  </strong>
                </span>
              </td>
              <td
                *ngIf="recordsTable.unFilteredTimeSlips.length > 0"
                class="p-text-center"
              >
                <div class="p-text-center">
                  <i
                    class="fal fa-pencil p-mr-3 ic-green ic-size-15 cursor-hand"
                    pTooltip="{{'Edit' | translate}}"
                    tooltipPosition="top"
                    (click)="showTimeRecords(rowData.ProjectID)"
                  >
                  </i>
                </div>
              </td>
              <td class="p-text-center" >
                <i
                  pReorderableRowHandle
                  class="fa fa-bars"
                  pTooltip="Reorder Row"
                >
                </i>
              </td>
              <td class="p-text-left" >
                {{ rowData.Description }}
              </td>
              <td
                class="p-text-left p-text-nowrap p-text-truncate"
                (click)="onRowEditInit(rowData, 'details', 'Desc', rowIndex)"
                [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
              >
                <span *ngIf="!rowData['IsRowEditing']">
                  {{rowData.bddescription}}
                </span>
                <div  *ngIf="rowData['IsRowEditing']">
                  <app-mentions [(inputText)]="rowData['bddescription']" id="desc_{{rowData['uuid']}}" [rows]="1" style="width: 100%; resize: vertical;"></app-mentions>
                </div>
              </td>
              <td
                *ngIf="recordsTable.unFilteredTimeSlips.length > 0 && !isInvoiceAmountCanEdit()"
                class="p-text-right"
              >
                {{ converToNum(rowData.Discount) | currency:'USD':'symbol':'1.2-2' }}
              </td>
              <td
                *ngIf="recordsTable.unFilteredTimeSlips.length > 0 && isInvoiceAmountCanEdit()"
                class="p-text-right"
                (click)="onRowEditInit(rowData, 'details', 'Discount', rowIndex)"
              >
                <span *ngIf="!rowData['IsRowEditing']">
                  {{ converToNum(rowData.Discount) | currency:'USD':'symbol':'1.2-2' }}
                </span>
                <div  *ngIf="rowData['IsRowEditing']">
                  <input
                    pInputText
                    [(ngModel)]="rowData.Discount"
                    [id]="'discount' + rowIndex"
                    class="p-text-right discount-{{rowIndex}}"
                    (blur)="checkAmountAndPaymentValues(null, rowData, 'Discount')"
                    type="text"
                    pKeyFilter="num"
                    (focus)="$event.target.select();onDiscountFocus(rowData.Discount);"
                    [ngModelOptions]="{standalone: true}"
                  >
                </div>
              </td>
              <td
                *ngIf="recordsTable.unFilteredTimeSlips.length > 0 && !isInvoiceAmountCanEdit()"
                class="p-text-right"
              >
                {{ converToNum(rowData.Amount) | currency:'USD':'symbol':'1.2-2' }}
              </td>
              <td
                *ngIf="recordsTable.unFilteredTimeSlips.length > 0 && isInvoiceAmountCanEdit()"
                class="p-text-right"
                (click)="onRowEditInit(rowData, 'details', 'Amount', rowIndex)"
              >
                <span *ngIf="!rowData['IsRowEditing']">
                  {{ converToNum(rowData.Amount) | currency:'USD':'symbol':'1.2-2' }}
                </span>
                <div  *ngIf="rowData['IsRowEditing']">
                  <input
                    pInputText
                    [(ngModel)]="rowData.Amount"
                    [id]="'amount' + rowIndex"
                    class="p-text-right amt-{{rowIndex}}"
                    type="text"
                    pKeyFilter="num"
                    (blur)="checkAmountAndPaymentValues(null, rowData, 'Amount')"
                    (focus)="$event.target.select();onInvoiceAmountFocus(rowData.Amount);"
                    [ngModelOptions]="{standalone: true}"
                  >
                </div>
              </td>
              <td *ngIf="recordsTable.unFilteredTimeSlips.length == 0" class="p-text-right">
                {{ rowData.Amount | currency:'USD':'symbol':'1.2-2' }}
              </td>
              <td class="p-text-center" >
                <button
                  *ngIf="rowData['IsRowEditing']"
                  pButton
                  pRipple
                  pSaveEditableRow
                  type="button"
                  icon="pi pi-check"
                  class="p-button-rounded p-button-text p-button-success p-mr-2"
                  (click)="onRowEditSave(rowData, 'details', rowIndex);"
                >
                </button>
                <button
                  *ngIf="rowData['IsRowEditing']"
                  pButton
                  pRipple
                  pCancelEditableRow
                  type="button"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-text p-button-danger"
                  (click)="onRowEditCancel(rowData, 'details')"
                >
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [colspan]="recordsTable.unFilteredTimeSlips.length > 0 ? 7 : 6" >
                <p-message
                  styleClass="p-px-2"
                  text="{{ 'data_not_found' | translate }}"
                  severity="info"
                >
                </p-message>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div
        class="p-field p-col-12 edit-invoice-padding"
        *ngIf="recordsTable.timeSlipsWithoutBillingDetails.length > 0"
      >
        <div class="p-d-flex p-jc-start">
          <h4 class="label-text p-mb-0 ic-red" >
            {{ 'invoice_line_no_items' | translate }}
          </h4>
        </div>
        <p-table
          styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo ic-red"
          editMode="row"
          dataKey="LineItem"
          [value]="recordsTable.timeSlipsWithoutBillingDetails"
        >
          <ng-template pTemplate="header">
            <tr>
              <th class="width-5p p-text-center">{{ 'action' | translate }}</th>
              <th class="width-5p p-text-center">{{ 'engagement' | translate }}</th>
              <th class="width-5p p-text-center">{{ 'amount' | translate }}</th>
            </tr>
          </ng-template>
          <ng-template
            let-rowData
            pTemplate="body"
            let-columns="columns"
            let-rowIndex="rowIndex"
          >
            <tr>
              <td class="p-text-center">
                <div class="p-text-center">
                  <i
                    class="fal fa-pencil p-mr-3 ic-green ic-size-15 cursor-hand"
                    pTooltip="{{'Edit' | translate}}"
                    tooltipPosition="top"
                    (click)="showTimeRecordsWithoutBillingDetails()"
                  >
                  </i>
                </div>
              </td>
              <td class="p-text-center">
                {{ rowData.EngagementName }}
              </td>
              <td class="p-text-center">
                {{ rowData.totalbilled | currency:'USD':'symbol':'1.2-2' }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div
        *ngIf="(expensesItems.length > 0)"
        class="p-field p-col-12"
      >
        <h4 class="label-text" >
          {{ 'billable-expenses' | translate }}
        </h4>
        <p-table
          styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
          sortField="Ddate"
          (onRowReorder)="onRowChange($event)"
          [value]="expensesItems"
          [rowHover]="true"
          [responsive]="true"
          [rows]="10"
          [reorderableColumns]="true"
          [showCurrentPageReport]="true"
          [pageLinks]="3"
          [rowsPerPageOptions]="[10,25,50]"
          [sortOrder]="-1"
          [filterDelay]="0"
          [paginatorPosition]="'top'"
          [paginator]="expensesItems.length > 10"
        >
          <ng-template pTemplate="header" >
            <tr>
              <th class="width-20p p-text-center">
                {{ 'date' | translate }}
              </th>
              <th class="width-65p p-text-center" >
                {{ 'description' | translate }}
              </th>
              <th class="width-15p p-text-right" >
                {{ 'amount' | translate }}
              </th>
            </tr>
          </ng-template>
          <ng-template
            let-rowData
            pTemplate="body"
            let-columns="columns"
            let-rowIndex="rowIndex"
          >
            <tr>
              <td class="p-text-center" >
                {{rowData.Ddate | date:'MM/dd/yyyy'}}
              </td>
              <td
                pEditableColumn
                class="p-text-left"
                (click)="onExpenseRowEdit(rowData)"
                [style.overflow]="rowData['IsRowEditing'] ? 'visible' : 'hidden'"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <app-mentions [(inputText)]="rowData['Memo']" class="p-text-left desc-{{rowIndex}} "
                      (inputTextChange)="validateForm();onExpenseRowFinishEdit(rowData);" 
                      [rows]="1" style="width: 100%; resize: vertical;"></app-mentions>
                  </ng-template>
                  <ng-template pTemplate="output" >
                    {{ rowData.Memo }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="p-text-right" >
                {{ rowData.BilledAmount | currency:'USD':'symbol':'1.2-2' }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" >
            <tr>
              <td colspan="4" >
                <p-message
                  styleClass="p-px-2"
                  text="{{ 'data_not_found' | translate }}"
                  severity="info"
                >
                </p-message>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class=" p-field p-col-12"  style="height: 10vh;"
      >
      </div>
    </div>
  </form>
  <ng-template
    pTemplate="footer"
    class="p-mb-6"
  >
    <button
      type="button"
      pButton
      pRipple
      icon="fal fa-check"
      (click)="isShowMemo = !isShowMemo"
      label="{{ 'top-memo' | translate }}"
      class="pull-left"
    >
    </button>
    <button
      pButton
      pRipple
      type="button"
      icon="fal fa-check"
      label="{{ 'bottom-memo' | translate }}"
      class="pull-left"
      (click)="isShowBottonMemo = !isShowBottonMemo"
    >
    </button>
    <div class="p-field-checkbox pull-left p-mb-0 p-mt-1 fb-normal">
      <p-checkbox [(ngModel)]="IsExcludeAging" binary="true" label="Exclude in Aging Report" name="IsExcludeAging" id="IsExcludeAging" (onChange)="excludeAgingChanged=true; validateForm();"></p-checkbox>
      <span pTooltip="{{ 'isExcludeAging_tooltip_message' | translate }}" class="p-pl-1" tooltipPosition="top">
        <i class="fa fa-question-circle ic-blue"></i>
      </span>
    </div>


    <button
      pButton
      pRipple
      type="button"
      icon="fal fa-check"
      label="{{ 'save_button' | translate}}"
      (click)="saveInvoice(invoiceForm.value)"
      [disabled]="(!isFormValid || editForm || editData.length > 0)"
    >
    </button>
    <button
      pButton
      pRipple
      type="button"
      icon="fal fa-times"
      label="{{ 'cancel_button' | translate}}"
      (click)="closeDialog()"
    >
    </button>
  </ng-template>
</p-dialog>

<p-sidebar
  position="bottom"
  styleClass="p-sidebar-md"
  (onHide)="setForm()"
  [(visible)]="isShowMemo"
  [baseZIndex]="10002"
  [style]="
    {
      'height':'45%'
    }
  "
>
  <div
    class="card"
  >
    <div
      class="p-field p-col-12"
    >
      <label
        class="label-text"
      >
        {{ 'top-memo' | translate }}
      </label>
      <ejs-richtexteditor
        #topEditor

        id='topEditor'

        name="billNoteTop"

        (ngModelChange)="setRichText();"

        [(ngModel)]="activeObj['BillNoteTop']"

        [ngModelOptions]="{standalone: true}"
        [toolbarSettings]='tools'
        [showCharCount]="true"
        [maxLength]="550"
        autofocus
      >
      </ejs-richtexteditor>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  styleClass="p-sidebar-md"
  position="bottom"

  (onHide)="setForm()"

  [(visible)]="isShowBottonMemo"

  [baseZIndex]="10001"
  [style]="
    {
      'height':'45%'
    }
  "
>
  <div
    class="card"
  >
    <div
      class="p-field p-col-12"
    >
      <label
        class="label-text"
      >
        {{ 'bottom-memo' | translate }}
      </label>
      <ejs-richtexteditor
        #billNote

        id='billNote'

        name="BillNote"

        (ngModelChange)="setRichText();"

        [(ngModel)]="activeObj['BillNote']"

        [ngModelOptions]="{standalone: true}"
        [toolbarSettings]='tools'
        [maxLength]="550" [showCharCount]="true"
        autofocus
        >
      </ejs-richtexteditor>
    </div>
  </div>
</p-sidebar>

<p-sidebar
  *ngIf="showRecordsSideBar"

  position="right"

  (onHide)="onCloseRecords()"

  [(visible)]="showRecordsSideBar"

  [closeOnEscape]="true"
  [baseZIndex]="2"
  [style]="
    {
      'min-width':'98%',
      'min-height':'100%',
      'background':'#8e8f92',
      'overflow': 'auto'
    }
  "
>
  <div
    class="card"
    style="min-height: calc(100vh - 35px)"
  >
    <div
      class="card p-col-12 p-py-0 p-px-4 p-mx-2 p-mb-2"
    >
      <div
        class="p-col-12 p-pr-0 manual"
      >
        <p-panel
          header="{{ 'Open-Time-Records' | translate }}"

          [toggleable]="true"
        >
          <p-table
            #dtAllTimeSlips

            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            editMode="row"
            dataKey="SlipMasterID"
            metaKeySelection="true"

            (onFilter)="onFilter($event)"

            [value]="recordsTable.allTimeSlips"
            [rows]="5"
            [pageLinks]="3"
            [sortOrder]="-1"
            [rowHover]="true"
            [responsive]="true"
            [resizableColumns]="true"
            [columns]="selectedColumns"
            [globalFilterFields]="['StaffName', 'StaffNumber', 'Description', 'ServiceCode', 'Memo']"
            [scrollable]="true"
            [scrollHeight]="recordsTable.allExpenseSlips.length > 0 ? 'calc(55vh - 200px)' : 'calc(85vh - 200px)'"
          >
            <ng-template
              pTemplate="caption"
            >
              <div
                class="table-header"
                style="margin-top: -10px;"
              >
                <div
                  class="p-d-flex p-jc-between"
                >
                  <div>
                    <span
                      class="p-input-icon-left"
                    >
                      <i
                        class="fal fa-search"
                      >
                      </i>
                      <input
                        #searchValue

                        pInputText

                        type="text"
                        placeholder="{{ 'Search-by' | translate }}"

                        (input)="dtAllTimeSlips.filterGlobal($event.target.value, 'contains')"
                      />
                    </span>
                    <span>
                      <button
                        pButton
                        pRipple

                        type="button"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-danger p-button-text"
                        tooltipPosition="top"
                        pTooltip="{{'clear' | translate}}"

                        (click)="dtAllTimeSlips.reset();clearSearchFilterTime()"
                      >
                      </button>
                    </span>
                    <p-message
                      *ngIf="filteredItemsSize > -1"

                      severity="success"
                      text="{{filteredItemsSize}} {{ 'records_found' | translate }}."
                      styleClass="p-ml-2"
                    >
                    </p-message>
                  </div>
                  <p-multiSelect
                    placeholder="Choose Columns"
                    optionLabel="header"
                    selectedItemsLabel="{0} columns selected"

                    (onChange)="onChangeSelectedCols($event)"

                    [(ngModel)]="selectedColumns"

                    [options]="cols"
                    [style]="{minWidth: '200px'}"
                  >
                  </p-multiSelect>
                </div>
              </div>
            </ng-template>
            <ng-template
              let-columns

              pTemplate="header"
            >
              <tr>
              <th *ngIf="displayDate"
                class="width-5p p-text-left"
              >
              <a   (click)="sortDates()">
                Date
                <i
                [ngClass]="isSortAscending ? 'fa fa-sort-amount-asc' : 'fa fa-sort-amount-desc'"
                >
                </i></a>
              </th>
              <ng-container *ngFor="let col of columns">
                <th
                *ngIf="col.field!=='Ddate'"

                class="p-text-left"
                class="{{col.rowClass}}"

                [pSortableColumn]="col.field"
              >
                {{col.header}}
                <i
                  *ngIf="col.field === 'ServiceCode'"

                  class="fa fa-question-circle ic-blue"
                  pTooltip="{{'activity-code' | translate}}"
                  tooltipPosition="top"
                >
                </i>
                <p-sortIcon
                  [field]="col.field"
                >
                </p-sortIcon>
              </th>
              </ng-container>

                <th
                  class="width-5p p-text-right"
                  pTooltip="{{'Billing-Hours' | translate}}"
                  tooltipPosition="top"
                >
                  {{ 'timer.hours' | translate }}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                </th>
                <th
                  class="width-5p p-text-right"
                >
                  {{ 'billing-rate' | translate }}
                </th>
                <th
                  class="width-3p p-text-center"
                  pTooltip="{{'Non-Billable-tooltip' | translate}}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                >
                  NB
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                </th>
                <th
                  class="width-8p p-text-right"
                  pTooltip="{{'billable-amount' | translate}}/{{'Non-Billable-Amount' | translate}}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                >
                  {{ 'Standard_Amount' | translate }}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                </th>
                <th
                  class="width-5p p-text-right"
                  pTooltip="{{'writes-up-down' | translate}}"
                  tooltipPosition="top"
                >
                  WUWD
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                </th>
                <th
                  class="width-5p p-text-right"
                  pTooltip="{{'billed-amount' | translate}}"
                  tooltipPosition="top"
                >
                  {{ 'billed' | translate }}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                </th>
                <th
                  class="width-6p p-text-center"
                >
                </th>
              </tr>
            </ng-template>
            <ng-template
              let-rowData

              pTemplate="body"
              let-columns="columns"
              let-editing="editing"
              let-ri="rowIndex"
            >
              <tr
                class="cursor-hand"

                [pEditableRow]="rowData"
              >
                <td *ngIf="displayDate" class="width-5p p-text-left p-text-truncate" >
                  <span
                  >
                    {{ rowData['Ddate'] | date:'MM/dd/yyyy' }}
                  </span>
                </td>
                <ng-container *ngFor="let col of columns">
                  <td
                  *ngIf="col.field!=='Ddate'"
                  class="{{col.rowClass}} p-text-truncate"
                  pTooltip="{{rowData[col.field]}}"
                  showDelay="800"
                  tooltipStyleClass="tooltip-no-wrap"
                  tooltipPosition="top"

                  [style.overflow]="(rowData['IsRowEditing'] && col.field === 'Memo') ? 'visible' : 'hidden'"
                >
                  <span
                    *ngIf="col.field==='StaffName'"
                  >
                    {{ rowData.StaffName }}
                  </span>
                  <span
                    *ngIf="col.field==='StaffNumber'"
                  >
                    {{ rowData.StaffNumber }}
                  </span>
                  <span
                    *ngIf="col.field==='scdescr'"
                  >
                    {{ rowData['scdescr'] }}
                  </span>
                  <span
                    *ngIf="col.field==='ServiceCode'"
                  >
                    {{ rowData.ServiceCode }}
                  </span>
                  <span
                    *ngIf="col.field==='Memo'"
                  >
                    {{ rowData.Memo }}
                  </span>
                </td>
                </ng-container>

                <td
                  class="width-5p p-text-right"
                >
                  {{ rowData.TotalTime | number: "1.2-2" }}
                </td>
                <td
                  class="width-5p p-text-right"
                >
                  {{ rowData.BillingRate | currency: "USD":true:"1.2-2" }}
                </td>
                <td
                  class="width-3p p-text-center"

                  id="border-right"
                >
                  <i
                    *ngIf="rowData.nonbillableamount"

                    class="pi pi-check ic-green"
                  >
                  </i>
                </td>
                <td
                  class="width-8p p-text-right"

                  id="border-right"
                >
                  {{ rowData.billableamount || rowData.nonbillableamount | currency: "USD":true:"1.2-2" }}
                </td>
                <td
                  class="width-5p p-text-right"

                  id="border-right"
                >
                  <span
                    [style.color]="setWriteUpDownColor(rowData.WriteUpDown)"
                  >
                    <span
                      *ngIf="rowData.WriteUpDown < 0"
                    >
                      ({{ mangoUtils.getAbsolute(rowData.WriteUpDown) | currency: "USD":"symbol":"1.2-2" }})
                    </span>
                    <span
                      *ngIf="rowData.WriteUpDown >= 0"
                    >
                      {{ rowData.WriteUpDown | currency: "USD":"symbol":"1.2-2" }}
                    </span>
                  </span>
                </td>
                <td
                  class="width-5p p-text-right"
                  (click)="onRowEditInit(rowData, 'time', 'Amount', ri)"
                >
                  <span *ngIf="!rowData['IsRowEditing']">
                    {{ converToNum(rowData.BilledAmount) | currency:'USD':'symbol':'1.2-2' }}
                  </span>
                  <div  *ngIf="rowData['IsRowEditing']">
                    <input
                      pInputText
  
                      class="p-text-right width-100p amt-{{ri}}"
                      type="text"
                      maxLength="14"
                      pKeyFilter="num"
  
                      (blur)="doMoneyFormate(null, rowData, 'BilledAmount')"
                      (focus)="preventTimeRecordUpdate(rowData['ProjectMasterID'], rowData, ri, $event);"
  
                      [(ngModel)]="rowData.BilledAmount"
  
                      [ngModelOptions]="{standalone: true}"
                    >
                  </div>
                </td>
                <td
                  class="width-6p p-text-center"
                >
                  <button
                    *ngIf="rowData['IsRowEditing']"

                    pButton
                    pRipple
                    pSaveEditableRow

                    class="p-button-rounded p-button-text p-button-success p-mr-2"
                    type="button"
                    icon="pi pi-check"

                    (click)="onRowEditSave(rowData, 'time');"
                  >
                  </button>
                  <button
                    *ngIf="rowData['IsRowEditing']"

                    pButton
                    pRipple
                    pCancelEditableRow

                    class="p-button-rounded p-button-text p-button-danger"
                    type="button"
                    icon="pi pi-times"

                    (click)="onRowEditCancel(rowData, 'time')"
                  >
                  </button>
                </td>
              </tr>
            </ng-template>
            <ng-template
              let-columns

              pTemplate="footer"
            >
              <tr>
                <td
                  *ngFor="let col of columns"

                  class="{{col.rowClass}}"
                >
                </td>
                <td
                  class="width-5p p-text-right p-text-bold ic-red"
                >
                  Totals
                </td>
                <td
                  class="width-5p p-text-right p-text-bold ic-red"
                >
                  <span>
                    {{ totalFromSlips.TotalRate | number: "1.2-2" }}
                  </span>
                </td>
                <td
                  class="width-3p p-text-center p-text-bold ic-red"
                >
                  &nbsp;
                </td>
                <td
                  class="width-8p p-text-right p-text-bold ic-red"
                >
                  <span>
                    {{ totalFromSlips.TotalStandardAmt | currency: "USD":true:"1.2-2" }}
                  </span>
                </td>
                <td
                  class="width-5p p-text-right p-text-bold ic-red"
                >
                  <span
                    [style.color]="setWriteUpDownColor(totalFromSlips.TotalWUWD)"
                  >
                    <span
                      *ngIf="totalFromSlips.TotalWUWD < 0"
                    >
                      ({{ totalFromSlips.TotalWUWD | currency: "USD":"symbol":"1.2-2" }})
                    </span>
                    <span
                      *ngIf="totalFromSlips.TotalWUWD >= 0"
                    >
                      {{ totalFromSlips.TotalWUWD | currency: "USD":"symbol":"1.2-2" }}
                    </span>
                  </span>
                </td>
                <td
                  class="width-5p p-text-right p-text-bold ic-red"
                >
                  <span>
                    {{ totalFromSlips.TotalBilledAmt | currency: "USD":true:"1.2-2" }}
                  </span>
                </td>
                <td
                  class="width-6p p-text-center"
                >
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-panel>
      </div>
    </div>
  </div>
</p-sidebar>