import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_services';
import { TaxImportsComponents } from './tax-imports.component';

const routes: Routes = [
  { path: '', component: TaxImportsComponents, canActivate: [AuthGuard], data: { 'featID': 31 } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TaxImportsRoutingModule { }
