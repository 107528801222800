import { Component, OnInit, ViewChild, Input, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MangoApiService, EncrDecrService, BreadcrumbService, mangoUtils } from '@app/_services';
import { ConfirmationService } from 'primeng/api';
import { AppConstants } from '@app/_helpers/api-constants';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import $ from "jquery";
import { SharedComponentsService } from '@app/shared/components';
declare let numeral: any;
@Component({
  selector: "app-client-list",
  templateUrl: "./client-list.component.html",
})
export class ClientListComponent implements OnInit {
  totalClientsDataSource: any = [];
  clientListsDropdown: any = [];
  openTimeExpenseArr: any = [];
  clientGroupCategory: any = [];
  globalFilterColumns: any = [];
  statuses: any[];
  companyId;
  filteredClients: any = [];
  colspan: number = 12;
  @ViewChild("searchValue") searchValue;
  @ViewChild("dt") dataTableComponent: Table;
  @ViewChildren('tableRow') tableRows!: QueryList<ElementRef>;
  clientsCount: any = { all: 0, active: 0, inactive: 0, contacts: 0 };
  searchTextStr: any = "";
  filteredItemsSize = -1;
  isClientList: boolean = true;
  selClient = null;
  isClientListTableLoading = false;
  isClientListDropdownLoading = false;
  showContacts: boolean = false;
  clientContacts = [];
  selectedTabIndex = 0;
  totalUnbilledTime = 0;
  totalUnbilledExpense = 0;
  selectedRowsPerPage = 10;

  cols: any[];
  _selectedColumns: any[];

  cols1: any[];
  _selectedColumns1: any = [];

  companyData: any = {};
  totalInvoiceBal: any = 0;
  ClientListCols: Array<string> = [];
  clientsListStorage = {
    active: [], inactive: [], contacts: [], all: []
  }
  selectedClient: any;
  focusedRowIndex: number | null = null;
  paginatedClients: any = [];
  currentPage: number = 0;

  constructor(
    private router: Router,
    private mangoAPISrvc: MangoApiService,
    private encrDecSrvc: EncrDecrService,
    private confirmationSrvc: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    public mangoUtils: mangoUtils,
    public sharedSrvc: SharedComponentsService
  ) {
    this.encrDecSrvc.addObject(AppConstants.clientID, "");
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, "");
    this.mangoAPISrvc.fireClientView(true);
    this.companyData = this.encrDecSrvc.getObject(AppConstants.timeAndExpenses);
    this._selectedColumns = [
      {
        field: "CustomerTypeDescription",
        header: this.translate.instant("client.type"),
        class: "width-7p",
      },
    ];
    this.translate.reloadLang(this.translate.currentLang).subscribe(() => {
      this.initTranslations();
    });
  }

  //Column Chooser for client list customer contacts
  @Input() get selectedColumns1(): any[] {
    return this._selectedColumns1;
  }

  set selectedColumns1(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns1 = this.cols1.filter((col) =>
      arr.includes(col.field)
    );
  }
  
  toggleClientListView(){
    const staffID = this.encrDecSrvc.getObject(AppConstants.staffID);
    this.sharedSrvc.isTableView = !this.sharedSrvc.isTableView;
    if(this.sharedSrvc.isTableView) {
      this.handleChange({ index: 0 })
    }
    this.mangoAPISrvc.updateStaffClientView(staffID, { isClientTable: this.sharedSrvc.isTableView })
      .subscribe(response => {
        this.encrDecSrvc.addObject(AppConstants.staffClientTableView, this.sharedSrvc.isTableView)
      })
    if(this.sharedSrvc.isTableView){
      this.loadFilterGrid();
    }
  }

  initializeColumns1() {
    this.cols1 = [
      /* { field: 'ContactName', header: this.translate.instant('client.contact_name'), class: 'width-15p p-text-left'}, */
      /*  { field: 'Company', header: this.translate.instant('company'), class: 'width-15p p-text-left'}, */
      {
        field: "EngagementName",
        header: this.translate.instant("engagement"),
        class: "width-15p p-text-left",
        sortable: true,
      },
      {
        field: "ContactDescription",
        header: this.translate.instant("client.contact_description"),
        class: "width-15p p-text-left",
        sortable: false,
      },
      {
        field: "ContactType",
        header: this.translate.instant("contact_type"),
        class: "width-15p p-text-left",
        sortable: false,
      },
      /* { field: 'Office', header: this.translate.instant('work'), class: 'width-10p p-text-left'},
      { field: 'Fax', header: this.translate.instant('Fax-Number'), class: 'width-10p p-text-left'},
      { field: 'Mobile', header: this.translate.instant('mobile'), class: 'width-7p p-text-left'}, */
      {
        field: "Street1",
        header: this.translate.instant("Street1"),
        class: "width-15p p-text-left",
        sortable: false,
      },
      {
        field: "Street2",
        header: this.translate.instant("Street2"),
        class: "width-15p p-text-left",
        sortable: false,
      },
      {
        field: "Zip",
        header: this.translate.instant("Zip_Code"),
        class: "width-10p p-text-left",
        sortable: false,
      },
      {
        field: "City",
        header: this.translate.instant("City"),
        class: "width-15p p-text-left",
        sortable: false,
      },
      {
        field: "State",
        header: this.translate.instant("State"),
        class: "width-10p p-text-left",
        sortable: false,
      },
      /*  { field: 'Email', header: this.translate.instant('email'), class: 'width-10p p-text-left'}, */
    ];
  }

  showClientContacts(rowData) {
    this.showContacts = true;
    this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc
      .getContacts(rowData["ClientID"])
      .subscribe((data: any) => {
        rowData["hasChildren"] = data.length > 0 ? true : false;
        this.clientContacts = data;
        this.mangoAPISrvc.showLoader(false);
      });
  }

  cancelClientContacts() {
    this.clientContacts = [];
    this.showContacts = false;
  }

  initTranslations() {
    this.breadcrumbService.setItems([
      { label: this.translate.instant("client-list") },
    ]);
    this.initializeColumn();
    this.initializeColumns1();
  }

  /* loadFilterGrid(){
    setTimeout(() => {
      this.searchTextStr = this.encrDecSrvc.getObject(
        AppConstants.listRoutePath + "clients_" + AppConstants.SearchString
      );
      this.clientsCount = this.encrDecSrvc.getObject(AppConstants.clientCount);
      this.searchValue.nativeElement.value = this.searchTextStr || "";
      // this.dataTableComponent.reset();
      let element = $(`#p-tabpanel-${this.selectedTabIndex} #searchValue-${this.selectedTabIndex}`)[0];
      if (this.searchTextStr) {
        var event = new Event("input", {
          bubbles: true,
          cancelable: true,
        });
        element?.dispatchEvent(event);
        element?.select();
      } else {
        element?.focus();
      }
      this.filteredItemsSize = -1;
    }, 100);
  } */

  loadFilterGrid() {
    if(!this.sharedSrvc.isTableView ) return;
    setTimeout(() => {
      if(this.searchValue) {
        this.searchValue.nativeElement.value = this.searchTextStr || "";
        this.dataTableComponent.reset();
        if (this.searchTextStr) {
          const event = new Event('input', {
            'bubbles': true,
            'cancelable': true
          });
          this.searchValue.nativeElement.dispatchEvent(event);
          this.searchValue.nativeElement.select();
        } else {
          this.searchValue.nativeElement.focus();
        }
        this.filteredItemsSize = -1;
      }
    }, 1000);
  }

  // private filterTimeout: any = null;
  // private filterTimer: any = timer(500);
  // filterClients(event) {
  //   if (this.filterTimeout) {
  //     this.filterTimeout.unsubscribe();
  //   }
  //   this.filterTimeout = this.filterTimer.subscribe(() => {
  //     let filtered: any[] = [];
  //     let query = event.query;
  //     for (let i = 0; i < this.clientListsDropdown.length; i++) {
  //       let client = this.clientListsDropdown[i];
  //       if (
  //         client["ClientName"].toLowerCase().indexOf(query.toLowerCase()) > -1
  //       ) {
  //         filtered.push(client);
  //       } else if (client['ClientNumber']?.toLowerCase()?.indexOf(query.toLowerCase()) > -1) {
  //         filtered.push(client);
  //       }

  //       if (filtered.length > 20)
  //         break;
  //     }
  //     this.filteredClients = filtered;
  //     this.filterTimeout.unsubscribe();
  //   });
  // }

  ngOnInit(): void {
    this.searchTextStr = this.encrDecSrvc.getObject(
      AppConstants.listRoutePath + "clients_" + AppConstants.SearchString
    );
    this.companyId = this.encrDecSrvc.getObject(AppConstants.companyID);
    this.mangoAPISrvc.notifyLogging(
      this.encrDecSrvc.getObject(AppConstants.isLoggingUser)
    );
    //this.clientsCount = this.encrDecSrvc.getObject(AppConstants.clientCount);
    this.clientGroupCategory = this.encrDecSrvc.getObject(
      AppConstants.clientGroupCategory
    );
    //table get from API, special handling
    this.sharedSrvc.isTableView = this.encrDecSrvc.getObject(AppConstants.staffClientTableView);
    if(this.sharedSrvc.isTableView) {
      this.loadClients();
    }
  }

  onTablePageChange(event) {
    this.currentPage = (event.first/this.selectedRowsPerPage);
    this.savePaginationData(event.first, event.rows);
    if (this.selectedRowsPerPage === event.rows)
      return;

    const objToSave = {
      ClientListDefaultPagination: event.rows
    }
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(
        this.encrDecSrvc.getObject(AppConstants.staffID),
        objToSave
      )
      .subscribe(
        (data: any) => { },
        (err) => { }
      );
  }

  initializeColumn() {
    const allCols = [
      {
        field: "TotalInvBal",
        header: this.translate.instant("Accounts.invoice-balance"),
        class: "width-8p p-text-right",
      }, {
        field: "CompanyLocation",
        header: this.translate.instant("company_location"),
        class: "width-10p p-text-left",
      },
      {
        field: "CustomerTypeDescription",
        header: this.translate.instant("client.type"),
        class: "width-7p p-text-left p-text-truncate",
      },
      {
        field: "GroupDescriptionIDArrayDesc",
        header: this.translate.instant("client-group"),
        class: "width-11p p-text-left p-text-truncate",
      },
      {
        field: "BillingGroup",
        header: this.translate.instant("billing-group"),
        class: "width-9p p-text-left p-text-truncate",
      },
      {
        field: "ContactPerson",
        header: this.translate.instant("client.client_contact"),
        class: "width-8p p-text-left p-text-truncate",
      },
      {
        field: "Office",
        header: this.translate.instant("phone"),
        class: "width-7p p-text-left p-text-truncate",
      },
      {
        field: "Mobile",
        header: this.translate.instant("mobile"),
        class: "width-6p p-text-left p-text-truncate",
      },
      {
        field: "ClientNumber",
        header: this.translate.instant("client.client_number"),
        class: "width-6p p-text-left p-text-truncate",
      },
      {
        field: "ClientID",
        header: this.translate.instant("sys_id"),
        class: "width-5p p-text-left p-text-truncate",
      },
      {
        field: "Email",
        header: this.translate.instant("email"),
        class: "width-7p p-text-left p-text-truncate",
      },
      {
        field: "BillingPartnerName",
        header: this.translate.instant("client.billing_partner_name"),
        class: "width-7p p-text-left p-text-truncate",
      },
      {
        field: "StaffAssignedName",
        header: this.translate.instant("client.staff_assigned_name"),
        class: "width-7p p-text-left p-text-truncate",
      },
      {
        field: "BusCity",
        header: this.translate.instant("client.business_city"),
        class: "width-5p p-text-left p-text-truncate",
      },
      {
        field: "BusStreet1",
        header: this.translate.instant("client.business_street1"),
        class: "width-5p p-text-left p-text-truncate",
      },
      {
        field: "BusStreet2",
        header: this.translate.instant("client.business_street2"),
        class: "width-5p p-text-left p-text-truncate",
      },
      {
        field: "BusState",
        header: this.translate.instant("client.business_state"),
        class: "width-4p p-text-left p-text-truncate",
      },
      {
        field: "BusZip",
        header: this.translate.instant("client.business_zip"),
        class: "width-5p p-text-left p-text-truncate",
      },
      {
        field: "unbilledtime",
        header: this.translate.instant("client.unbilled_time"),
        class: "width-5p p-text-right",
      },
      {
        field: "unbilledexpenses",
        header: this.translate.instant("client.unbilled_expenses"),
        class: "width-5p p-text-right",
      },
    ];

    let selectedCols = [];

    const defaultCols = [
      {
        field: "TotalInvBal",
        header: this.translate.instant("Accounts.invoice-balance"),
        class: "width-8p p-text-right p-text-truncate",
      }, {
        field: "CompanyLocation",
        header: this.translate.instant("company_location"),
        class: "width-10p p-text-left",
      },
      {
        field: "CustomerTypeDescription",
        header: this.translate.instant("client.type"),
        class: "width-7p p-text-left p-text-truncate",
      },
      {
        field: "GroupDescriptionIDArrayDesc",
        header: this.translate.instant("group"),
        class: "width-11p p-text-left p-text-truncate",
      },
      {
        field: "BillingGroup",
        header: this.translate.instant("Billing Group"),
        class: "width-8p p-text-left p-text-truncate",
      },
      {
        field: "ContactPerson",
        header: this.translate.instant("client.client_contact"),
        class: "width-8p p-text-left p-text-truncate",
      },
      {
        field: "Office",
        header: this.translate.instant("phone"),
        class: "width-7p p-text-left p-text-truncate ",
      },
      {
        field: "Mobile",
        header: this.translate.instant("mobile"),
        class: "width-6p p-text-left p-text-truncate",
      },
      {
        field: "ClientNumber",
        header: this.translate.instant("client.client_number"),
        class: "width-6p p-text-left p-text-truncate",
      },
      {
        field: "ClientID",
        header: this.translate.instant("sys_id"),
        class: "width-5p p-text-left p-text-truncate",
      },
    ];

    this.mangoAPISrvc
      .getUsersSelectedColsByUserId(
        this.encrDecSrvc.getObject(AppConstants.staffID)
      )
      .subscribe(
        (data: any) => {
          if (data.ClientListCols?.length > 0) {
            selectedCols = allCols.filter((col) =>
              data.ClientListCols.includes(col.field)
            );
            this.ClientListCols = data.ClientListCols;
          } else {
            selectedCols = [...defaultCols];
          }

          this.cols = [...allCols];
          this.selectedRowsPerPage = data.ClientListDefaultPagination || 10

          this._selectedColumns = selectedCols;
          this.globalFilterColumns = [
            ...["ClientName"],
            ...this._selectedColumns.map((col) => col.field),
          ];

          if (this.ClientListCols?.length > 0) {
            this._selectedColumns = this.mangoUtils.mapOrder(
              this._selectedColumns,
              this.ClientListCols,
              'field'
            );
          }
        },
        (err) => {
          selectedCols = [...defaultCols];

          this.cols = [...allCols];
          this.globalFilterColumns = [
            ...["ClientName"],
            ...selectedCols.map((col) => col.field),
          ];
        }
      );
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    const arr = val.map((col) => col.field);
    this._selectedColumns = this.cols.filter((col) => arr.includes(col.field));
    this.globalFilterColumns = [
      ...["ClientName"],
      ...this._selectedColumns.map((col) => col.field),
    ];
    this._selectedColumns = this.mangoUtils.mapOrder(
      this._selectedColumns,
      this.ClientListCols,
      'field'
    );

    this.saveSelectedCols(this._selectedColumns)
  }

  loadClients() {
    const self = this;
    // self.mangoAPISrvc.showLoader(true);
    this.isClientListTableLoading = true;
    this.encrDecSrvc.removeObject(AppConstants.clientCount);
    this.fetchClientsCount()
    this.getAllClientsDataSource();
  }

  fetchClientsCount() {
    this.mangoAPISrvc.fetchClientsCount().subscribe(
      (data) => {
        this.encrDecSrvc.addObject(AppConstants.clientCount, data);
        this.clientsCount = data;
      },
      (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  saveSelectedCols(list: Array<any>): void {
    let columnsToSave = "";
    list.map((col, index) => {
      if (index > 0) columnsToSave += `, ${col.field}`;
      else columnsToSave += col.field;
    });
    const objToSave = { ClientListCols: `{${columnsToSave}}` };
    this.mangoAPISrvc
      .updateUserSelectedColsByUserId(
        this.encrDecSrvc.getObject(AppConstants.staffID),
        objToSave
      )
      .subscribe(
        (data: any) => {
          this.ClientListCols = data.ClientListCols;
        },
        (err) => { }
      );
  }

  calculateFooter(clients) {
    let total = 0;
    if (clients.length > 0) {
      total = clients.reduce(function (a, b) {
        return a + Number(b["TotalInvBal"]);
      }, 0);
      this.totalUnbilledTime = clients.reduce(function (a, b) {
        return a + Number(b["unbilledtime"]);
      }, 0);
      this.totalUnbilledExpense = clients.reduce(function (a, b) {
        return a + Number(b["unbilledexpenses"]);
      }, 0);
    }
    this.totalInvoiceBal = total;
  }

  getParamsForClientsAPI() {
    switch (this.selectedTabIndex) {
      case 0:
        return `inactive=false&contact=false`
      case 1:
        return `inactive=true&contact=false`
      case 2:
        return `contact=true`
    }
  }

  getSavedClientsList() {
    switch (this.selectedTabIndex) {
      case 0:
        //return this.encrDecSrvc.getObject('clients_active') || [];
        return this.clientsListStorage['active'];
      case 1:
        //return this.encrDecSrvc.getObject('clients_inactive') || [];
        return this.clientsListStorage['inactive'];
      case 2:
        //return this.encrDecSrvc.getObject('clients_contacts') || [];
        return this.clientsListStorage['contacts'];
      case 3:
        //return this.encrDecSrvc.getObject('clients_all') || [];
        return this.clientsListStorage['all'];
    }
  }

  saveClientsListToStorage() {
    try {
      switch (this.selectedTabIndex) {
        case 0:
          //this.encrDecSrvc.addObject('clients_active', this.totalClientsDataSource);
          this.clientsListStorage['active'] = this.totalClientsDataSource;
          break;
        case 1:
          //this.encrDecSrvc.addObject('clients_inactive', this.totalClientsDataSource);
          this.clientsListStorage['inactive'] = this.totalClientsDataSource;
          break;
        case 2:
          //this.encrDecSrvc.addObject('clients_contacts', this.totalClientsDataSource);
          this.clientsListStorage['contacts'] = this.totalClientsDataSource;
          break;
        case 3:
          //this.encrDecSrvc.addObject('clients_all', this.totalClientsDataSource);
          this.clientsListStorage['all'] = this.totalClientsDataSource;
          break;
      }
    } catch (e) {
      console.log("error: ")
      console.log(e)
    }
  }

  refreshClientListCache() {
    this.encrDecSrvc.removeObject('clients_active');
    this.encrDecSrvc.removeObject('clients_inactive');
    this.encrDecSrvc.removeObject('clients_contacts');
    this.encrDecSrvc.removeObject('clients_all');
    this.getAllClientsDataSource();
  }

  getAllClientsDataSource() {
    if (!this.companyId) {
      return false;
    }
    const params = `?${this.getParamsForClientsAPI()}`;
    this.totalClientsDataSource = this.getSavedClientsList();
    if (this.totalClientsDataSource.length > 0) {
      this.calculateFooter(this.totalClientsDataSource);
      this.loadFilterGrid();
      this.mangoAPISrvc.showLoader(false);
      this.isClientListTableLoading = false;
      return;
    }
    // if(this.sharedSrvc.isTableView) this.mangoAPISrvc.showLoader(true);
    this.mangoAPISrvc.getAllClientsByCompanyId(this.companyId, params).subscribe(
      (clients: any) => {
        clients.map((item) => {
          item["contactcount"] = parseInt(item["contactcount"]);
          item["childItem"] = [];
          item["hasChildren"] = false;
          return item;
        });
        if (this.openTimeExpenseArr?.length == 0) {
          this.mangoAPISrvc.getSumOpenTimeAndExpense().subscribe((resultOpen: any) => {
            this.openTimeExpenseArr = resultOpen
            this.processDataSource(clients, resultOpen)
          }, (err) => {
            // this.mangoAPISrvc.showLoader(false)
            this.isClientListTableLoading = false;
            this.processDataSource(clients, this.openTimeExpenseArr)
          })
        } else {
          this.processDataSource(clients, this.openTimeExpenseArr)
        }

      },
      // (err) => this.mangoAPISrvc.showLoader(false)
    );
  }

  processDataSource(clients, resultOpenTime) {
    clients = clients.map((obj) => {
      const openTimeExpense = resultOpenTime?.find((item) => item.ClientID == obj.ClientID)
      obj["TotalInvBal"] = obj["TotalInvBal"] ? numeral(obj["TotalInvBal"]).value() : 0;
      obj["unbilledtime"] = openTimeExpense?.unbilledtime ? numeral(openTimeExpense["unbilledtime"]).value() : 0;
      obj["unbilledexpenses"] = openTimeExpense?.unbilledexpenses ? numeral(openTimeExpense["unbilledexpenses"]).value() : 0;
      obj["TotalInvBal"] = obj["TotalInvBal"] == "NaN" ? null : obj["TotalInvBal"];
      obj["GroupDescriptionIDArrayDesc"] = "";
      obj["GroupDescriptionIDArray"] = obj["GroupDescriptionIDArray"] || [];
      if (obj['GroupDescriptionIDArray'] && obj['GroupDescriptionIDArray'].length > 0) {
        obj['GroupDescriptionIDArray'].filter(item => item != -1);
        obj['GroupDescriptionIDArrayDesc'] = this.clientGroupCategory?.filter(group => obj['GroupDescriptionIDArray'].includes(group['CustomerGroupCategoryID']))
          .map(group => group['GroupDescription']).join(", ");
      }
      const billingGroup = this.clientGroupCategory.find(item => item['CustomerGroupCategoryID'] == obj['BillingGroupID']);
      if (billingGroup) {
        obj["BillingGroup"] = billingGroup['GroupDescription'];
      }
      return obj;
    });
    this.totalClientsDataSource = clients;
    this.savePaginationData( 0, this.selectedRowsPerPage );
    //this.loadFilterGrid();
    this.saveClientsListToStorage();
    setTimeout(() => {
      this.loadFilterGrid();
      this.calculateFooter(this.totalClientsDataSource);
      this.mangoAPISrvc.showLoader(false);
      this.isClientListTableLoading = false;
    }, 100);
  }

  clearSearchFilter() {
    this.filteredItemsSize = -1;
    this.encrDecSrvc.removeObject(
      AppConstants.listRoutePath + "clients_" + AppConstants.SearchString
    );
    this.encrDecSrvc.addObject(
      AppConstants.listRoutePath + "clients_" + AppConstants.SearchString,
      ""
    );
    const element = $(`#searchValue-${this.selectedTabIndex}`)[0];
    this.searchValue.nativeElement.value = this.searchTextStr = element.value = "";
    this.calculateFooter(this.totalClientsDataSource);
    this.filteredClients = [];
    this.focusedRowIndex=0;
    this.currentPage=0;
    this.paginatedClients = this.totalClientsDataSource.slice(0, this.selectedRowsPerPage);
  }


  onFilter(obj) {
    this.filteredItemsSize = obj.filteredValue.length;
    this.encrDecSrvc.addObject(
      AppConstants.listRoutePath + "clients_" + AppConstants.SearchString,
      obj.filters.global?.value
    );
    this.calculateFooter(obj.filteredValue);
    this.filteredClients = obj.filteredValue;
  }

  onRowExpandEvent(event) {
    if (event.data["childItem"].length > 0) {
      return false;
    }
    this.mangoAPISrvc.showLoader(true);

    this.mangoAPISrvc
      .getContacts(event.data["ClientID"])
      .subscribe((data: any) => {
        event.data["hasChildren"] = data.length > 0 ? true : false;
        event.data["childItem"] = data;

        this.mangoAPISrvc.showLoader(false);
      });
  }

  onRowSelect(data) {
    //let client = event.data
    //this.editClient(data);
  }

  sendEmailToClient(client) {
    window.location.href = "mailto:" + client.Email;
  }

  handleChange(e) {
    this.selectedTabIndex = e.index;
    setTimeout(() => {
      this.searchTextStr = this.encrDecSrvc.getObject(
        AppConstants.listRoutePath + "clients_" + AppConstants.SearchString
      );
      const element = $(`#searchValue-${this.selectedTabIndex}`)[0];
      this.searchValue.nativeElement.value = element.value = this.searchTextStr || "";
      this.isClientListTableLoading = true;
      this.fetchClientsCount()
      this.getAllClientsDataSource();
    }, 100)
  }

  updateDataSource(clientId, index) {
    const self = this;
    self.totalClientsDataSource.splice(
      self.totalClientsDataSource.findIndex((x) => x.clientId == clientId),
      1
    );
    self.filteredClients.splice(index, 1);
  }

  deleteClient(item, index) {
    const self = this;
    self.mangoAPISrvc.showLoader(true);
    self.mangoAPISrvc
      .getFilesAndContacts(item.ClientID)
      .subscribe((result: any) => {
        self.mangoAPISrvc.showLoader(false);
        if (result[0].length > 0 || result[1].length > 0) {
          Swal.fire({
            icon: "error",
            title: self.translate.instant("error"),
            text: self.translate.instant("client_has_records_warning"),
            showConfirmButton: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timer: 2000,
          });
        } else {
          self.confirmationSrvc.confirm({
            message: AppConstants.deleteMsg,
            header: AppConstants.deleteConfirm,
            accept: () => {
              self.mangoAPISrvc.showLoader(true);
              self.encrDecSrvc.removeObject(AppConstants.clientCount);
              const obj = {};
              obj["IShareClientID"] = item.IShareClientID;
              obj["vendorAPIKeyIS"] = self.companyData.vendorAPIKeyIS;
              self.mangoAPISrvc.deleteClient(item.ClientID, obj).subscribe(
                (res) => {
                  self.totalClientsDataSource =
                    self.totalClientsDataSource.filter(
                      (client) => client.ClientID !== item.ClientID
                    );
                  const userLogs = {}
                  userLogs['Action'] = "Delete Customer";
                  userLogs['Description'] = item['ClientName'] + " ##" + item['ClientID'];
                  userLogs['Table'] = "Customers";
                  const isManaging = self.encrDecSrvc.getObject(AppConstants.isManagingAccount);
                  if(!isManaging){
                    self.mangoAPISrvc.addUserLogs(userLogs).subscribe(() => { }, (err) => {
                      console.log(err);
                    });
                  }
                
                  this.saveClientsListToStorage();
                  // self.encrDecSrvc.clientList = self.totalClientsDataSource;
                  self.mangoAPISrvc.showLoader(false);
                  self.subtractClientCount(self.selectedTabIndex, item);
                  // self.filterClients(self.selectedTabIndex);
                  self.mangoAPISrvc.notify(
                    "success",
                    self.translate.instant("delete-message"),
                    AppConstants.deleteMessage
                  );
                  self.mangoAPISrvc.showLoader(false);
                },
                (error) => {
                  //Error callback
                  self.mangoAPISrvc.notify(
                    "error",
                    self.translate.instant("mgmt-delete"),
                    error
                  );
                  self.mangoAPISrvc.showLoader(false);
                }
              );
            },
          });
        }
      });
  }

  subtractClientCount(status, item) {
    switch (status) {
      case 0: {
        this.clientsCount.all -= 1;
        this.clientsCount.active -= 1;
        break;
      }
      case 1: {
        this.clientsCount.all -= 1;
        this.clientsCount.inactive -= 1;
        break;
      }
      case 2: {
        this.clientsCount.contacts -= 1;
        break;
      }
      default: {
        if (item["Inactive"] != true && item["ContactRecord"] != true) {
          this.clientsCount.all -= 1;
          this.clientsCount.active -= 1;
        } else if (item["Inactive"] == true && item["ContactRecord"] != true) {
          this.clientsCount.all -= 1;
          this.clientsCount.inactive -= 1;
        } else if (item["ContactRecord"] == true) {
          this.clientsCount.contacts -= 1;
        }
        break;
      }
    }

    this.encrDecSrvc.addObject(AppConstants.clientCount, this.clientsCount);
    console.log(
      "Client count",
      this.encrDecSrvc.getObject(AppConstants.clientCount)
    );
  }

  redirectToClientView(clientId: number, rowData: any) {
    this.isClientList = false;
    this.sharedSrvc.selClient = rowData;
    this.encrDecSrvc.addObject(AppConstants.selectedClientRecord, rowData);
    this.encrDecSrvc.addObject(AppConstants.clientID, clientId);
    this.encrDecSrvc.addObject(AppConstants.ClientName, rowData["ClientName"]);
    this.encrDecSrvc.addObject(
      AppConstants.selectedClientTabIndex,
      this.selectedTabIndex
    );
    if (clientId > 0) {
      this.getAllCounts(clientId);
    } else {
      this.mangoAPISrvc.fireClientView(true);
    }
  }

  getAllCounts(clientId) {
    const parent = this;
    parent.mangoAPISrvc.showLoader(true);
    parent.mangoAPISrvc.getAllDataCounts(clientId).subscribe(function (data) {
      parent.encrDecSrvc.addObject(AppConstants.allDataCountsForClient, data);
      parent.mangoAPISrvc.fireClientView(true);
      parent.mangoAPISrvc.showLoader(false);
    });
  }

  onColReorder(event) {
    this.saveSelectedCols(event.columns)
  }

  mapOrder(array, order, key) {
    array.sort(function (a, b) {
      if (order.indexOf(a[key]) > order.indexOf(b[key])) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }
  
  onInputKeyDown(event: KeyboardEvent): void {
    const key = event.key || event.code || event.which;
    if (key === 'ArrowDown') {
      const clientArray = this.filteredClients.length>0 
                            ? this.filteredClients.slice(this.currentPage*this.selectedRowsPerPage,this.currentPage*this.selectedRowsPerPage+this.selectedRowsPerPage)
                            : this.paginatedClients;
      this.focusedRowIndex = 0;
      this.selectedClient = clientArray[0];
      this.focusTableRow();
      event.preventDefault();
    }else{
      this.currentPage=0;
      this.selectedClient=null;
    }
  }
  focusTableRow(): void {
     const rowsArray = this.tableRows.toArray();
     if (this.focusedRowIndex !== null && rowsArray[this.focusedRowIndex]) {
        rowsArray[this.focusedRowIndex].nativeElement.focus();
     }
  }
  onTableKeyDown(event: KeyboardEvent, index: number): void {
    const key = event.key || event.code || event.which;
    const clientArray = this.filteredClients.length>0 
                          ? this.filteredClients.slice(this.currentPage*this.selectedRowsPerPage,this.currentPage*this.selectedRowsPerPage+this.selectedRowsPerPage)
                          : this.paginatedClients;
    if (key === 'ArrowUp' ) {
      if (index-this.currentPage*this.selectedRowsPerPage == 0){
      this.focusedRowIndex = null;
      this.selectedClient = null;
      this.searchValue.nativeElement.focus();
      }else{
        if( index-this.currentPage*this.selectedRowsPerPage > 0) {
          this.selectedClient = clientArray[index-this.currentPage*this.selectedRowsPerPage-1];
          this.focusedRowIndex = index-this.currentPage*this.selectedRowsPerPage - 1;
          this.focusTableRow();
        }
      }
      event.preventDefault();
    } else if(key === 'ArrowDown') {
      if (((index-this.currentPage*this.selectedRowsPerPage) < this.selectedRowsPerPage   - 1 && 
          (index-this.currentPage*this.selectedRowsPerPage) < clientArray.length - 1)) {
        this.selectedClient = clientArray[index-this.currentPage*this.selectedRowsPerPage + 1];
        this.focusedRowIndex = index-this.currentPage*this.selectedRowsPerPage + 1;
        this.focusTableRow();
      }
      event.preventDefault();
    }
    if (key === 'Enter') {
      debugger;
      this.selectedClient = clientArray[index-this.currentPage*this.selectedRowsPerPage];
      this.redirectToClientView(this.selectedClient.ClientID, this.selectedClient);
    }
  }
  savePaginationData(first, rows) {
    this.paginatedClients = this.totalClientsDataSource.slice(first, first + rows);
  }
}
