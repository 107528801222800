<div
  class="clientother"
>
  <span
    class="pull-right p-mr-3 clientform-btn-top"
  >
    <button
      pRipple
      pButton

      label="{{'Upload' | translate}}"
      icon="fas fa-check"
      class="p-mr-1 p-button-sm"

      [disabled]="formDisabled"
      [disabled]="!selectedType"

      (click)="showFiles()"
    >
    </button>
    <button
      pButton
      pRipple
      label="{{'import-options' | translate}}"
      [disabled]="formDisabled"
      icon="fas fa-check"
      class="p-mr-1 p-button-sm"
      [disabled]="!selectedType"
      (click)="showOptionSetting()"
    >
    </button>
    <button
      pButton
      pRipple
      label="Show Mappings"
      [disabled]="!selectedType"
      class="p-mr-1 p-button-sm"
      (click)="showTaxMapping = true;displayTaxMapping();"
    >
    </button>
  </span>

  <div
    class="layout-content p-pt-0"
  >
    <div
      class="p-col-12"
    >
      <div
        class="card p-mb-1 p-pl-5 p-py-3"
      >
        <div
          class="p-col-12"
        >
          <label
            class="label-text p-mr-4"
          >
            {{'tax_vendor' | translate}}
          </label>
          <p-dropdown
            [options]="importList"
            [(ngModel)]="selectedType"
            (onChange)="selectType($event);"
            placeholder="Select Import Type"
            styleClass="width-14p"
          >
          </p-dropdown>
        </div>
        <div
          class="p-col-12"
          *ngIf="(selectedType === 'ProSeries' || selectedType === 'Lacerte' || selectedType === 'Drake')"
        >
          <label
            class="label-text p-mr-3"
          >
            {{'return_type' | translate}}
          </label>
          <p-dropdown
            [options]="typeReturnList"
            [(ngModel)]="selectedReturnType"
            placeholder="Select Return Type"
            styleClass="width-14p"
          >
          </p-dropdown>
        </div>
      </div>
      <div
        class="card p-pt-0"
      >
        <div
          class="p-col-12 p-pt-0"
        >
          <p-table
            [value]="csvData"
            [responsive]="true"
            sortField="UploadedAt"
            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
            [globalFilterFields]="['FilePath', 'UploadedAt', 'InProgress', 'StartedAt', 'IsCompleted', 'TotalRecords', 'NumSuccess', 'NumFailed', 'CompletedAt']"
            [sortOrder]="-1"
            [rowHover]="true"
            #dt
            [rows]="csvData.length"
            [scrollable]="true"
            [scrollHeight]="'calc(90vh - 350px)'"
            [virtualScroll]="true"
          >
            <ng-template
              pTemplate="caption"
            >
              <div
                class="table-header"
              >
                <span
                  class="p-input-icon-left"
                >
                  <i
                    class="fal fa-search"
                  >
                  </i>
                  <input
                    #searchValue
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="{{ 'globalsearch' | translate }}"
                  />
                </span>
                <span>
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-text"
                    tooltipPosition="top"
                    pTooltip="{{ 'clear' | translate }}"
                  >
                  </button>
                </span>
                <p-message
                  *ngIf="filteredItemsSize > -1"
                  severity="success"
                  text="{{filteredItemsSize}} {{'records_found' | translate}}."
                  styleClass="p-ml-2"
                >
                </p-message>
              </div>
            </ng-template>
            <ng-template
              pTemplate="header"
            >
              <tr>
                <th
                  class="width-6p p-text-center"
                >
                  {{'actions' | translate}}
                </th>
                <th
                  class="width-12p p-text-left"
                  pSortableColumn="FilePath"
                  pTooltip="File Name"
                  tooltipPosition="top"
                >
                  {{'File' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="FilePath"
                  >
                  </p-sortIcon>
                </th>
                <th
                  class="width-8p p-text-center"
                  pSortableColumn="UploadedAt"
                  pTooltip="{{'Uploaded_At' | translate}}"
                  tooltipPosition="top"
                >
                  {{'Uploaded' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="UploadedAt"
                  >
                  </p-sortIcon>
                </th>
                <th
                  class="width-8p p-text-center"
                  pSortableColumn="StartedAt"
                  pTooltip="Started At"
                  tooltipPosition="top"
                >
                  {{'Started' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="StartedAt"
                  >
                  </p-sortIcon>
                </th>
                <th
                  class="width-6p p-text-center"
                  pSortableColumn="TotalRecords"
                  pTooltip="Total Records"
                  tooltipPosition="top"
                >
                  {{'Total' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="TotalRecords"
                  >
                  </p-sortIcon>
                </th>
                <th
                  class="width-6p p-text-center"
                  pSortableColumn="NumSuccess"
                  pTooltip="Num Success"
                  tooltipPosition="top"
                >
                  {{'Success' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="NumSuccess"
                  >
                  </p-sortIcon>
                </th>
                <th
                  class="width-6p p-text-center"
                  pSortableColumn="NumFailed"
                  pTooltip="Num Failed"
                  tooltipPosition="top"
                >
                  {{'Failed' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="NumFailed"
                  >
                  </p-sortIcon>
                </th>
                <th
                  class="width-8p p-text-center"
                  pSortableColumn="CompletedAt"
                  pTooltip="Completed At"
                  tooltipPosition="top"
                >
                  {{'completed' | translate}}
                  <i
                    class="fa fa-question-circle ic-blue"
                  >
                  </i>
                  <p-sortIcon
                    field="CompletedAt"
                  >
                  </p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-rowIndex="rowIndex"
            >
              <tr>
                <td
                  class="width-6p p-text-center"
                >
                  <div
                    class="p-formgroup-inline"
                  >
                    <div
                      class="p-col p-p-0"
                    >
                      <button
                        *ngIf="! rowData.InProgress && ! rowData.IsCompleted"
                        pButton
                        type="button"
                        label="{{'Start' | translate}}"
                        class="p-button-sm"
                        style="width: 60px; height: 30px; border: none;"
                        pTooltip="{{'Start' | translate}}"
                        tooltipPosition="top"
                        class="ic-white bg-green p-mr-1 cursor-hand"
                        (click)="processCSV(rowData.CustomerCSVID, rowData.FileName)"
                      >
                      </button>
                      <!--
                      <i
                        *ngIf="! rowData.InProgress && ! rowData.IsCompleted"
                        class="fa fa-play-circle ic-green p-mr-1 cursor-hand"
                        aria-hidden="true"
                        pTooltip="{{'Start' | translate}}"
                        tooltipPosition="top"
                        tooltipPosition="top"
                        (click)="processCSV(rowData.CustomerCSVID, rowData.FileName)"
                      >
                      </i>
                      -->
                    </div>
                    <div
                      class="p-col p-p-0"
                    >
                      <button
                        pButton
                        type="button"
                        label="{{'mgmt-delete' | translate}}"
                        class="p-button-sm"
                        style="width: 60px; height: 30px; border: none;"
                        pTooltip="{{'mgmt-delete' | translate}}"
                        tooltipPosition="top"
                        (click)="removeCSV(rowData);$event.stopPropagation();"
                        class="ic-white bg-red cursor-hand"
                      >
                      </button>
                      <!--
                      <i
                        pTooltip="{{'mgmt-delete' | translate}}"
                        tooltipPosition="top"
                        (click)="removeCSV(rowData);$event.stopPropagation();"
                        class="fal fa-trash-alt ic-red cursor-hand"
                        tooltipPosition="top"
                        aria-hidden="true"
                      >
                      </i>
                      -->
                    </div>
                  </div>
                </td>
                <td
                  class="width-12p p-text-left"
                  showDelay="800"
                  pTooltip="{{rowData.FileName}}"
                  tooltipPosition="top"
                  tooltipStyleClass="tooltip-no-wrap"
                >
                  {{rowData.FileName}}
                </td>
                <td
                  class="width-8p p-text-center"
                  pTooltip="{{rowData.UploadedAt | date: 'MM/dd/yyy HH:mm' : 'UTC'}}"
                  tooltipStyleClass="tooltip-no-wrap"
                  showDelay="800"
                  tooltipPosition="top"
                >
                  {{rowData.UploadedAt | date: 'MM/dd/yyy HH:mm' : 'UTC'}}
                </td>
                <td
                  class="width-8p p-text-center"
                  pTooltip="{{rowData.StartedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC'}}"
                  tooltipStyleClass="tooltip-no-wrap"
                  showDelay="800"
                  tooltipPosition="top"
                >
                  {{rowData.StartedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC'}}
                </td>
                <td
                  class="width-6p p-text-center"
                  showDelay="800"
                  pTooltip="{{rowData.TotalRecords}}"
                  tooltipPosition="top"
                >
                  {{rowData.TotalRecords}}
                </td>
                <td
                  class="width-6p p-text-center"
                  showDelay="800"
                  pTooltip="{{rowData.NumSuccess}}"
                  tooltipPosition="top"
                >
                  {{rowData.NumSuccess}}
                </td>
                <td
                  class="width-6p p-text-center"
                  showDelay="800"
                  pTooltip="{{rowData.NumFailed}}"
                  tooltipPosition="top"
                >
                  {{rowData.NumFailed}}
                </td>
                <td
                  class="width-8p p-text-center"
                  pTooltip="{{rowData.CompletedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC'}}"
                  tooltipStyleClass="tooltip-no-wrap"
                  showDelay="800"
                  tooltipPosition="top"
                >
                  {{rowData.CompletedAt | date: 'MM/dd/yyyy HH:mm' : 'UTC'}}
                </td>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="emptymessage"
            >
              <tr>
                <td
                  colspan="8"
                >
                  <p-message
                    severity="info"
                    text="{{'data_not_found' | translate}}."
                    styleClass="p-px-2"
                  >
                  </p-message>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar
  [(visible)]="showAttachedFiles"
  position="bottom"
  [style]="{'min-height':'300px'}"
>
  <div
    class="card"
  >
    <div
      class="header-color text-nowrap col-sm-9"
    >
      <h4
        class="label-text"
      >
        {{'Upload' | translate}} {{'File' | translate}}
      </h4>
    </div>
    <div
      class="p-grid p-mx-2"
    >
      <div class="card flex justify-content-center" style="width: 100%;">
        <p-fileUpload name="fileupload" #fileUpload [auto]="true" chooseLabel="Upload" (onSelect)="onSelect($event)"
          [multiple]="false" accept="text/csv" maxFileSize="3145728">
          <ng-template pTemplate="content">
            <div class="p p-d-flex p-jc-center" *ngIf="files.length == 0" style="height: 40px;">
              Drag file(s) here
            </div>
            <ul *ngIf="files.length > 0">
              <li *ngFor="let file of files">
                {{ file.name }} - {{ file.size }} bytes
              </li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
  </div>
</p-sidebar>

<p-dialog
  [(visible)]="showOptions"
  [style]="{width: '64vw'}"
  modal="true"
>
  <ng-template
    pTemplate="header"
  >
    <div
      class="card p-my-0 p-pl-0 p-py-0 p-fluid"
    >
      <div
        class="p-field p-col-12 p-pb-0 p-mb-0"
      >
        <h4
          class="label-text p-mb-0"
        >
          Tax Import Options - Form {{selectedReturnType}}
        </h4>
      </div>
    </div>
  </ng-template>
  <div
    class="p-mt-0 p-px-4"
  >
    <form
      [formGroup]="TaxOptionsForm"
    >
      <h4
        class="label-text p-mb-0"
      >
        Client Settings
      </h4>
      <div
        class="p-grid p-mb-2"
      >
        <div
          class="p-field-radiobutton p-col-3 p-mb-0"
        >
          <p-radioButton
            name="ClientOptions"
            formControlName="ClientOptions"
            value="AddOnly"
            inputId="AddOnly"
          >
          </p-radioButton>
          <label
            for="AddOnly"
          >
            Add New Clients Only
          </label>
        </div>
        <div
          class="p-field-radiobutton p-col-3 p-mb-0"
        >
          <p-radioButton
            name="ClientOptions"
            formControlName="ClientOptions"
            value="AddAndUpdate"
            inputId="AddAndUpdate"
          >
          </p-radioButton>
          <label
            for="AddAndUpdate"
          >
            Add / Update Clients
          </label>
        </div>
        <div
          class="p-field-radiobutton p-col-6 p-mb-0 p-px-0 p-fluid"
        >
          <div
            class="p-field p-col-12 p-mb-0"
          >
            <label
              class="label-text p-mr-4 required-field"
              style="font-weight: 500 !important;"
            >
              {{'Assign_engagement' | translate}}
              <i
                pTooltip="{{ 'taximports.engagement_tooltip' | translate }}"
                tooltipStyleClass="tooltip-width-300"
                class="fa fa-question-circle ic-blue question-tooltip-icon"
                tooltipPosition="right"
              >
              </i>
            </label>
            <p-dropdown
              [options]="engagementsTypes"
              id="itemEngagementSelected"
              formControlName="SelectedEngagements"
              (onChange)="onSelectedEngagement($event)"
              scrollHeight="150px"
              [style]="{'minWidth':'100%'}"
              appendTo="body"
              placeholder="Select Engagement"
            >
            </p-dropdown>
          </div>
        </div>
      </div>
      <div
        class="p-grid p-mb-2"
      >
        <div
          class="p-field-radiobutton p-col-3"
        >
          <p-radioButton
            inputId="searchByClientNameOption"

            formControlName="SearchClientOption"
            name="SearchClientOption"
            value="SearchByClientName"
          >
          </p-radioButton>
          <label
            for="searchByClientNameOption"
          >
            Search by Client Name
          </label>
        </div>
        <div
          class="p-field-radiobutton p-col-3"
        >
          <p-radioButton
            inputId="searchByClientNumberOption"

            formControlName="SearchClientOption"
            name="SearchClientOption"
            value="SearchByClientNumber"
          >
          </p-radioButton>
          <label
            for="searchByClientNumberOption"
          >
            Search by Client Number
          </label>
        </div>
      </div>
      <div
        class="p-grid p-mb-2 p-mt-2"
      >
        <div
          class="p-field p-col-3 p-mb-0"
        >
          <label
            class="label-text p-mr-6"
            style="font-weight: 500 !important;"
          >
            {{'Assign_Billing_Partner' | translate}}
          </label>
          <p-dropdown
            [options]="staffList"
            formControlName="BillingPartner"
            appendTo="body"
            placeholder="Select Billing Partner"
            scrollHeight="150px"
            [style]="{'minWidth':'100%'}"
          >
          </p-dropdown>
        </div>
        <div
          class="p-field p-col-3 p-mb-0"
        >
          <label
            class="label-text p-mr-6"
            style="font-weight: 500 !important;"
          >
            {{'Assign_Originating_Partner' | translate}}
          </label>
          <p-dropdown
            [options]="staffList"
            formControlName="OrigPartner"
            appendTo="body"
            placeholder="Select Originating Partner"
            scrollHeight="150px"
            [style]="{'minWidth':'100%'}"
          >
          </p-dropdown>
        </div>
        <div
          class="p-field p-col-3 p-mb-0"
        >
          <label
            class="label-text p-mr-6"
            style="font-weight: 500 !important;"
          >
            {{'Assign_Staff' | translate}}
          </label>
          <p-dropdown
            [options]="staffList"
            formControlName="StaffAssignedID"
            appendTo="body"
            placeholder="Select Staff"
            scrollHeight="150px"
            [style]="{'minWidth':'100%'}"
          >
          </p-dropdown>
        </div>
        <div
          class="p-field p-col-3 p-mb-0"
        >
          <label
            class="label-text p-mr-6"
            style="font-weight: 500 !important;"
          >
            {{'Assign_Client_Type' | translate}}
          </label>
          <p-dropdown
            [options]="cTypeList"
            formControlName="ClientType"
            appendTo="body"
            placeholder="Select Client Type"
            scrollHeight="150px"
            [style]="{'minWidth':'100%'}"
          >
          </p-dropdown>
        </div>
      </div>
      <h4
        class="label-text"
      >
        Invoice Settings
      </h4>
      <div
        class="p-grid p-mb-2"
      >
        <div
          class="p-field-radiobutton p-col-3"
        >
          <p-radioButton
            name="InvoiceOptions"
            formControlName="InvoiceOptions"
            value="InvoiceNoAdd"
            inputId="InvoiceWithPayment"
          >
          </p-radioButton>
          <label
            for="InvoiceWithPayment"
          >
            Don't add Invoices
          </label>
        </div>
        <div
          class="p-field-radiobutton p-col-3"
          *ngIf="selectedType !== 'Drake'"
        >
          <p-radioButton
            name="InvoiceOptions"
            formControlName="InvoiceOptions"
            value="InvoiceWithPayment"
            inputId="InvoiceWithPayment"
          >
          </p-radioButton>
          <label
            for="InvoiceWithPayment"
          >
            Add Invoices w/ Payment
          </label>
        </div>
        <div
          class="p-field-radiobutton p-col-6"
          *ngIf="selectedType !== 'Drake'"
        >
          <p-radioButton
            name="InvoiceOptions"
            formControlName="InvoiceOptions"
            value="InvoiceWithoutPayment"
            inputId="InvoiceWithoutPayment"
          >
          </p-radioButton>
          <label
            for="InvoiceWithoutPayment"
          >
            Add Invoices {{ selectedType === 'Drake' ? "" : "w/ no Payment"}}
          </label>
        </div>
      </div>
      <div
        class="p-grid p-mb-2"
      >
        <div
          class="p-field p-col-12"
          *ngIf="TaxOptionsForm.get('InvoiceOptions').value !== 'InvoiceNoAdd'"
        >
          <label
            class="label-text p-mr-4 required-field"
            style="font-weight: 500 !important;"
          >
            {{'billing-invoicing.invoice-description' | translate}}
            <i
              pTooltip="{{ 'taximports.description_tooltip' | translate }}"
              tooltipStyleClass="tooltip-width-300"
              class="fa fa-question-circle ic-blue question-tooltip-icon"
              tooltipPosition="right"
            >
            </i>
          </label>
          <textarea
            pInputTextarea
            class="width-100p"
            [rows]="2"
            formControlName="InvoiceDescription"
            name="InvoiceDescription"
            style="resize: vertical;"
           
          >
          </textarea>
        </div>
      </div>
      <div
        class="p-grid p-mb-2"
      >
        <div
          class="p-field p-col-3"
          *ngIf="TaxOptionsForm.get('InvoiceOptions').value !== 'InvoiceNoAdd'"
        >
          <label
            class="label-text p-mr-6 required-field"
            style="font-weight: 500 !important;"
          >
            {{'Year' | translate}}
            <i
              pTooltip="{{ 'taximports.year_tooltip' | translate }}"
              tooltipStyleClass="tooltip-width-300"
              class="fa fa-question-circle ic-blue question-tooltip-icon"
              tooltipPosition="right"
            >
            </i>
          </label>
          <p-dropdown
            [options]="yearList"
            formControlName="SelectedYear"
            appendTo="body"
            placeholder="Select Year"
            scrollHeight="150px"
            [style]="{'minWidth':'100%'}"
          >
          </p-dropdown>
        </div>
        <div
          class="p-field p-col-9"
          *ngIf="TaxOptionsForm.get('InvoiceOptions').value !== 'InvoiceNoAdd'"
        >
          <div
            class="p-col-12 p-pt-0"
          >
            <label
              class="label-text p-mr-4 required-field"
              style="font-weight: 500 !important;"
            >
              {{'release-time-expenses' | translate}}
              <i
                pTooltip="{{ 'taximports.releasetime_tooltip' | translate }}"
                tooltipStyleClass="tooltip-width-300"
                class="fa fa-question-circle ic-blue question-tooltip-icon"
                tooltipPosition="right"
              >
              </i>
            </label>
          </div>
          <div
            class="p-col-12"
          >
            <p-checkbox
              formControlName="isReleaseTimeExpense"
              [binary]="true"
              styleClass="p-pb-4"
            >
            </p-checkbox>
          </div>
        </div>
      </div>
    </form>
  </div>
  <ng-template
    pTemplate="footer"
  >
    <button
      pButton
      pRipple
      label="OK"
      [disabled]="isFormInvalid()"
      icon="fas fa-check"
      class="p-mr-1 p-button-sm"
      (click)="showOptionSetting()"
    >
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="showTaxMapping"
  [style]="{width: '32vw'}"
  modal="true"
  (onHide)="showTaxMapping = false;"
>
  <ng-template
    pTemplate="header"
  >
    <div
      class="card p-my-0 p-pl-0 p-py-0 p-fluid"
    >
      <div
        class="p-field p-col-12 p-pb-0 p-mb-0"
      >
        <h4
          class="label-text p-mb-0"
        >
          Mappings - {{selectedType}} Form {{selectedReturnType}}
        </h4>
      </div>
    </div>
  </ng-template>
  <div
    class="p-mt-0 p-px-4"
  >
    <p-table
      [value]="taxTblMapping"
      [responsive]="true"
      styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
      [rowHover]="true"
      #dt
      [scrollable]="true"
      [scrollHeight]="'calc(90vh - 350px)'"
    >
      <ng-template
        pTemplate="header"
      >
        <tr>
          <th
            class="width-50p p-text-left"
          >
            {{ selectedType }} Export Fields
          </th>
          <th
            class="width-50p p-text-left"
          >
            Required
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-rowIndex="rowIndex"
      >
        <tr>
          <td
            class="width-50p p-text-left"
          >
            {{rowData.value}}
          </td>
          <td
            class="width-50p p-text-left {{rowData.required ? 'ic-green' : ''}}"
          >
            {{rowData.required ? 'Yes' : 'No'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
