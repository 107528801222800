<div class="cashreceipts">
    <div class="layout-content p-pt-0">
        <form [formGroup]="mycashReceiptsForm" autocomplete="nope" novalidate>
            <!-- buttons -->
            <span class="pull-right p-mr-3 clientform-btn-top">
                <p-splitButton *ngIf="isSearchByClient != 2" label="{{ 'save_button' | translate }}"
                    icon="fal fa-floppy-o" styleClass="p-button-sm split-button-size" [disabled]="!isFormValid"
                    (onClick)="saveConfirmationAction(true);" [model]="items">
                </p-splitButton>
                <button style="width: 60px;" pButton pRipple *ngIf="isSearchByClient == 2"
                    label="{{ 'save_button' | translate }}" [disabled]="!isFormValid" class="p-button-sm p-pr-2"
                    (click)="saveConfirmationAction(true);" type="button">
                </button>
                <button pButton pRipple label="{{ 'clear' | translate }}" icon="fad fa-eraser"
                    [disabled]="isPaymentFlowRequired" class="p-button-sm p-px-2 p-mx-2" tooltipPosition="top"
                    pTooltip="{{ 'clear' | translate }}" (click)="onClearClick()" type="button"></button>
                <button pButton pRipple label="{{ 'Accounts.make-payment' | translate }}" icon="fal fa-university"
                    class="p-button-sm p-pr-2 p-mr-2" tooltipPosition="top"
                    pTooltip="{{ 'Accounts.make-payment' | translate }}" routerLinkActive="active-link"
                    routerLink="/accounting/deposits" type="button"></button>
                <app-apply-advance-to-invoice *ngIf="isSearchByClient != 2" [selectedClient]="selClient"
                    [disabled]="!(selClient?.ClientID && selClient?.ClientName)"
                    (onCloseApplyAdvanceDialog)="onCloseApplyAdvanceDialog($event)"
                    [tooltip]="'Apply Multiple Advance Payments to Invoice(s)'" [label]="'Apply Advance Payments'">
                </app-apply-advance-to-invoice>
            </span>
            <div class="p-fluid p-formgrid p-grid p-mx-2">
                <div class="p-col-12">
                    <div class="card p-py-3">
                        <div class="p-formgroup-inline">
                            <div class="p-formgroup-inline p-col-3 p-mb-0">
                                <span class="width-100p">
                                    <label class="required-field label-text p-px-0">{{labelStr}}
                                    </label>
                                    <div class="pull-right" style="margin-top: -7px;">

                                        <label class="label-text p-mr-2 p-mb-2" style="display: inline-block;">
                                            {{'Search by' | translate }}</label>
                                        <button type="button" pButton pRipple icon="pi pi-chevron-right"
                                            (click)="menu.toggle($event)"></button>
                                        <p-menu appendTo="body" #menu [popup]="true"
                                            styleClass="button-list-cash-receipt" [model]="buttonsDeleteList">
                                        </p-menu>
                                    </div>
                                </span>
                                <span class="p-mt-2" *ngIf="isSearchByClient == 0" style="width: 100%;">
                                    <p-autoComplete (onShow)="onDropdownShow()" #autoCompleteRef  [(ngModel)]="selClient" [ngModelOptions]="{standalone: true}"
                                        [inputStyle]="{'font-weight': '700 !important'}"
                                        class="cash-receipt-autocomplete" autofocus
                                        [disabled]="clientAutoCompleteDisable || transactionData"
                                        [forceSelection]="true" panelStyleClass="client-dropdown-autocomplete"
                                        autocomplete="off" [suggestions]="filteredClients"
                                        (completeMethod)="filterClients($event)" field="ClientName"
                                        (onSelect)="handleSelectClick($event.value);" ngDefaultControl id="selClient"
                                        placeholder="Type {{labelStr}}" #selclientID="ngModel" [dropdown]="true"
                                        required>
                                        <ng-template let-client pTemplate="item">
                                            <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' +
                                                client.ClientNumber : '' }}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                </span>
                                <span class="p-mt-2" *ngIf="isSearchByClient == 1" style="width: 100%;">
                                    <p-autoComplete [(ngModel)]="selInvoiceNo" [disabled]="transactionData"
                                        class="cash-receipt-autocomplete" [ngModelOptions]="{standalone: true}"
                                        [suggestions]="filteredInvoices" (completeMethod)="filteredInvoicesFun($event)"
                                        field="ClientName" (onSelect)="handleInvoiceSelectClick($event.value);" autofocus
                                        ngDefaultControl placeholder="Type {{labelStr}}" name="selclientID"
                                        id="selInvoiceNo" #selclientID="ngModel" required>
                                        <ng-template let-client pTemplate="item">
                                            <div>{{client.ClientName}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                </span>
                                <span class="p-mt-2" *ngIf="isSearchByClient == 2" style="width: 100%;">
                                    <p-autoComplete [(ngModel)]="selClientGroup" [disabled]="transactionData"
                                        class="cash-receipt-autocomplete" [ngModelOptions]="{standalone: true}"
                                        [suggestions]="filteredGroups" (completeMethod)="filterGroups($event)"
                                        id="selClientGroup" field="label" ngDefaultControl
                                        (onSelect)="handleGroupSelect($event.value);" autofocus
                                        placeholder="Type {{labelStr}}" #selclientID="ngModel" [dropdown]="true"
                                        required>
                                        <ng-template let-group pTemplate="item">
                                            <div>{{group.label}}</div>
                                        </ng-template>
                                    </p-autoComplete>
                                </span>
                            </div>
                            <div class="p-field p-col-1 p-mb-0 p-mt-5 p-pl-4 p-pt-2" *ngIf="isSearchByClient == 0">
                                <p-checkbox [(ngModel)]="isIncludeActive" binary="true" tabindex="-1"
                                    [ngModelOptions]="{standalone: true}" label="{{ 'show_inactive' | translate }}"
                                    class="label-text">
                                </p-checkbox>
                            </div>
                            <div class="p-field p-col-2 p-mb-0 p-mt-5 p-pl-5 p-pt-2">
                                <p-checkbox [(ngModel)]="isAutoApply" binary="true" (ngModelChange)="autoApply()"
                                    [ngModelOptions]="{standalone: true}" label="{{ 'auto_apply' | translate }}"
                                    tooltipPosition="top" pTooltip="{{ 'allocate_payment' | translate }}"
                                    class="label-text" tabindex="-1">
                                </p-checkbox>
                            </div>
                            <div class="p-field p-col-1 p-mb-0 p-mt-5 p-pl-4 p-pt-2" *ngIf="isSearchByClient != 0">
                            </div>
                            <div
                              class="p-field p-col-2 p-mb-0"
                            >
                                <label
                                  class="label-text"
                                >
                                  {{ 'Accounts.payment-date' | translate }}
                                </label>
                                <p-calendar
                                  formControlName="PaymentDate"

                                  id="paymentDate"

                                  yearRange="2000:2090"
                                  dateFormat="mm/dd/yy"

                                  [monthNavigator]="true"
                                  [yearNavigator]="true"

                                  (onSelect)="
                                    verifySystemLocking( $event );
                                    validatePaymentDate( );
                                  "
                                >
                                </p-calendar>
                            </div>
                            <div class="p-field p-col-2 p-ml-4 p-mb-0" *ngIf="openRetainersDataSource.length > 0">
                                <div class="p-grid nested-grid miGrid-cr bg-green p-m-0 p-py-3">
                                    <div class="p-col-2">
                                        <div><i class="fal fa-money-check-edit ic-size-40"></i></div>
                                    </div>
                                    <div class="p-col-10 p-text-right">
                                        <div class="p-grid">
                                            <div class="p-col-12 ic-size-14">
                                                {{'client.advpayments' | translate}}
                                            </div>
                                            <div class="p-col-12 ic-size-18 p-mt-1">
                                                <div>
                                                    {{ totalNumberPaymentUnappliedValue |
                                                    currency:"USD":"symbol":"1.2-2" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- totalNumberPaymentUnapplied -->
                        <!--  end of card -->
                    </div>
                </div>
            </div>

            <div class="card p-mt-1 p-mx-3 p-pb-2 p-mb-0">
                <h4 class="label-text">{{ 'billing-invoicing.payment-information' | translate }} </h4>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-3">
                        <div class="p-field p-col-12">
                            <label class="label-text required-field">{{ 'Accounts.type-of-payment' | translate
                                }}</label>
                            <p-dropdown [options]="isSearchByClient == 2 ? typeofPaymentsSearchByGroup: typeofPayments"
                                formControlName="PaymentType" placeholder="{{ 'Accounts.payment-type' | translate }}"
                                (onChange)="changePaymentType(mycashReceiptsForm.get('PaymentType'))" id="paymentType"
                                [disabled]="(!isClientSelected || isPaymentFlowRequired) || transactionData"
                                [ngClass]="{'notAllowed': isFormDisble, '': !isFormDisble}" required></p-dropdown>
                        </div>
                        <div class="p-field p-col-12"
                            *ngIf="mycashReceiptsForm.get('PaymentType')['value'] === 'Check' && (!showCardoption || !showAchCardoption)">
                            <label class="label-text required-field">{{ 'Accounts.check-reference' | translate
                                }}</label>
                            <div (click)="handleClickCheck()">
                                <input type="text" pInputText autocomplete="nope" formControlName="CheckRef"
                                    (focus)="$event.target.select()" autocomplete="off" maxlength="20" [tabindex]="3"
                                    [disabled]="!isCheckRef" [ngClass]="{'p-disabled': !isCheckRef, '': isCheckRef}">
                            </div>
                        </div>
                        <!-- Stax button -->
                        <div class="p-field p-col-6"
                            *ngIf="isClientSelected && !isRetainerApplied && isSTaxEnabled && (mycashReceiptsForm.get('PaymentType').value === 'Credit Card' || mycashReceiptsForm.get('PaymentType').value === 'ACH')">
                            <button pButton pRipple
                                label="{{ 'process' | translate }} {{mycashReceiptsForm.get('PaymentType').value}}"
                                icon="fal fa-credit-card" class="p-button-sm" tooltipPosition="top"
                                pTooltip="{{ 'process' | translate }}" [disabled]="isPaymentFlowRequired"
                                (click)="showStaxdialog()"></button>
                        </div>
                        <div class="p-field p-col-8" *ngIf="showCardoption && !isCpaPay && !isSTaxEnabled">
                            <button pButton pRipple label="{{ 'process-credit-card' | translate }}"
                                icon="fal fa-credit-card" class="p-button-sm" tooltipPosition="top"
                                pTooltip="{{ 'process-credit-card' | translate }}" [disabled]="(!isFormValid)"
                                (click)="processData()"></button>
                        </div>
                        <div class="p-field p-col-6" *ngIf="showAchCardoption && !isCpaPay && !isSTaxEnabled">
                            <button pButton pRipple label="{{ 'process' | translate }} ACH" icon="fal fa-credit-card"
                                class="p-button-sm" tooltipPosition="top" pTooltip="{{ 'process' | translate }} ACH"
                                [disabled]="(! isFormValid)" (click)="processACHData()"></button>
                        </div>
                        <div class="p-field p-col-8" *ngIf="isCpaPay && !isSTaxEnabled">
                            <button pButton pRipple
                                label="{{ 'process' | translate }} {{mycashReceiptsForm.get('PaymentType').value}}"
                                icon="fal fa-credit-card" class="p-button-sm" tooltipPosition="top"
                                [disabled]="(!isFormValid)" (click)="processLexcharge()">
                            </button>
                        </div>
                    </div>
                    <div class="p-col-3">
                        <div class="p-field p-col-9">
                            <label class="label-text required-field">{{ 'amount' | translate }}</label>
                            <!-- <p-inputNumber formControlName="PaymentAmount" mode="currency" currency="USD" locale="en-US"
                                (click)="$event.target.select();" maxlength="20" autocomplete="off"
                                inputStyleClass="p-text-right" id="paymentAmount"
                                (onBlur)="changeUSMoney(mycashReceiptsForm.get('PaymentAmount'),mycashReceiptsForm.get('PaymentUnapplied'));autoApply()"
                                [ngClass]="{'notAllowed': isFormDisble, '': !isFormDisble}" required>
                            </p-inputNumber> -->

                            <input pInputText type="text" pKeyFilter="num" class="p-text-right" mode="currency"
                                currency="USD" locale="en-US" prefix="$" formControlName="PaymentAmount" tabindex="4"
                                (click)="$event.target.select();" maxlength="20" autocomplete="off"
                                inputStyleClass="p-text-right" id="paymentAmount"
                                (change)="changeUSMoney(mycashReceiptsForm.get('PaymentAmount'),mycashReceiptsForm.get('PaymentUnapplied'));"
                                [ngClass]="{'notAllowed': isFormDisble, '': !isFormDisble}" required />
                        </div>
                        <div class="p-field p-col-9 p-mb-0">
                            <label class="label-text required-field">{{ 'Accounts.unapplied-amount' | translate
                                }}</label>
                            <p-inputNumber formControlName="PaymentUnapplied" mode="currency" type="number"
                                inputStyleClass="p-text-right" currency="USD" locale="en-US" autocomplete="off"
                                disabled="true">
                            </p-inputNumber>
                        </div>
                        <div class="p-field p-col-9 ic-red p-mb-0 p-text-bold"
                            *ngIf="mycashReceiptsForm.get('PaymentUnapplied').value > 0">
                            Advance Payment
                        </div>
                    </div>
                    <div class="p-col-5">
                        <div class="p-field p-col-12">
                            <label for="address" class="label-text">{{ 'payment-memo-description' | translate }}</label>
                            <app-mentions [(inputText)]="mycashReceiptsForm.controls['PaymentNote'].value" (inputTextChange)="mycashReceiptsForm.controls['PaymentNote'].setValue($event);"
                                style="width:100% !important; resize: vertical;"></app-mentions>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-col-12" *ngIf="mycashReceiptsForm.get('PaymentType').value !=='Refund' && isClientSelected">
                <div class="card p-mx-2">
                    <h4 class="label-text">{{ 'Accounts.open-invoices' | translate }}</h4>
                    <p-table #dt [rowHover]="true" [value]="openInvoicesDataSource" [responsive]="true"
                        dataKey="InvoiceNumber" selectionMode="single" [showCurrentPageReport]="true" [pageLinks]="3"
                        sortField="InvoiceDate" [filterDelay]="0" [scrollHeight]="'calc(90vh - 500px)'"
                        [scrollable]="true" [globalFilterFields]="['InvoiceNumber','InvoiceDate','ClientName','DescriptionShort', 'InvoiceAmount']"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        [resizableColumns]="true">
                        <ng-template pTemplate="caption">
                            <div class="table-header">
                                <div class="p-grid">
                                    <div class="p-col-6" style="display: flex; align-items: center;">
                                        <div class="p-mr-5">
                                            <span class="p-input-icon-left">
                                                <i class="fal fa-search"></i>
                                                <input #searchValue pInputText type="text"
                                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                    placeholder="{{ 'globalsearch' | translate }}" />
                                            </span>
                                            <span>
                                                <button pButton pRipple type="button" icon="pi pi-times"
                                                    class="p-button-rounded p-button-danger p-button-text" (click)="dt.reset();"
                                                    tooltipPosition="top" pTooltip="{{ 'clear' | translate }}"></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-5p p-text-center" pSortableColumn="InvoiceNumber">{{
                                    'Accounts.invoice-number' | translate }}
                                    <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-center" pSortableColumn="InvoiceDate">{{
                                    'Accounts.invoice-date' | translate }}
                                    <p-sortIcon field="InvoiceDate"></p-sortIcon>
                                </th>
                                <th *ngIf="isSearchByClient == 2" class="width-15p p-text-left">{{ 'client.name' |
                                    translate }}</th>
                                <th *ngIf="isSearchByClient != 2" class="width-25p p-text-left">{{
                                    'Accounts.invoice-memo' | translate }}</th>
                                <th *ngIf="isSearchByClient == 2" class="width-8p p-text-center">{{
                                    'Accounts.invoice-memo' | translate }}</th>
                                <th class="width-11p p-text-right"> {{ 'Accounts.invoice-amount' | translate }}</th>
                                <th class="width-10p p-text-right">{{ 'total-payments' | translate }}</th>
                                <th class="width-10p p-text-right">{{ 'Accounts.invoice-balance' | translate }}</th>
                                <th class="width-10p p-text-right">{{ 'payments-applied' | translate }}</th>
                                <th class="width-5p p-text-center">{{ 'Accounts.apply' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr [pEditableRow]="rowData">
                                <td class="width-5p p-text-center"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    {{rowData.InvoiceNumber}}
                                </td>
                                <td class="width-10p p-text-center"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    {{ mangoUtils.formatDateString(rowData.InvoiceDate) | date:'MM/dd/yyyy'}}
                                </td>
                                <td *ngIf="isSearchByClient == 2" class="width-15p p-text-left"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    {{rowData.ClientName}}
                                </td>
                                <td *ngIf="isSearchByClient != 2" class="width-25p p-text-left"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''"
                                    pTooltip="{{rowData.DescriptionShort}}" tooltipPosition="top" showDelay="800">
                                    {{rowData.DescriptionShort}}
                                </td>
                                <td *ngIf="isSearchByClient == 2" class="width-8p p-text-center">
                                    <i pTooltip="{{rowData.DescriptionShort}}" class="fal fa-file-invoice"
                                        tooltipStyleClass="tooltip-width-450" tooltipPosition="top"></i>
                                </td>
                                <td class="width-10p p-text-right" [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    {{ (rowData.InvoiceAmount + (rowData.TotalTax ? rowData.TotalTax : 0) + (rowData.TotalTaxExpenses ?
                                    rowData.TotalTaxExpenses : 0) ) | currency : 'USD' : true : '1.2-2'}}
                                </td>
                                <td class="width-10p p-text-right"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    {{rowData.TotalPayments | currency : 'USD' : true : '1.2-2'}}
                                </td>
                                <td class="width-10p p-text-right"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    {{(rowData.InvoiceBalance) | currency : 'USD' : true : '1.2-2' }}
                                </td>
                                <td pEditableColumn class="width-10p p-text-right"
                                    [ngClass]="(rowData.InvoiceType === 'Retainer Invoice')?'ic-green':''">
                                    <p-cellEditor id="engagement_column_{{rowData['uuid']}}">
                                        <ng-template pTemplate="input">
                                            <div>
                                                <p-inputNumber [(ngModel)]="rowData.PaymentsApplied" mode="currency"
                                                    currency="USD" locale="en-US" type="number"
                                                    (keydown.enter)="paymentsAppliedOnChange(rowData)" [step]="0"
                                                    (onBlur)="paymentsAppliedOnChange(rowData)" placeholder="0.00"
                                                    maxlength="10" [ngModelOptions]="{standalone: true}"
                                                    (onFocus)="$event.target.select(); rowData.isEditing = true;"
                                                    autocomplete="off" inputStyleClass="p-text-right input-number-width"
                                                    [max]="mycashReceiptsForm.controls['PaymentUnapplied'].value"
                                                    required>
                                                </p-inputNumber>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>{{(rowData.PaymentsApplied) | currency : 'USD' : true : '1.2-2' }}
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="width-5p p-text-center">
                                    <p-checkbox (click)="calculateData(rowData)" binary="true"
                                        [ngClass]="{'wrapper notAllowed': rowData.isEditing}"
                                        [(ngModel)]="rowData['selected']" [ngModelOptions]="{standalone: true}">
                                    </p-checkbox>
                                </td>
                            </tr>
                        </ng-template>
                        <!-- Footer -->
                        <ng-template pTemplate="footer">
                            <tr *ngIf="openInvoicesDataSource.length > 1">
                                <td></td><td></td>
                                <td class="width-40p p-text-right ic-red">{{ 'Totals' | translate }}</td>
                                <td class="width-10p p-text-right ic-red">
                                    <strong>${{totalNumberAmt}}</strong>
                                </td>
                                <td class="width-10p p-text-right ic-red">
                                    <strong>${{totalNumberPrev}}</strong>
                                </td>
                                <td class="width-10p p-text-right ic-red">
                                    <strong>${{totalNumberBalance}}</strong>
                                </td>
                                <td class="width-10p p-text-right ic-red">
                                    <strong>${{totalNumberPayments}}</strong>
                                </td>
                                <td class="width-5p">
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!--  end of card -->
                </div>
            </div>

            <div class="p-col-12 p-pt-0"
                *ngIf="isClientSelected && openRetainersDataSource.length > 0 && isSearchByClient != 2">
                <div class="card p-mx-2">
                    <h4 class="label-text">{{ 'Accounts.open-retainers' | translate }}
                        <div class="pull-right">
                            <a href="JavaScript:void(0);" (click)="clearSelection()" class="ic-size-15 ic-red">{{
                                'clear' | translate }}</a>
                            <span class="p-mx-2 ic-size-15">/</span>
                            <a href="JavaScript:void(0);" (click)="showHideTable();"
                                class="ic-size-15 ic-red">{{showText}}</a>
                        </div>
                    </h4>
                    <p-table *ngIf="showTable" #dt [rowHover]="true" [value]="openRetainersDataSource"
                        [responsive]="true" dataKey="BillingHeaderID" selectionMode="single"
                        [showCurrentPageReport]="true" [pageLinks]="3" [filterDelay]="0"
                        [scrollHeight]="'calc(90vh - 500px)'" [scrollable]="true"
                        styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                        sortField="PaymentDate" [resizableColumns]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="width-11p p-text-center" pSortableColumn="PaymentDate">{{
                                    'Accounts.payment-date' | translate }}
                                    <p-sortIcon field="PaymentDate"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-center" pSortableColumn="PaymentType">{{
                                    'Accounts.payment-type' | translate }}
                                    <p-sortIcon field="PaymentType"></p-sortIcon>
                                </th>
                                <th class="width-25p p-text-center" pSortableColumn="CheckRef">{{
                                    'Accounts.check-reference' | translate }}
                                    <p-sortIcon field="CheckRef"></p-sortIcon>
                                </th>
                                <th class="width-10p p-text-right">{{ 'Accounts.payment-amount' | translate }}</th>
                                <th class="width-10p p-text-right">{{ 'Accounts.unapplied-amount' | translate }}</th>
                                <th class="width-10p p-text-center">{{ 'Accounts.apply' | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td class="width-11p p-text-center">
                                    {{mangoUtils.formatDateString(rowData.PaymentDate) | date:'MM/dd/yyyy'}}
                                </td>
                                <td class="width-10p p-text-center">
                                    {{rowData.PaymentType}}
                                </td>
                                <td class="width-25p p-text-left">
                                    {{rowData.CheckRef}}
                                </td>
                                <td class="width-10p p-text-right">
                                    ${{rowData.PaymentAmount}}
                                </td>
                                <td class="width-10p p-text-right">
                                    ${{rowData.PaymentUnapplied}}
                                </td>
                                <td class="width-10p p-text-center">
                                    <p-radioButton name="groupname" value="{{rowData['PaymentHeaderID']}}"
                                        [(ngModel)]="selectedValue" (click)="showRowData(rowData)"
                                        [ngModelOptions]="{standalone: true}"></p-radioButton>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr *ngIf="openRetainersDataSource.length > 1">
                                <td class="width-46p p-text-right ic-red">{{ 'Totals' | translate }}</td>
                                <td class="width-10p p-text-right ic-red">
                                    <strong>${{totalNumberPaymentamt}}</strong>
                                </td>
                                <td class="width-10p p-text-right ic-red">
                                    <strong>${{totalNumberPaymentUnapplied}}</strong>
                                </td>
                                <td class="width-10p">
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">
                                    <p-message severity="info" text="{{ 'data_not_found' | translate }}."
                                        styleClass="p-px-2"></p-message>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <!--  end of card -->
                </div>
            </div>
        </form>
    </div>
</div>
<p-dialog [(visible)]="combinedVisible" [modal]="true" [style]="{width: dialogWidth}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    (onHide)="closeCreditCardForm();">
    <p-header>
        <div>
            {{ 'billing-invoicing.credit-card-information' | translate }}
            <img class="img-responsive card-icons" src="assets/img/cards.png">
        </div>
    </p-header>
    <div class="p-m-1" *ngIf="isNewCpaPay">
        <iframe [attr.src]=" newcardurl ? newcardurl : null" frameBorder="0" style="width: 100%; height: 700px"
            (load)="onLoad()"></iframe>
    </div>

    <div class="p-fluid p-formgrid p-grid p-m-1">
        <!-- Card Form -->
        <div class="p-col-12" *ngIf="!isCpaPay && !isNewCpaPay && (!paymentProfile || flagAddNewCard)">
            <form role="form" [formGroup]="myPaymentForm" autocomplete="off" novalidate>
                <div class="p-fluid">
                    <div class="p-formgroup-inline">
                        <!-- Card type -->
                        <div class="p-field p-col-6 p-pl-0">
                            <label for="cardTypes" class="required-field label-text">{{ 'Card-Type' | translate
                                }}</label>
                            <p-dropdown [options]="cardTypes" formControlName="cardType" (onChange)="cardTypeChange()">
                            </p-dropdown>
                        </div>

                        <!-- Amount -->
                        <div class="p-field p-col-6 p-pr-0">
                            <label for="cardNumber" class="required-field label-text">{{ 'billing.Amount-to-Charge' |
                                translate }}</label>
                            <input type="text" pInputText formControlName="amount" readonly="true" />
                        </div>
                    </div>

                    <!-- Company Name -->
                    <div class="p-field">
                        <label for="cupncode" class="label-text">{{ 'company' | translate }}</label>
                        <input type="text" pInputText formControlName="company" readonly="true" />
                    </div>

                    <!-- Card Holder Name -->
                    <div class="p-field">
                        <label for="cardNumber" class="required-field label-text">{{ 'billing-invoicing.name-on-card' |
                            translate }}</label>
                        <div>
                            <input type="text" pInputText formControlName="nameOnCard" />
                        </div>
                    </div>

                    <!-- Card Number -->
                    <div class="p-field">
                        <label for="cardNumber" class="required-field label-text">{{ 'billing-invoicing.card-number' |
                            translate }}</label>
                        <p-inputMask *ngIf="isVisaCard==='true'" mask="9999-9999-9999-9999" formControlName="cardno"
                            autofocus></p-inputMask>
                        <p-inputMask *ngIf="isVisaCard==='false'" mask="9999-999999-99999" formControlName="cardno"
                            autofocus></p-inputMask>
                    </div>

                    <!-- EXP Date CVV -->
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-7 p-pl-0">
                            <label for="cardExpiry" class="required-field label-text"><span>{{
                                    'billing-invoicing.expiration-date' | translate }}</span></label>
                            <p-inputMask mask="99/9999" formControlName="expDate" autofocus>
                            </p-inputMask>
                        </div>
                        <div class="p-field p-col-5 p-pr-0">
                            <label for="cardCVC" class="label-text">{{ 'cv-code' | translate }}</label>
                            <p-inputMask mask="999?9" formControlName="securityCode" autofocus></p-inputMask>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- Card Details -->
        <div class="p-col-12" *ngIf="!!paymentProfile && !flagAddNewCard && !isNewCpaPay">
            <div class="card">
                <h5>Please review the card details. You can also add the new card.</h5>
                <h5>Card Details</h5>

                <div class="p-grid p-field">
                    <div class="p-col-6 label-text p-text-bold">{{
                        'billing.Amount-to-Charge' | translate }}</div>
                    <div class="p-col-6">: {{amountToCharge | currency:'USD':true:'1.2-2' }}</div>
                </div>
                <div class="p-grid p-field">
                    <div class="p-col-6 label-text p-text-bold">{{
                        'billing-invoicing.name-on-card' | translate }}</div>
                    <div class="p-col-6">: {{paymentProfile.NameOnCard}}</div>
                </div>
                <div class="p-grid p-field">
                    <div class="p-col-6 label-text p-text-bold">{{
                        'last-4digits-crcard' | translate }}</div>
                    <div class="p-col-6">: {{paymentProfile.CardNo}}</div>
                </div>
                <div class="p-grid p-field" *ngIf="!isCpaPay">
                    <div class="p-col-6 label-text p-text-bold">{{'Expiry-date' | translate}}</div>
                    <div class="p-col-6 ic-size-16">: {{paymentProfile.ExpiryDate}}</div>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <!-- Buttons -->
        <div class="p-grid" *ngIf="!isNewCpaPay">
            <div class="p-col-12">
                <button pButton pRipple *ngIf="paymentProfile && !flagAddNewCard"
                    [disabled]="(!isFormValid || !isDialogFormValid)"
                    (click)="singularProcessPayment(paymentProfile,false);"
                    label="{{ 'billing-invoicing.process-payment' | translate }}"
                    class="p-button-sm p-pr-3 p-mr-2"></button>
                <button pButton pRipple *ngIf="!isCpaPay && (!paymentProfile || flagAddNewCard)"
                    [disabled]="(!isFormValid || !isDialogFormValid)" (click)="singularCCTokenize();"
                    label="{{ 'billing-invoicing.authorize-card' | translate }}" class="p-button-sm p-pr-3 p-mr-2">
                </button>
                <button pButton pRipple *ngIf="!!paymentProfile && !flagAddNewCard" (click)="addNewCard()"
                    label="{{ 'Add-New-Card' | translate }}" class="p-button-sm p-pr-3 p-mr-2">
                </button>
                <button pButton pRipple (click)="dialogDisplay=false;closeCreditCardForm()"
                    class="p-button-sm p-pr-3 p-mr-2" label="{{ 'cancel_button' | translate }}">
                </button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="ACH {{ 'Information' | translate }}" [(visible)]="showACHDialog" [modal]="true"
    [style]="{width: dialogWidth}" [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" (onHide)="closeCreditCardForm();">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <div class="p-col-12">
            <div class="p-m-1" *ngIf="isNewCpaPay">
                <iframe [attr.src]=" newcardurl ? newcardurl : null" frameBorder="0" style="width: 100%; height: 700px"
                    (load)="onLoad()"></iframe>
            </div>
            <div *ngIf="showAchNewCardoption && !isCpaPay && !isNewCpaPay" class="card">
                <form role="form" novalidate autocomplete="off" [formGroup]="myAchReceiptsForm">
                    <!-- Card type -->
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pl-0">
                            <label for="amount" class="required-field label-text">{{ 'billing.Amount-to-Charge' |
                                translate }}</label>
                            <input type="text" pInputText formControlName="Amount" readonly />
                        </div>
                        <div class="p-field p-col-6 p-pr-0">
                            <label for="acdebit" class="required-field label-text">{{'billing.Account-to-Debit' |
                                translate}}</label>
                            <p-dropdown [options]="transCodeTypes" formControlName="TransCode">
                            </p-dropdown>
                        </div>
                    </div>

                    <div class="p-field">
                        <label for="acNumber" class="required-field label-text">{{ 'user.Name' | translate }}</label>
                        <input type="text" #firstNameInputFocus pInputText formControlName="FirstName" />
                    </div>

                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pl-0">
                            <label for="cardNumber" class="required-field label-text">{{ 'RoutingNumber' | translate
                                }}</label>
                            <input type="text" pInputText formControlName="RoutingNumber" />
                        </div>
                        <div class="p-field p-col-6 p-pr-0">
                            <label for="acNumber" class="required-field label-text">{{ 'Account-Number' | translate
                                }}</label>
                            <input type="text" pInputText formControlName="AccountNumber" />
                        </div>
                    </div>
                </form>
            </div>

            <div *ngIf="!showAchNewCardoption && achProfile !=null && !isNewCpaPay" class="card">
                <h5>{{ 'billing.Bank-Account-Details' | translate }}</h5>

                <div class="p-grid p-field">
                    <div class="p-col-6 label-text p-text-bold">{{ 'billing.Amount-to-Charge' | translate }}</div>
                    <div class="p-col-6">: {{amountToCharge | currency:'USD':true:'1.2-2' }}</div>
                </div>
                <div class="p-grid p-field">
                    <div class="p-col-6 label-text p-text-bold">{{ 'user.Name' | translate }}</div>
                    <div class="p-col-6">: {{achProfile.NameOnCard}}</div>
                </div>
                <div class="p-grid p-field">
                    <div class="p-col-6 label-text p-text-bold">{{'last-4digits-bank' | translate}}</div>
                    <div class="p-col-6">: {{achProfile.BankAcctNo}}</div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <!-- Buttons -->
        <div class="p-grid" *ngIf="!isNewCpaPay">
            <div class="p-col-12">
                <button pButton pRipple class="p-button-sm p-pr-3 p-mr-2" (click)="processAchCard()"
                    label="{{ 'process' | translate }}">
                </button>
                <button pButton pRipple class="p-button-sm p-pr-3 p-mr-2"
                    (click)="showAchNewCardoption = true;addNewCard()" label="{{ 'add-new-bank' | translate }}">
                </button>
                <button pButton pRipple class="p-button-sm p-pr-3 p-mr-2"
                    (click)="showACHDialog=false;closeCreditCardForm()" label="{{ 'cancel_button' | translate }}">
                </button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-dialog header="{{dialogTitle}}" [(visible)]="isLexcharge" [modal]="true" [style]="{width: '60vw'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
    <div class="p-fluid p-formgrid p-grid p-m-1">
        <iframe [attr.src]="url ? url : null" frameBorder="0" style="width: 100%; height: 700px"
            (load)="onLoad()"></iframe>
    </div>
</p-dialog>

<!-- STax Dialog -->

<p-dialog [(visible)]="showStaxoption" [modal]="true" [style]="{width: dialogWidth}" [maximizable]="false"
    [position]="isStaxNewCard ? 'top' : 'center'" showEffect="fade" [baseZIndex]="10000" [draggable]="true"
    [resizable]="false" [closable]="false">
    <p-header>
        <div>
            <span *ngIf="isCCFlow">
                <span *ngIf="!isDebitCard">
                    {{ 'billing-invoicing.credit-card-information' | translate }}
                </span>
                <span *ngIf="isDebitCard">
                    {{ 'billing-invoicing.debit-card-information' | translate }}
                </span>
            </span>
            <span *ngIf="!isCCFlow"><span><i class="fal fa-university p-pr-3" style="opacity:.7"></i></span> ACH {{
                'Information' | translate }}</span>
            <img *ngIf="isCCFlow" class="img-responsive card-icons pull-right" style="position: absolute;right: 5px;"
                src="assets/img/cards.png">
        </div>
    </p-header>
    <!-- Credit Card Flow -->
    <div class="p-fluid p-formgrid p-m-1" *ngIf="isCCFlow">
        <form role="form" [formGroup]="myPaymentForm" autocomplete="off" novalidate>
            <div class="p-col-12" *ngIf="isStaxNewCard">

                <div class="p-fluid">
                    <div class="p-formgroup-inline">
                        <!-- Amount -->
                        <div class="p-field p-col-12 p-px-0">
                            <label for="cardNumber" class="required-field label-text">{{ 'billing.Amount-to-Charge' |
                                translate }}</label>
                            <input type="text" pInputText formControlName="amount" readonly="true" />
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pl-0">
                            <label for="cardNumber" class="required-field label-text">{{ 'fname' |
                                translate }}</label>
                            <input type="text" pInputText formControlName="fname" />
                        </div>
                        <div class="p-field p-col-6 p-pr-0">
                            <label for="cardNumber" class="required-field label-text">{{ 'lname' |
                                translate }}</label>
                            <input type="text" pInputText formControlName="lname" />
                        </div>
                    </div>

                    <!--address -->
                    <div class="p-field">
                        <label for="cardNumber" class="required-field label-text">{{ 'user.user_address' |
                            translate }}</label> <span class="p-text-italic p-ml-2 ic-red"
                            style="font-size: 11px">(Address must match billing
                            address on Credit Card)</span>
                        <input type="text" pInputText formControlName="address" />
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-4 p-pl-0">
                            <label for="cardExpiry" class="required-field label-text"><span>{{
                                    'client.city' | translate }}</span></label>
                            <input type="text" formControlName="city" pInputText autocomplete="nope" maxlength="50">
                        </div>
                        <div class="p-field p-col-4 p-pr-0">
                            <label for="cardExpiry" class="required-field label-text"><span>{{
                                    'client.state' | translate }}</span></label>
                            <input type="text" formControlName="state" pInputText autocomplete="nope" maxlength="50">
                        </div>
                        <div class="p-field p-col-4 p-pr-0">
                            <label class="label-text required-field">{{ 'client.zip' | translate }}</label>
                            <input type="text" pInputText class="p-text-right" formControlName="zip" maxlength="10"
                                (blur)="focusCard(0,'card_number')" autocomplete="nope">
                        </div>
                    </div>
                    <!-- Card Number -->
                    <div class="p-field">
                        <label for="cardNumber" class="required-field label-text">{{ 'billing-invoicing.card-number' |
                            translate }}</label>

                        <div id="fattjs-number" style="height:35px;">
                        </div>

                    </div>

                    <!-- EXP Date CVV -->
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pl-0">
                            <label for="cardExpiry" class="required-field label-text"><span>{{
                                    'billing-invoicing.expiration-date' | translate }}</span></label>
                            <p-inputMask mask="99/9999" formControlName="expDate" autofocus
                                (onBlur)="focusCard(1,'cvv')">
                            </p-inputMask>
                        </div>
                        <div class="p-field p-col-6 p-pr-0">
                            <label for="cardCVC" class="label-text">{{ 'cv-code' | translate }}</label>
                            <div id="fattjs-cvv" style="height:35px;">
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="p-col-12" *ngIf="sTaxProfile && !isStaxNewCard">
                <div class="p-fluid">
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pr-0 p-my-0" *ngIf="!mangoCompanyData.isSurchargeEnabled">
                            <label for="uname" class="label-text">{{ 'billing.Amount-to-Charge' | translate
                                }}</label>
                        </div>
                        <div class="p-field p-col-6 p-pr-0 p-my-0" *ngIf="!mangoCompanyData.isSurchargeEnabled">
                            <span class="p-text-bold">{{amountToCharge | currency:'USD':true:'1.2-2' }}</span>
                        </div>
                        <!-- Surcharge -->
                        <div class="p-field p-col-12"
                            *ngIf="mangoCompanyData.isSurchargeEnabled && myPaymentForm.get('SurChargeAmount').value == 0">
                            <div class="surcharge-msg p-pt-2">
                                <p-message severity="info" text="{{'surcharge-msg' |
                            translate}}"></p-message>
                            </div>
                        </div>
                        <div class="p-field p-col-4" *ngIf="mangoCompanyData.isSurchargeEnabled">
                            <label for="activity" class="label-text">{{'Accounts.payment-amount' |
                                translate}}</label>
                            <div class="p-col-6">{{amountToCharge | currency:'USD':true:'1.2-2' }}</div>
                        </div>
                        <div class="p-field p-col-4"
                            *ngIf="mangoCompanyData.isSurchargeEnabled && myPaymentForm.get('SurChargeAmount').value > 0">
                            <label for="activity" class="label-text">{{'surcharge' |
                                translate}}</label>
                            <p-inputNumber disabled="true" inputStyleClass="width-120px"
                                formControlName="SurChargeAmount" mode="currency" currency="USD" locale="en-US"
                                [maxFractionDigits]="2">
                            </p-inputNumber>
                        </div>

                        <div class="p-field p-col-4"
                            *ngIf="mangoCompanyData.isSurchargeEnabled && myPaymentForm.get('SurChargeAmount').value > 0">
                            <label for="activity" class="label-text">{{'total-amount' |
                                translate}}</label>
                            <p-inputNumber disabled="true" inputStyleClass="width-120px" formControlName="withSurcharge"
                                mode="currency" currency="USD" locale="en-US" [maxFractionDigits]="2">
                            </p-inputNumber>
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pr-0 p-my-0">
                            <label for="uname" class="label-text">{{ 'billing-invoicing.name-on-card' | translate
                                }}</label>
                        </div>
                        <div class="p-field p-col-6 p-pr-0 p-my-0">
                            <span class="p-text-bold"> {{sTaxProfile.NameOnCard}}</span>
                        </div>
                    </div>
                    <div class="p-formgroup-inline">
                        <div class="p-field p-col-6 p-pr-0 p-my-0">
                            <span *ngIf="!isDebitCard">
                                <label for="uname" class="label-text">{{ 'last-4digits-crcard' | translate }}</label>
                            </span>
                            <span *ngIf="isDebitCard">
                                <label for="uname" class="label-text">{{ 'last-4digits-dbcard' | translate }}</label>
                            </span>
                        </div>
                        <div class="p-field p-col-6 p-pr-0 p-my-0 p-pt-1">
                            <span class="p-text-bold p-mr-3"> ****{{sTaxProfile.CardNo}}</span>
                            <span *ngIf="sTaxProfile['CardType'] === 'visa'" tooltipPosition="top" title="Visa"
                                showDelay="800" tooltipStyleClass="tooltip-no-wrap" class="crcardimg">
                                <img src="assets/mango/svg/visa.svg">
                            </span>
                            <span *ngIf="sTaxProfile['CardType'] === 'mastercard'" tooltipPosition="top"
                                title="Mastercard" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                class="crcardimg">
                                <img src="assets/mango/svg/mastercard.svg">
                            </span>
                            <span *ngIf="sTaxProfile['CardType'] === 'discover'" tooltipPosition="top" title="Discover"
                                showDelay="800" tooltipStyleClass="tooltip-no-wrap" class="crcardimg">
                                <img src="assets/mango/svg/discover.svg">
                            </span>
                            <span *ngIf="sTaxProfile['CardType'] === 'amex'" tooltipPosition="top" title="Amex"
                                showDelay="800" tooltipStyleClass="tooltip-no-wrap" class="crcardimg">
                                <img src="assets/mango/svg/amex.svg">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- Ach Flow -->
    <div class="p-fluid p-formgrid p-grid p-m-1" *ngIf="!isCCFlow">
        <div class="p-col-12" *ngIf="isStaxNewCard">
            <form role="form" novalidate autocomplete="off" [formGroup]="myAchReceiptsForm">
                <!-- Card type -->
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6 p-pl-0">
                        <label for="amount" class="required-field label-text">{{ 'billing.Amount-to-Charge' |
                            translate }}</label>
                        <input type="text" pInputText formControlName="Amount" readonly />
                    </div>
                    <div class="p-field p-col-6 p-pr-0">
                        <label for="acdebit" class="required-field label-text">{{'billing.Account-to-Debit' |
                            translate}}</label>
                        <p-dropdown [options]="transCodeTypes" formControlName="TransCode">
                        </p-dropdown>
                    </div>
                </div>

                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6 p-pl-0">
                        <label for="fname" class="required-field label-text">{{ 'fname' |
                            translate }}</label>
                        <input type="text" pInputText formControlName="FirstName" />
                    </div>
                    <div class="p-field p-col-6 p-pr-0">
                        <label for="lname" class="required-field label-text">{{ 'lname' |
                            translate }}</label>
                        <input type="text" pInputText formControlName="LastName" />
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6 p-pl-0">
                        <label for="cardNumber" class="required-field label-text">{{ 'RoutingNumber' | translate
                            }}</label>
                        <input type="text" pInputText formControlName="RoutingNumber" />
                    </div>
                    <div class="p-field p-col-6 p-pr-0">
                        <label for="acNumber" class="required-field label-text">{{ 'Account-Number' | translate
                            }}</label>
                        <input type="text" pInputText formControlName="AccountNumber" />
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6 p-pl-0">
                        <p-radioButton name="groupname" value="false" [(ngModel)]="isBusiness"
                            [ngModelOptions]="{standalone: true}" label="Personal" labelStyleClass="p-pr-2">
                        </p-radioButton>
                        <p-radioButton name="groupname" value="true" [(ngModel)]="isBusiness"
                            [ngModelOptions]="{standalone: true}" label="Business">
                        </p-radioButton>
                    </div>
                </div>


            </form>
        </div>
        <div class="p-col-12" *ngIf="!isStaxNewCard && sTaxProfile">
            <div class="p-field surcharge-msg"
                *ngIf="mangoCompanyData.isSurchargeEnabled && myPaymentForm.get('SurChargeAmount').value == 0">
                <p-message severity="info" text="{{'surcharge-msg' |
                translate}}"></p-message>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-6 label-text p-text-bold">{{ 'billing.Amount-to-Charge' | translate }}</div>
                <div class="p-col-6"> {{amountToCharge | currency:'USD':true:'1.2-2' }}</div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-6 label-text p-text-bold">{{ 'user.Name' | translate }}</div>
                <div class="p-col-6"> {{sTaxProfile.NameOnCard}}</div>
            </div>
            <div class="p-grid p-field">
                <div class="p-col-6 label-text p-text-bold">{{'last-4digits-bank' | translate}}</div>
                <div class="p-col-6"> ****{{sTaxProfile.BankAcctNo}}
                    <span *ngIf="sTaxProfile['CardType']">
                        <i class="far fa-money-check ic-green ic-size-16 p-pl-3" tooltipPosition="top"
                            pTooltip="{{sTaxProfile['CardType']}}" showDelay="800"
                            tooltipStyleClass="tooltip-no-wrap"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <!-- Buttons -->
        <div class="p-grid">
            <div class="p-col-12">
                <button pButton pRipple (click)="processSTaxUsingToken();"
                    label="{{ 'billing-invoicing.process-payment' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="!isStaxNewCard" [disabled]="isProcessing"></button>
                <button pButton pRipple (click)="addNewCreditCard()" label="{{ 'Add-New-Card' | translate }}"
                    class="p-button-sm p-pr-3 p-mr-2" *ngIf="!isStaxNewCard && isCCFlow">
                </button>
                <button pButton pRipple (click)="addNewBankCard()" label="{{ 'Add New Bank' | translate }}"
                    class="p-button-sm p-pr-3 p-mr-2" *ngIf="sTaxProfile && !isStaxNewCard && !isCCFlow">
                </button>

                <!-- <button pButton pRipple (click)="addSTaxNewCard()" [disabled]="isProcessing"
                    label="{{ 'billing-invoicing.authorize-card' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="isStaxNewCard && isCCFlow && !mangoCompanyData.isSurchargeEnabled">
                </button>

                <button pButton pRipple (click)="addSTaxNewCard()" [disabled]="isProcessing"
                    label="{{ 'authorize-bank' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="isStaxNewCard && !isCCFlow && !mangoCompanyData.isSurchargeEnabled">
                </button> -->

                <button pButton pRipple (click)="authorizeSTaxNewCard()" [disabled]="isProcessing"
                    label="{{ 'authorize-card' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="isStaxNewCard && isCCFlow">
                </button>

                <button pButton pRipple (click)="authorizeSTaxNewCard()" [disabled]="isProcessing"
                    label="{{ 'authorize-bank' | translate }}" class="p-button-sm p-pr-3 p-mr-2"
                    *ngIf="isStaxNewCard && !isCCFlow">
                </button>

                <button pButton pRipple (click)="showStaxoption=false;closeStaxForm()" class="p-button-sm p-pr-3 p-mr-2"
                    label="{{ 'cancel_button' | translate }}">
                </button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<app-debit-memo-dialog [amountUnapplied]="mycashReceiptsForm.get('PaymentUnapplied').value"
    [showDebitMemoDialog]="showDebitMemoDialog" (onCloseDebitMemoDialog)="onCloseDebitMemoDialog($event)"
    (onSaveDebitMemoDialog)="onSaveDebitMemoDialog($event)"></app-debit-memo-dialog>
