import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppRegisterComponent } from './pages/register/register.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AppConstants } from '../app/_helpers/api-constants';
import { AppPaymentComponent } from './pages/app-payment/app-payment.component';
import { FilesandfoldersComponent } from './pages/filesandfolders/filesandfolders.component';
import { HostedPageComponent } from './pages/hosted-page/hosted-page.component';

const routes: Routes = [
   // { path: '', component: AppLoginComponent },
   {
    path: AppConstants.capacityPlanningRoutePath,
    data: {preload: true},
    loadChildren: () => import('./modules/capacity-planning/capacity-planning.module')
        .then(mod => mod.CapacityPlanningModule)
    }, 
    {
        path: AppConstants.capacityPlanningRoutePath,
        data: {preload: true},
        loadChildren: () => import('./modules/capacity-planning/capacity-planning.module')
            .then(mod => mod.CapacityPlanningModule)
    },
    {
        path: AppConstants.accountingRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/accounting/accounting.module')
            .then(mod => mod.AccountingModule)
    },
    {
        path: AppConstants.billinginvoicingRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/billing-invoicing/billing-invoicing.module')
            .then(mod => mod.BillingInvoicingModule)
    },
    {
        path: AppConstants.clientRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/client/client.module')
            .then(mod => mod.ClientModule)
    },
    {
        path: AppConstants.dashBoardRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/dashboard/dashboard.module')
            .then(mod => mod.DashboardModule)
    },
    {
        path: AppConstants.dmsRoutePath,
        data: { preload: true, roles: ["manager"], plans: ["enterprise"] },
        loadChildren: () => import('./modules/document-management/document-management.module')
            .then(mod => mod.DocumentManagementModule)
    },
    {
        path: AppConstants.engagementsRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/engagements/engagements.module')
            .then(mod => mod.EngagementsModule)
    },
    {
        path: `${AppConstants.engagementsRoutePath}/${AppConstants.lettersRoutePath}`,
        data: { preload: true },
        loadChildren: () => import('./modules/engagement-letters/engagement-letters.module')
            .then(mod => mod.EngagementLettersModule)
    },
    {
        path: AppConstants.projectManagementRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/project-management/project-management.module')
            .then(mod => mod.ProjectManagementModule)
    },
    {
        path: AppConstants.reportsRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/reports/reports.module')
            .then(mod => mod.ReportsModule)
    },
    {
        path: AppConstants.schedulingRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/scheduling/scheduling.module')
            .then(mod => mod.SchedulingModule)
    },
    {
        path: AppConstants.timeExpenseRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/time-expense/time-expense.module')
            .then(mod => mod.TimeExpenseModule)
    },
    {
        path: AppConstants.users,
        data: { preload: true },
        loadChildren: () => import('./modules/users/users.module')
            .then(mod => mod.UsersModule)
    },
    {
        path: AppConstants.setUp,
        data: { preload: true },
        loadChildren: () => import('./modules/setup-wizard/setup-wizard.module')
            .then(mod => mod.SetupWizardModule)
    },
    {
        path: AppConstants.settingsRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/settings/settings.module')
            .then(mod => mod.SettingsModule)
    },
    {
        path: AppConstants.superAdmin,
        data: { preload: true },
        loadChildren: () => import('./modules/admin/admin.module')
            .then(mod => mod.AdminModule)
    },
    {
        path: AppConstants.companySettings,
        data: { preload: true },
        loadChildren: () => import('./modules/company/company.module')
            .then(mod => mod.CompanyModule)
    },
    //:@deprecate-stripe;
    /*;
    {
        path: AppConstants.subscriptionSetupRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/subscription/subscription.module')
            .then(mod => mod.SubscriptionModule)
    },
    */
    {
        path: AppConstants.importExportRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/import-export/import-export.module')
            .then(mod => mod.ImportExportModule)
    },
    {
        path: AppConstants.taxImports,
        data: { preload: true },
        loadChildren: () => import('./modules/tax-imports/tax-imports-routing.module')
            .then(mod => mod.TaxImportsRoutingModule)
    },
    {
        path: AppConstants.transactionsRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/transaction/transaction.module')
            .then(mod => mod.TransactionModule)
    },
    {
        path: AppConstants.engagementMattersRoutePath,
        data: { preload: true },
        loadChildren: () => import('./modules/engagement-matters/engagement-matters.module')
            .then(mod => mod.EngagementMattersModule)
    },
    { path: AppConstants.errorRoutePath, component: AppErrorComponent },
    { path: AppConstants.accessdeniedRoutePath, component: AppAccessdeniedComponent },
    { path: AppConstants.notfoundRoutePath, component: AppNotfoundComponent },
    { path: AppConstants.loginRoutePath, component: AppLoginComponent },
    { path: AppConstants.paymentsRoutePath + '/activate/:token', component: AppPaymentComponent,data: { preload: true }},
    { path: AppConstants.paymentsRoutePath + `/hosted-page/:token`, component: HostedPageComponent, data: { preload: true } },
    { path: AppConstants.dmsRoutePath + `/${AppConstants.filesAndfolders}/:token`, component: FilesandfoldersComponent,data: { preload: true }},
    { path: AppConstants.registerRoutePath, component: AppRegisterComponent },
    { path: AppConstants.passwordResetRoutePath, component: ResetPasswordComponent },
    {
        path: '',
        redirectTo: `/${AppConstants.loginRoutePath}`,
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: AppConstants.loginRoutePath
    },
];



@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    declarations: []
})
export class AppRoutingModule {
}
