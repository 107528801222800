import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppSharedModule } from '@shared-modules/app-shared.module';
import { CommonModule } from '@angular/common';

import { TaxImportsRoutingModule } from './tax-imports-routing.module';
import { TaxImportsComponents } from './tax-imports.component';


@NgModule({
  declarations: [TaxImportsComponents],
  imports: [
    CommonModule,
    AppSharedModule,
    TaxImportsRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TaxImportsModule { }
