<div class="time-entry-list">
    <div class="layout-content p-pt-0">
        <div class="p-mx-1">
            <div class="p-col-12">
                <div class="card p-pb-1">
                    <div class="p-formgroup-inline">
                        <div class="p-field">
                            <label for="View" class="p-mr-3 label-text">{{'view' | translate}}</label>
                            <p-dropdown [options]="slipOptions" (onChange)="selectSlips($event.label, $event.value)"
                                [(ngModel)]="slipID" [style]="{'width':'160px'}"></p-dropdown>
                        </div>
                        <div class="p-field p-mx-3">
                            <label for="For" class="p-mr-3 label-text">{{'For' | translate}}</label>
                            <p-dropdown [options]="staffListOptions" (onChange)="selectStaff($event)"
                                [style]="{'width':'260px'}" [(ngModel)]="staffID"></p-dropdown>
                        </div>
                        <div class="p-field p-mx-3">
                            <label for="For" class="p-mr-3 label-text">{{'time.for-the-week-of' | translate}}</label>
                            <p-calendar name="Ddate" [monthNavigator]="true" [yearNavigator]="true"
                                yearRange="2000:2030" showButtonBar="true" [(ngModel)]="selectedWeekDate"
                                (onSelect)="changeSelectedWeekDate($event)"></p-calendar>
                        </div>
                        <div class="p-field p-mx-3">
                            <span class="p-buttonset">
                                <button pButton icon="fal fa-angle-left" (click)="previous()"></button>
                                <button pButton label="{{'Today' | translate}}" (click)="today()"></button>
                                <button pButton icon="fal fa-angle-right" (click)="next()"></button>
                            </span>
                        </div>
                        <div class="p-field p-mx-3">
                            <button pButton pRipple name="syncIcon" pTooltip="Refresh" tooltipPosition="top"
                                (click)="refresh()" type="button" icon="fal fa-sync"
                                class="p-button-rounded p-mr-3"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-pt-0">
                <div class="card client-list" *ngIf="dateList.length > 0 && counterList.length > 0"
                    [ngStyle]="{'padding-bottom': 'inherit'}">
                    <p-tabView (onChange)="selectDate($event.index)">
                        <p-tabPanel *ngFor="let tdate of dateList; let i = index" [selected]="i == selectedDateIndex">
                            <ng-template pTemplate="header">
                                <span>{{tdate | date: 'EEE MM/dd'}}</span>
                                <span class="b-badge-{{i}} add-badge-{{counterList[i]}} badge-class-danger"></span>
                            </ng-template>
                            <div *ngTemplateOutlet="timesheetTable">
                            </div>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span>All</span>
                                <span class="b-badge-all add-badge-{{timeSheetList.length}} badge-class-danger"></span>
                            </ng-template>
                            <div *ngTemplateOutlet="timesheetTable">
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                    <!-- [scrollHeight]="'calc(100vh - 80px)'" -->
                    <ng-template #timesheetTable>
                        <p-table #dt [value]="tsList" [rowHover]="true" [responsive]="true" dataKey="SlipMasterID"
                            selectionMode="single" [filterDelay]="0" [columns]="selectedColumns"
                            styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                            sortField="SlipMasterID" [globalFilterFields]="globalFilterFields"
                            [(selection)]="selectedTsList" (onFilter)="onFilter($event)"
                            [scrollHeight]="'calc(100vh - 450px)'" [scrollable]="true"
                            (onRowUnselect)="uncheckRow($event)" (onRowSelect)="checkRow($event)" [sortOrder]="-1"
                            (onEditInit)="onEditInit($event)" (onEditComplete)="onEditComplete()" [pageLinks]="3"
                            [rows]="10" (onEditCancel)="onEditCancel()">
                            <ng-template pTemplate="caption">
                                <div class="table-header">
                                    <span class="p-input-icon-left">
                                        <i class="fal fa-search"></i>
                                        <input #searchValue pInputText type="text" id="search-{{selectedDateIndex}}"
                                            name="search-{{selectedDateIndex}}" [(ngModel)]="searchTextStr"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="{{'globalsearch' | translate}}" />
                                    </span>
                                    <span>
                                        <button pButton pRipple type="button" icon="pi pi-times"
                                            class="p-button-rounded p-button-danger p-button-text"
                                            (click)="dt.reset();clearSearchFilter()" tooltipPosition="top"
                                            pTooltip="{{'clear' | translate}}"></button>
                                    </span>
                                    <p-message *ngIf="filteredItemsSize > -1" severity="success"
                                        text="{{filteredItemsSize}} {{'records_found' | translate}}."
                                        styleClass="p-ml-2"></p-message>

                                    <span class="pull-right p-ml-2">
                                        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns"
                                            optionLabel="header" selectedItemsLabel="{0} columns selected"
                                            (onChange)="onChangeSelectedCols($event)" [style]="{minWidth: '200px'}"
                                            placeholder="Choose Columns"></p-multiSelect>
                                    </span>
                                    <span class="pull-right">
                                        <p-button label="{{'Time' | translate}}" pRipple icon="fal fa-plus"
                                            styleClass="p-button-sm p-mx-2"
                                            (click)="openTimeEntryOrExpenseEntryDailog({'IsTimeRecord':'T'},true)">
                                        </p-button>
                                        <p-button label="{{'Expense' | translate}}" pRipple icon="fal fa-plus"
                                            styleClass="p-button-sm"
                                            (click)="openTimeEntryOrExpenseEntryDailog({'IsTimeRecord':'X'},true)">
                                        </p-button>
                                    </span>
                                    <span class="pull-right p-mr-3 p-mt-2 ic-blue">
                                        <p-checkbox [(ngModel)]="showStartStopTime" binary="true" inputId="binary"
                                            (click)="updateStartStopTime()" label="{{'Show-Time' | translate}}">
                                        </p-checkbox>
                                    </span>
                                    <span class="pull-right">
                                        <span *ngIf="selectedTsList?.length > 0">
                                            <app-batch-options-time [selOpenTimeRecords]="getOpenTimeRecords(selectedTsList)" [selOpenExpRecords]="getExpenseRecords(selectedTsList)" [showApproveOption]="isAllowUser"></app-batch-options-time>
                                        </span>
                                    </span>
                                    <span class="pull-right">
                                        <button *ngIf="selectedTsList?.length > 0" pButton pRipple icon="fal fa-times"
                                            class="p-button-sm p-mr-3 p-mb-1" label="{{'Unselect All' | translate}}"
                                            (click)="unselectRows();"></button>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th class="width-4p p-text-center">
                                        <p-tableHeaderCheckbox (click)="onToggleHeaderCheckbox($event)"></p-tableHeaderCheckbox>
                                    </th>
                                    <!-- <th class="width-2p p-text-left"></th> -->
                                    <th class="width-6p p-text-left">{{'actions' | translate}}</th>

                                    <th class="width-7p p-text-left" pSortableColumn="sortableTimeStart"
                                        [hidden]="!showStartStopTime">
                                        {{'Start' | translate}}
                                        <p-sortIcon field="sortableTimeStart"></p-sortIcon>
                                    </th>
                                    <th class="width-7p p-text-left" pSortableColumn="sortableTimeStop"
                                        [hidden]="!showStartStopTime">
                                        {{'Stop' | translate}}
                                        <p-sortIcon field="sortableTimeStop"></p-sortIcon>
                                    </th>
                                    <ng-container *ngFor="let col of columns;">
                                        <th *ngIf="col.field !== 'Taxable'" [pSortableColumn]="col.field"
                                            class="{{col.class}}">
                                            <span *ngIf="col.field !== 'Taxable'">
                                                {{col.header}}
                                                <p-sortIcon [field]="col.field" *ngIf="col.field !== 'Taxable'"></p-sortIcon>
                                            </span>
                                        </th>
                                    </ng-container>
                                    <th class="width-8p p-text-left" pSortableColumn="timestamp">
                                        {{'date' | translate}}
                                        <p-sortIcon field="timestamp"></p-sortIcon>
                                    </th>
                                    <th class="width-15p p-text-left" pSortableColumn="ClientName">
                                        {{'client.name' | translate}}
                                        <p-sortIcon field="ClientName"></p-sortIcon>
                                    </th>
                                    <th class="width-9p p-text-left" pSortableColumn="EngagementName">
                                        {{'timer.engagement' | translate}}
                                        <p-sortIcon field="EngagementName"></p-sortIcon>
                                    </th>
                                    <!--  <th class="width-25p p-text-left">{{'memo' | translate}}</th> -->
                                    <th class="width-5p p-text-right" pTooltip="{{'Total-Hours' | translate}}"
                                        tooltipPosition="top">
                                        {{'Total' | translate}}
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                    <th class="width-7p p-text-right" pTooltip="{{'Billable-Hours' | translate}}"
                                        tooltipPosition="top">
                                        {{'billable' | translate}}
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                    <th class="width-7p p-text-right" pTooltip="{{'time.Billing-Rate' | translate}}"
                                        tooltipPosition="top" [hidden]="isHideBillRateColumn">
                                        {{'rate' | translate}}
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                    <th class="width-7p p-text-right" pTooltip="{{'billable-amount' | translate}}"
                                        tooltipPosition="top" [hidden]="isHideBillRateColumn">
                                        {{'amount' | translate}}
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                    <th class="width-7p p-text-right" pTooltip="{{'Non-Billable-Amount' | translate}}"
                                        tooltipPosition="top" [hidden]="isHideBillRateColumn">
                                        N/B Amt
                                        <i class="fa fa-question-circle ic-blue"></i>
                                    </th>
                                    <th class="width-7p p-text-right">{{'billing-invoicing.apply-to-expenses' |
                                        translate}}
                                    </th>
                                    <th class="width-5p p-text-center">{{'billable' | translate}}</th>
                                    <th class="width-7p p-text-center">Appr.</th>
                                    <ng-container *ngFor="let col of columns;">
                                        <th *ngIf="col.field === 'Taxable'"
                                            class="{{col.class}}">
                                            <span>
                                                {{col.header}}
                                            </span>
                                        </th>
                                    </ng-container>
                                    <th class="width-3p p-text-center">{{'Flat' | translate}}</th>
                                    <th class="width-4p p-text-center">{{'billed' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-ri="rowIndex" let-expanded="expanded"
                                let-columns="columns" let-rowData>
                                <tr class="cursor-hand" (click)="cancelEditing($event,rowData)">
                                    <td class="width-4p p-text-center">
                                        <p-tableCheckbox [value]="rowData" (click)="onToggleCheckbox($event)"></p-tableCheckbox>
                                    </td>
                                    <td class="width-6p">
                                        <div class="p-text-left">
                                            <i pRipple class="fal fa-pencil p-mr-1 ic-green"
                                                (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                                tooltipPosition="top" pTooltip="{{'Edit' | translate}}"></i>
                                            <i pRipple (click)="deleteClientAlert(rowData,rowData.index);"
                                                class="fal fa-trash-alt ic-red p-pl-2" tooltipPosition="top"
                                                pTooltip="{{'mgmt-delete' | translate}}"></i>
                                            <i class="fal fa-save ic-blue p-ml-3" (click)="updateTS('yes', rowData)"
                                                *ngIf="rowData['IsColumnChanges']"
                                                pTooltip="{{'save_button' | translate}}" tooltipPosition="top"></i>
                                        </div>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-left" [hidden]="!showStartStopTime">
                                        {{ rowData['TimeStart'] | utcTime }}
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-left" [hidden]="!showStartStopTime">
                                        {{ rowData['TimeStop'] | utcTime }}
                                    </td>
                                    <td *ngFor="let col of columns" class="{{col.class}}" [pEditableColumn]="rowData"
                                        [pEditableColumnField]="col.field" [pEditableColumnRowIndex]="ri"
                                        [ngStyle]="{'overflow': (cellFocused && cellFocused.field === 'Memo' && cellFocused.index === ri) ? 'visible' : 'hidden'}"
                                        pTooltip="{{rowData[col.field]}}" showDelay="800"
                                        tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                        [pEditableColumn]="col.editable">
                                        <span *ngIf="col.field==='SlipMasterID'">
                                            {{rowData.SlipMasterID}}
                                        </span>

                                        <span *ngIf="col.field==='Memo'"
                                            class="p-text-left p-text-nowrap p-text-truncate">
                                            <p-cellEditor id="invoice_desc_column_{{rowData['uuid']}}">
                                                <ng-template pTemplate="input">
                                                    <span>
                                                        <app-mentions [(inputText)]="rowData['Memo']" [disabled]="(rowData['Billed'] && !rowData['MarkSlipsBilled']) || (isLocked(rowData['Ddate']) && rowData['IsTimeRecord'] !== 'X')" (inputTextChange)="rowData['IsColumnChanges'] = true" id="desc" [rows]="1" style="width:100% !important; resize: vertical;"></app-mentions>
                                                    </span>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ rowData.Memo }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </span>

                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-8p p-text-left"
                                        *ngIf="!rowData.IsTimeRecord || rowData.IsTimeRecord === 'T'">
                                        {{rowData['displayDate']}}
                                    </td>
                                    <td pEditableColumn class="width-8p p-text-left"
                                        *ngIf="rowData.IsTimeRecord === 'X'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                                    (ngModelChange)="rowData['IsColumnChanges'] = true"
                                                    [disabled]="(rowData['Billed'] && !rowData['MarkSlipsBilled']) || (isLocked(rowData['Ddate']) && rowData['IsTimeRecord'] !== 'X')"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="rowData['Ddate']"
                                                    appendTo="body" yearRange="2000:2099" dateFormat="mm/dd/yy"
                                                    showButtonBar="true">
                                                </p-calendar>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span>{{rowData['Ddate'] | date : 'MM/dd/yyyy'}}</span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pEditableColumn class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                        showDelay="800" tooltipPosition="top" pTooltip="{{rowData.ClientName}}"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-autoComplete [suggestions]="filteredClients"
                                                    [disabled]="(rowData['Billed'] && !rowData['MarkSlipsBilled']) || (isLocked(rowData['Ddate']) && rowData['IsTimeRecord'] !== 'X')"
                                                    autocomplete="off" (completeMethod)="filterClients($event)"
                                                    [minLength]="1" [dropdown]="true"
                                                    placeholder="{{rowData.ClientName}}" appendTo="body"
                                                    (onFocus)="$event.target.select()" class="p-text-truncate"
                                                    field="ClientName"
                                                    (onSelect)="handleClientSelectClick($event.value, rowData);">
                                                    <ng-template let-client pTemplate="item">
                                                        <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' +
                                                            client.ClientNumber : '' }}</div>
                                                    </ng-template>
                                                </p-autoComplete>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div class="text-nowrap p-text-nowrap p-text-truncate">
                                                    {{rowData.ClientName}}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pEditableColumn class="width-9p p-text-left p-text-nowrap p-text-truncate"
                                        showDelay="800" tooltipPosition="top" pTooltip="{{rowData.EngagementName}}"
                                        tooltipStyleClass="tooltip-no-wrap">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown [options]="rowData.engagementsList"
                                                    [(ngModel)]="rowData.ProjectMasterID" name="projectMasterID"
                                                    [filter]="true" id="projectMasterID" #projectMasterID="ngModel"
                                                    (onChange)="handleEngagementSelectClick($event, rowData,false); rowData.IsColumnChanges = true;"
                                                    appendTo="body" class="p-text-truncate"
                                                    [disabled]="rowData.ClientID==null || (rowData['Billed'] && !rowData['MarkSlipsBilled']) || (isLocked(rowData['Ddate']) && rowData['IsTimeRecord'] !== 'X')"
                                                    [style]="{'width':'100%'}">
                                                </p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div class="p-text-left p-text-nowrap p-text-truncate">
                                                    {{rowData['EngagementName']}}</div>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-5p p-text-right" showDelay="800"
                                        pTooltip="{{getTooltip(rowData['ElaspedTime'] | number:'1.2-2')}}"
                                        tooltipPosition="top" tooltipStyleClass="tooltip-no-wrap">
                                        {{ (rowData['ElaspedTime'] || 0) | roundNumber }}
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-right" *ngIf="rowData.IsTimeRecord === 'X'">
                                        <span *ngIf="rowData.Billable">{{rowData['TotalTime'] | number:'1.2-2' }}</span>
                                        <span *ngIf="!rowData.Billable"></span>
                                    </td>
                                    <td pEditableColumn class="width-7p p-text-right"
                                        *ngIf="rowData.Billable && (!rowData.IsTimeRecord || rowData.IsTimeRecord === 'T')">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="width-100p" pInputText [(ngModel)]="rowData['TotalTime']"
                                                    (focus)="$event.target.select();"
                                                    (blur)="changeTime($event,rowData);onTotalTimeChange($event, rowData)"
                                                    [disabled]="(rowData['Billed'] && !rowData['MarkSlipsBilled']) || (isLocked(rowData['Ddate']) && rowData['IsTimeRecord'] !== 'X')">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{ rowData.TotalTime }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-right"
                                        *ngIf="!rowData.Billable && rowData.IsTimeRecord === 'T'">
                                        <span></span>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-right" *ngIf="rowData.IsTimeRecord === 'X'"
                                        [hidden]="isHideBillRateColumn">
                                        {{ rowData.BillingRate | currency: "USD":true:"1.2-2" }}
                                    </td>
                                    <td pEditableColumn class="width-7p p-text-right" [hidden]="isHideBillRateColumn"
                                        *ngIf="!rowData.IsTimeRecord || rowData.IsTimeRecord === 'T'">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input class="width-100p" pInputText
                                                    [(ngModel)]="rowData['BillingRate']"
                                                    (focus)="$event.target.select();"
                                                    (blur)="changeUSMoney($event,rowData);onBillRateChange($event, rowData)"
                                                    (focus)="$event.target.select()"
                                                    (ngModelChange)="rowData['IsColumnChanges'] = true;editCellComplete($event,rowData)"
                                                    [disabled]="(rowData['Billed'] && !rowData['MarkSlipsBilled']) || (isLocked(rowData['Ddate']) && rowData['IsTimeRecord'] !== 'X')">
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{ rowData.BillingRate | currency: "USD":true:"1.2-2" }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-right" [hidden]="isHideBillRateColumn">
                                        <span *ngIf="rowData.isFlatFee">{{rowData['BilledAmount']
                                            |
                                            currency:'USD':'symbol':'1.2-2' }}</span>
                                        <span *ngIf="rowData.Billable && !rowData.isFlatFee">{{rowData['StandardAmount']
                                            |
                                            currency:'USD':'symbol':'1.2-2' }}</span>
                                        <span *ngIf="!rowData.Billable"></span>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-right" [hidden]="isHideBillRateColumn">
                                        <span *ngIf="!rowData.Billable">{{rowData['StandardAmount'] |
                                            currency:'USD':'symbol':'1.2-2' }}</span>
                                        <span *ngIf="rowData.Billable"></span>
                                    </td>
                                    <td class="width-7p p-text-right p-text-nowrap p-text-truncate">
                                        <span *ngIf="!rowData.IsTimeRecord || rowData.IsTimeRecord === 'T' "></span>
                                        <span *ngIf="rowData.IsTimeRecord !=='' && rowData.IsTimeRecord === 'X' ">
                                            {{rowData['StandardAmount'] | currency:'USD':'symbol':'1.2-2' }}</span>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-5p p-text-center">
                                        <input style="position: relative;top: -1px;" type="checkbox"
                                            [checked]="rowData['Billable']" checked="checked" disabled="true">
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-7p p-text-center">
                                        <i class="fa fa-check ic-red" *ngIf="rowData['Approved']"></i>
                                    </td>
                                    <ng-container *ngFor="let col of columns">
                                        <td *ngIf="col.field === 'Taxable'" class="{{col.class}}" [pEditableColumn]="rowData"
                                            [pEditableColumnField]="col.field" [pEditableColumnRowIndex]="ri"
                                            [ngStyle]="{'overflow': (cellFocused && cellFocused.field === 'Memo' && cellFocused.index === ri) ? 'visible' : 'hidden'}"
                                            pTooltip="{{rowData[col.field]}}" showDelay="800"
                                            tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                            [pEditableColumn]="col.editable">
                                            <span class="width-7p p-text-center">
                                            <input style="position: relative;top: -1px;" type="checkbox"
                                                [checked]="rowData['IsTaxable']" checked="checked" disabled="true">
                                            </span>
                                        </td>
                                    </ng-container>
                                    <td
                                        class="width-3p nested-table-header p-text-center p-text-nowrap p-text-truncate">
                                        <i class="fa fa-check ic-red" *ngIf="rowData['isFlatFee']"></i>
                                    </td>
                                    <td (click)="openTimeEntryOrExpenseEntryDailog(rowData,false)"
                                        class="width-4p p-text-center" id="border-right">
                                        <i *ngIf="rowData['Billed']" class="fal fa-lock ic-red ic-size-17"></i>
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="footer" let-columns>
                                <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && tsList.length > 0)">
                                    <td class="width-4p p-text-center"></td>
                                    <td class="width-6p p-text-left"></td>
                                    <td class="width-7p p-text-left" [hidden]="!showStartStopTime"></td>
                                    <td class="width-7p p-text-left" [hidden]="!showStartStopTime"> </td>
                                    <td *ngFor="let col of columns" class="{{col.class}}"></td>
                                    <td class="width-8p p-text-left">
                                    </td>
                                    <td class="width-15p p-text-left">
                                    </td>
                                    <td class="width-9p">
                                        <span [hidden]="showStartStopTime" class="p-text-right ic-red width-67p">
                                            <strong>Day Totals</strong>
                                        </span>
                                        <span [hidden]="!showStartStopTime" class="p-text-right ic-red width-81p">
                                            <strong>Day Totals</strong></span>
                                    </td>
                                    <!--  <th class="width-25p p-text-left">{{'memo' | translate}}</th> -->
                                    <td class="width-5p p-text-right  ic-red">
                                        <strong>{{ totalElaspedTime | number:'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <strong>{{ totalTime | number:'1.2-2'}}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        &nbsp;
                                    </td>
                                    <td class="width-7p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{ billableTotals | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{ totalAmtT2 | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <strong>{{ expenseTotals| currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <ng-container *ngFor="let col of columns;">
                                        <td *ngIf="col.field === 'Taxable'"
                                            class="{{col.class}}">
                                        </td>
                                    </ng-container>
                                    <td class="width-5p p-text-center">
                                        &nbsp;
                                    </td>
                                    <td class="width-7p p-text-center">

                                    </td>
                                    <td class="width-3p p-text-center"></td>
                                    <td class="width-4p p-text-center"></td>
                                    <!--  <td [hidden]="showStartStopTime" class="p-text-right ic-red width-67p"> <strong>Day Totals</strong>
                                    </td>
                                    <td [hidden]="!showStartStopTime" class="p-text-right ic-red width-81p"> <strong>Day Totals</strong></td>
                                    <td class="p-text-right width-5p ic-red">
                                        <strong>{{ totalElaspedTime | number:'1.2-2' }}</strong>
                                    </td>
                                    <td class="p-text-right width-7p ic-red">
                                        <strong>{{ totalTime | number:'1.2-2'}}</strong>
                                    </td>
                                    <td class="p-text-right width-7p" [hidden]="isHideBillRateColumn">
                                        &nbsp;
                                    </td>
                                    <td class="p-text-right width-7p ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{ billableTotals | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="p-text-right width-7p ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{ totalAmtT2 | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="p-text-right width-7p ic-red">
                                        <strong>{{ expenseTotals| currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-5p" [hidden]="isHideBillRateColumn">
                                        &nbsp;
                                    </td>
                                    <td class="width-19p" *ngIf="isHideBillRateColumn">&nbsp;</td>
                                    <td class="width-14p" *ngIf="!isHideBillRateColumn">&nbsp;</td> -->
                                </tr>
                                <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && tsList.length > 0)">
                                    <td class="width-4p p-text-center"></td>
                                    <td class="width-6p p-text-left"></td>
                                    <td class="width-7p p-text-left" [hidden]="!showStartStopTime"></td>
                                    <td class="width-7p p-text-left" [hidden]="!showStartStopTime"> </td>
                                    <td *ngFor="let col of columns" class="{{col.class}}"></td>
                                    <td class="width-8p p-text-left">
                                    </td>
                                    <td class="width-15p p-text-left">
                                    </td>
                                    <td class="width-9p">
                                        <span [hidden]="showStartStopTime" class="p-text-right ic-red width-67p">
                                            <strong>Week Totals:</strong>
                                        </span>
                                        <span [hidden]="!showStartStopTime" class="p-text-right ic-red width-81p">
                                            <strong>Week Totals:</strong>
                                        </span>
                                    </td>
                                    <!--  <th class="width-25p p-text-left">{{'memo' | translate}}</th> -->
                                    <td class="width-5p p-text-right  ic-red">
                                        <strong>{{ totalWeekElaspedTime | number:'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <strong>{{ totalWeekTime| number:'1.2-2'}}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        &nbsp;
                                    </td>
                                    <td class="width-7p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{wkbillableTotals | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{totalWeekAmtT2 | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="width-7p p-text-right ic-red">
                                        <strong>{{ wkExpenseTotals | currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </td>
                                    <ng-container *ngFor="let col of columns;">
                                        <td *ngIf="col.field === 'Taxable'"
                                            class="{{col.class}}">
                                        </td>
                                    </ng-container>
                                    <td class="width-5p p-text-center">
                                        &nbsp;
                                    </td>
                                    <td class="width-7p p-text-center">

                                    </td>
                                    <td class="width-3p p-text-center"></td>
                                    <td class="width-4p p-text-center"></td>

                                    <!-- <td [hidden]="showStartStopTime" class="p-text-right ic-red width-67p">
                                        <strong>Week Totals:</strong></td>
                                    <td [hidden]="!showStartStopTime" class="p-text-right ic-red width-81p">
                                        <strong>Week Totals:</strong>
                                    </td>
                                    <td class="p-text-right width-5p ic-red">
                                        <strong>{{ totalWeekElaspedTime | number:'1.2-2' }}</strong>
                                    </td>
                                    <td class="p-text-right width-7p ic-red">
                                        <strong>{{ totalWeekTime| number:'1.2-2'}}</strong>
                                    </td>
                                    <td class="width-7p" [hidden]="isHideBillRateColumn">
                                        &nbsp;
                                    </td>
                                    <td class="p-text-right width-7p ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{wkbillableTotals | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="p-text-right width-7p ic-red" [hidden]="isHideBillRateColumn">
                                        <strong>{{totalWeekAmtT2 | currency:'USD':'symbol':'1.2-2' }}</strong>
                                    </td>
                                    <td class="p-text-right width-7p ic-red">
                                        <strong>{{ wkExpenseTotals | currency:'USD':'symbol':'1.2-2'}}</strong>
                                    </td>
                                    <td class="width-5p p-text-right ic-red" [hidden]="isHideBillRateColumn">
                                        &nbsp;
                                    </td>
                                    <td *ngIf="isHideBillRateColumn" class="width-19p p-text-right ic-red"></td>
                                    <td *ngIf="!isHideBillRateColumn" class="width-14p p-text-right ic-red"></td> -->
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td *ngIf="showStartStopTime" [attr.colspan]="18">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                    <td *ngIf="!showStartStopTime" [attr.colspan]="16">
                                        <p-message severity="info" text="{{'data_not_found' | translate}}."
                                            styleClass="p-px-2"></p-message>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </ng-template>
                </div>
            </div>
            <!-- <div class="p-col-12 p-pt-0" *ngIf="tsList.length > 0">
                <div class="p-col-5 card permissions pull-right total-box-fixed total-box-pr">
                    <table class="p-datatable p-datatable-sm p-component width-100p">
                        <thead class="p-datatable-thead">
                            <tr>
                                <th class="p-text-right"><strong class="label-text">&nbsp;</strong></th>
                                <th class="p-text-right ic-size-15"><strong class="label-text">{{'Total-Hrs' |
                                        translate}}</strong></th>
                                <th class="p-text-right ic-size-15"><strong class="label-text">{{'Billable_Hrs' |
                                        translate}}</strong></th>

                                <th class="p-text-right ic-size-15" *ngIf="!isHideBillRateColumn"><strong
                                        class="label-text">{{'billable-amount' | translate}}</strong></th>
                                <th class="p-text-right ic-size-15" *ngIf="!isHideBillRateColumn"><strong
                                        class="label-text">{{'nonbillable-amount' | translate}}</strong></th>
                                <th class="p-text-right ic-size-15"><strong
                                        class="label-text">{{'billing-invoicing.apply-to-expenses' |
                                        translate}}</strong></th>

                            </tr>
                        </thead>
                        <tbody class="p-datatable-tbody">
                            <tr>
                                <td><strong>{{'Day-Totals' | translate}}</strong></td>
                                <td class="p-text-right ic-size-15">
                                    <strong>{{totalElaspedTime| number:'1.2-2'}}</strong>
                                </td>
                                <td class="p-text-right ic-size-15">
                                    <strong>{{totalTime| number:'1.2-2'}}</strong>
                                </td>
                                <td class="p-text-right ic-size-15" *ngIf="!isHideBillRateColumn">
                                    <strong>{{ billableTotals | currency:'USD':'symbol':'1.2-2' }}</strong>
                                </td>
                                <td class="p-text-right ic-size-15" *ngIf="!isHideBillRateColumn">
                                    <strong>{{ totalAmtT2 | currency:'USD':'symbol':'1.2-2' }}</strong>
                                </td>
                                <td class="p-text-right ic-size-15">
                                    <strong>{{expenseTotals | currency:'USD':'symbol':'1.2-2'}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>{{'Week-Totals' | translate}}</strong></td>
                                <td class="p-text-right ic-size-15">
                                    <strong>{{totalWeekElaspedTime| number:'1.2-2'}}</strong>
                                </td>
                                <td class="p-text-right ic-size-15">
                                    <strong>{{totalWeekTime| number:'1.2-2'}}</strong>
                                </td>
                                <td class="p-text-right ic-size-15" *ngIf="!isHideBillRateColumn">
                                    <strong>{{wkbillableTotals | currency:'USD':'symbol':'1.2-2' }}</strong>
                                </td>
                                <td class="p-text-right ic-size-15" *ngIf="!isHideBillRateColumn">
                                    <strong>{{totalWeekAmtT2 | currency:'USD':'symbol':'1.2-2' }}</strong>
                                </td>
                                <td class="p-text-right ic-size-15">
                                    <strong>{{wkExpenseTotals | currency:'USD':'symbol':'1.2-2'}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
        </div>
    </div>
</div>
