import { Injectable, Inject, Compiler } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, Subject, throwError, forkJoin, of, lastValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MangoApiService {
  public isFormChanged: boolean = false;
  isLoggedIn = new Subject<boolean>();
  isLoading = new BehaviorSubject(false);
  notificationChange: Subject<Object> = new Subject<Object>();
  clientViewChange = new BehaviorSubject(false);
  clienBadgeViewChange = new BehaviorSubject(false);
  engagementBadgeViewChange = new BehaviorSubject(false);
  userViewChange = new BehaviorSubject(false);
  engagementViewChange = new BehaviorSubject(false);
  profileViewChange = new BehaviorSubject(false);
  imageViewChange = new BehaviorSubject(false);
  isInvoiceUpdated = new BehaviorSubject(false);
  private capacityPlanningMenu = new BehaviorSubject(false);

  clientViewChange$ = this.clientViewChange.asObservable();
  clienBadgeViewChange$ = this.clienBadgeViewChange.asObservable();
  engagementBadgeViewChange$ = this.engagementBadgeViewChange.asObservable();
  profileViewChange$ = this.profileViewChange.asObservable();
  imageViewChange$ = this.imageViewChange.asObservable();
  capacityPlanningMenu$ = this.capacityPlanningMenu.asObservable();
  userViewChange$ = this.userViewChange.asObservable();
  engagementViewChange$ = this.engagementViewChange.asObservable();
  isLoggedIn$ = this.isLoggedIn.asObservable();
  isInvoiceUpdated$ = this.isInvoiceUpdated.asObservable();

  constructor(
    public http: HttpClient,
    @Inject(DOCUMENT) private _document: Document,
    public translate: TranslateService,
    private _compiler: Compiler
  ) {}

  getConstants() {
    return this.http.get(`${environment.API_URL}/api-constants`)
    .pipe(catchError(this.handleError))
    .toPromise();
  }

  getNativeWindow() {
    return window;
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }

  notifyLogging(flag: boolean) {
    this.isLoggedIn.next(flag);
  }

  showLoader(isLoaded: boolean) {
    this.isLoading.next(isLoaded);
  }

  notify(type, text, message) {
    this.notificationChange.next({ type: type, text: text, message: message });
  }

  applyLanguage() {
    // this._compiler.clearCache();
    this.translate.addLangs(['en', 'es']);
    var lang = localStorage.getItem('language');
    lang = lang ? lang : 'English';
    switch (lang) {
      case 'Spanish':
        this.translate.setDefaultLang('es');
        this.translate.use('es');
        break;
      case 'English':
      default:
        this.translate.setDefaultLang('en');
        this.translate.use('en');
        break;
    }
  }

  fireClientView(flag) {
    this.clientViewChange.next(flag);
  }

  fireClientBadgeView(flag) {
    this.clienBadgeViewChange.next(flag);
  }

  fireEngagementBadgeView(flag) {
    this.engagementBadgeViewChange.next(flag);
  }

  fireEngagementView(flag) {
    this.engagementViewChange.next(flag);
  }

  fireInvoiceUpdate(flag) {
    this.isInvoiceUpdated.next(flag);
  }

  fireUserView(flag) {
    this.userViewChange.next(flag);
  }

  fireProfileView(flag) {
    this.profileViewChange.next(flag);
  }

  fireImageView(flag) {
    this.imageViewChange.next(flag);
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  login(user) {
    return this.http
      .post(`${environment.API_URL}/authenticate`, user)
      .pipe(catchError(this.handleError));
  }

  logout() {
    return this.http.put(`${environment.API_URL}/logout`, {}).pipe(catchError(this.handleError));
  }

  forgotPassword(userData) {
    return this.http
      .put(`${environment.API_URL}/forgotPassword`, userData)
      .pipe(catchError(this.handleError));
  }

  verifyToken(token) {
    return this.http
      .get(`${environment.API_URL}/verifyToken/${token}`)
      .pipe(catchError(this.handleError));
  }

  resetPassword(postData, token) {
    return this.http
      .put(`${environment.API_URL}/resetPassword/${token}`, postData)
      .pipe(catchError(this.handleError));
  }

  getUserInfo(userId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/staff/users/${userId}`)
      .pipe(catchError(this.handleError));
  }

  getUserInfoByCompanyID(companyID): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/staff/usersByCompanyID/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  updateCompanyView(obj) {
    delete obj['PortalPaymentNotifications'];
    delete obj['MangoPaymentNotification'];
    return this.http.put(`${environment.API_URL}/company`, obj).pipe(catchError(this.handleError));
  }

  updateCompanyNotificationSetting(obj) {
    return this.http.put(`${environment.API_URL}/company`, obj).pipe(catchError(this.handleError));
  }

  updateLastInvoiceNumber(obj) {
    return this.http.put(`${environment.API_URL}/company`, obj).pipe(catchError(this.handleError));
  }

  updateStartStopTime(obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/setStartStopTime`, obj)
      .pipe(catchError(this.handleError));
  }

  getStartStopTime(id) {
    this.getCompanyMangoDetails(id);
  }

  approveTSE(arr) {
    return this.http
      .put(`${environment.API_URL}/company/approveTimeSheet`, arr)
      .pipe(catchError(this.handleError));
  }

  approveExpenseEntry(arr) {
    return this.http
      .put(`${environment.API_URL}/company/approveExpenseEntry`, arr)
      .pipe(catchError(this.handleError));
  }

  getCompanyViewData() {
    return this.http
      .get(`${environment.API_URL}/company/profileInfo`)
      .pipe(catchError(this.handleError));
  }

  getALLPaymentsData() {
    return this.http
      .get(`${environment.API_URL}/make-deposit/getAllPayments`)
      .pipe(catchError(this.handleError));
  }

  getClientPayments(clientId, limit) {
    return this.http
      .get(`${environment.API_URL}/make-deposit/getAllPayments/${clientId}/${limit}`)
      .pipe(catchError(this.handleError));
  }

  getBankNames() {
    return this.http.get(`${environment.API_URL}/company/banks`).pipe(catchError(this.handleError));
  }

  isUserExitsOrNot(email) {
    return this.http
      .get(`${environment.API_URL}/staff/isUserPresents/${email}`)
      .pipe(catchError(this.handleError));
  }

  getCompanyMangoDetails(companyID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/companyMango/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getPaymentDetailByHeaderID(id) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/payment-detail/getPaymentDetailByHeaderID/${id}`)
      .pipe(catchError(this.handleError));
  }

  getBillingHeaderByHeaderID(id) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/billing-header/getHeaderDataByID/${id}`)
      .pipe(catchError(this.handleError));
  }

  getBillingHeaderSingleRecordById(id) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/billing-header/getHeaderDataByID/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateUser(data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/staff/users/null`, data)
      .pipe(catchError(this.handleError));
  }

  updateUserByAdmin(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/staff/users/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  updateStaffClientView(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/staff/users/changeStaffClientView/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  updateClientRate(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/staff/client/rates/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  getUserPermissonsData(id) {
    return this.http
      .get(`${environment.API_URL}/staff/permission/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateUserPermissonsData(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/permission/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  validateOutlookToken(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/outlook/validateOutlookToken/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  getOutlookApiToken(id) {
    return this.http
      .get(`${environment.API_URL}/staff/outlook/getStaffRefreshTokens/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateOutlookApiToken(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/outlook/updateOutlookTokens/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateCompanyMangoDetails(obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/setLoadScreen`, obj)
      .pipe(catchError(this.handleError));
  }

  updateProfileView(obj) {
    return this.http
      .put(`${environment.API_URL}/staff/users/null`, obj)
      .pipe(catchError(this.handleError));
  }

  getLoginUrl() {
    return this.http
      .get(`${environment.API_URL}/staff/outlook/loginUrl`)
      .pipe(catchError(this.handleError));
  }

  getApiAuthURL() {
    return this.http.get(`${environment.API_URL}/gapi/auth`).pipe(catchError(this.handleError));
  }

  getApiToken() {
    return this.http.get(`${environment.API_URL}/gapi/token`).pipe(catchError(this.handleError));
  }

  getProjectNotes(projectMasterID) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getNotes/${projectMasterID}`)
      .pipe(catchError(this.handleError));
  }

  updateApiToken(code: string) {
    return this.http
      .get(`${environment.API_URL}/gapi/oauthcallback?code=${code}`)
      .pipe(catchError(this.handleError));
  }

  removeApiToken() {
    return this.http.delete(`${environment.API_URL}/gapi/token`).pipe(catchError(this.handleError));
  }

  updateApiSettings(postData) {
    return this.http
      .put(`${environment.API_URL}/gapi/settings`, postData)
      .pipe(catchError(this.handleError));
  }

  getCompanyDetails(id?: string) {
    return this.http
      .get(`${environment.API_URL}/client/companyDetails/${id}`)
      .pipe(catchError(this.handleError));
  }

  // getAllStaffMembers() {
  //     return this.http.get(`${environment.API_URL}/staff/getAllStaffMembers`).pipe(catchError(this.handleError));
  // }

  getProfileData() {
    return this.http
      .get(`${environment.API_URL}/staff/users/null`)
      .pipe(catchError(this.handleError));
  }

  fetchClientsCount() {
    return this.http
      .get(`${environment.API_URL}/staff/clientCount`)
      .pipe(catchError(this.handleError));
  }

  getAllClientsByCompanyId(companyId, params?) {
    let url = `${environment.API_URL}/staff/getAllClientListByCompanyId/${companyId}`;
    if (params) {
      url += params;
    }
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  fetchGroupCategory() {
    return this.http
      .get(`${environment.API_URL}/company/customerGroupCategory`)
      .pipe(catchError(this.handleError));
  }

  fetchClientType() {
    return this.http
      .get(`${environment.API_URL}/company/customerType`)
      .pipe(catchError(this.handleError));
  }

  getPayments(id) {
    return this.http
      .get(`${environment.API_URL}/staff/payment/${id}`)
      .pipe(catchError(this.handleError));
  }

  unapplyPayment(id, data) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-headers/unapplyPayments/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  assignPaymentToInvoice(data) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-headers/assignPaymentsToInvoice`, data)
      .pipe(catchError(this.handleError));
  }

  getClientFullinformation(id) {
    return this.http
      .get(`${environment.API_URL}/staff/client/${id}`)
      .pipe(catchError(this.handleError));
  }

  getClientFullinformationSuperAdmin(id) {
    return this.http
      .get(`${environment.API_URL}/super-admin/staff/client/${id}`)
      .pipe(catchError(this.handleError));
  }

  getDetailsCountByProjectId(projectMasterID) {
    return this.http
      .get(
        `${environment.API_URL}/company/projects/contactNotesTimeSlipsCount/${projectMasterID}/true`
      )
      .pipe(catchError(this.handleError));
  }

  getContacts(id) {
    return this.http
      .get(`${environment.API_URL}/staff/contact/${id}`)
      .pipe(catchError(this.handleError));
  }

  getAllDataCounts(id) {
    return this.http
      .get(`${environment.API_URL}/staff/contactNotesTimeSlipsCount/${id}`)
      .pipe(catchError(this.handleError));
  }

  getAllEngagementDataCounts(id) {
    return this.http
      .get(`${environment.API_URL}/company/projects/contactNotesTimeSlipsCount/${id}/null`)
      .pipe(catchError(this.handleError));
  }

  getClientRates(id) {
    return this.http
      .get(`${environment.API_URL}/staff/client/rates/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteClient(id, obj) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: obj
    };
    return this.http
      .delete(`${environment.API_URL}/staff/client/${id}`, options)
      .pipe(catchError(this.handleError));
  }

  getEmailSignature(staffID) {
    return this.http
      .get(`${environment.API_URL}/dms/emailSignature/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  deleteClientRate(id) {
    return this.http
      .delete(`${environment.API_URL}/staff/client/rates/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteProject(id) {
    return this.http
      .delete(`${environment.API_URL}/company/projects/deleteProject/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteTimeSheet(id) {
    return this.http
      .delete(`${environment.API_URL}/company/timeSheet/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteContact(id) {
    return this.http
      .delete(`${environment.API_URL}/staff/contact/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteBillingHeader(id) {
    return this.http
      .delete(`${environment.API_URL}/cash-receipts/billing-header/${id}`)
      .pipe(catchError(this.handleError));
  }

  deletePaymentDetails(id) {
    return this.http
      .delete(`${environment.API_URL}/cash-receipts/payment-detail/${id}`)
      .pipe(catchError(this.handleError));
  }

  deletePaymentHeader(id) {
    return this.http
      .delete(`${environment.API_URL}/cash-receipts/payment-header/${id}`)
      .pipe(catchError(this.handleError));
  }

  addContact(clientId, obj) {
    return this.http
      .post(`${environment.API_URL}/staff/contact/${clientId}`, obj)
      .pipe(catchError(this.handleError));
  }

  assignContactsToMultipleClients(clientsList, contactsList) {
    return this.http
      .post(`${environment.API_URL}/company/contacts`, { clientsList, contactsList })
      .pipe(catchError(this.handleError));
  }

  queryTest(obj) {
    return this.http
      .post(`${environment.API_URL}/mangoapi/queryTest`, obj)
      .pipe(catchError(this.handleError));
  }

  saveClientRate(id, obj) {
    return this.http
      .post(`${environment.API_URL}/staff/client/rates/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  createPaymentDetail(postData: any) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-detail`, postData)
      .pipe(catchError(this.handleError));
  }

  createDeposit(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-to-bank`, obj)
      .pipe(catchError(this.handleError));
  }

  createBillingHeader(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/billing-header`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkProjectCreate(postData) {
    return this.http
      .post(`${environment.API_URL}/company/projects/bulkCreateProject`, postData)
      .pipe(catchError(this.handleError));
  }

  bulkClientsUpdate(postData) {
    return this.http
      .post(`${environment.API_URL}/company/clients/bulkUpdateClients`, postData)
      .pipe(catchError(this.handleError));
  }

  bulkCreateBillingHeader(postData) {
    return this.http
      .post(`${environment.API_URL}/company/billingHeader/bulkCreateBillingHeader`, postData)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateBillingHeader(postData) {
    return this.http
      .post(`${environment.API_URL}/company/billingHeader/bulkUpdateBillingHeader`, postData)
      .pipe(catchError(this.handleError));
  }

  createSendInBlueContacts(postData) {
    return this.http
      .post(`${environment.API_URL}/sendinblue/contacts`, postData)
      .pipe(catchError(this.handleError));
  }

  createPaymentHeader(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-header`, obj)
      .pipe(catchError(this.handleError));
  }

  insertLog(postData) {
    return this.http
      .post(`${environment.API_URL}//users/logs`, postData)
      .pipe(catchError(this.handleError));
  }

  createPaymentDetails(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-detail`, obj)
      .pipe(catchError(this.handleError));
  }

  getBillingHeaderRecordById(id: number) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/billing-header/single/${id}`)
      .pipe(catchError(this.handleError));
  }

  getContactsByClientId(id: number) {
    return this.http
      .get(`${environment.API_URL}/staff/contact/${id}`)
      .pipe(catchError(this.handleError));
  }

  getContactsByClientIdWithPermission(id: number) {
    return this.http
      .get(`${environment.API_URL}/staff/client-contact/${id}`)
      .pipe(catchError(this.handleError));
  }

  getContactsByCompanyId(id: number) {
    return this.http
      .get(`${environment.API_URL}/company/contacts/${id}`)
      .pipe(catchError(this.handleError));
  }

  getTimeSheet(companyId, userType, userId, startDate, endDate) {
    return this.http
      .get(`${environment.API_URL}/project/timeSheet/${userType}/${userId}/${startDate}/${endDate}`)
      .pipe(catchError(this.handleError));
  }

  getTimeCard(companyId, userType, userId, startDate, endDate) {
    return this.http
      .get(`${environment.API_URL}/company/timeSheet/${userType}/${userId}/${startDate}/${endDate}`)
      .pipe(catchError(this.handleError));
  }

  getPaymentHeaderById(billingHeaderID: number) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/payment-header/single/${billingHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  getClientsdocuments(customerNotesId: number) {
    return this.http
      .get(`${environment.API_URL}/staff/notes/docs/${customerNotesId}`)
      .pipe(catchError(this.handleError));
  }
  getNotes(clientId) {
    return this.http
      .get(`${environment.API_URL}/staff/notes/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  getContactType() {
    return this.http
      .get(`${environment.API_URL}/company/contactType`)
      .pipe(catchError(this.handleError));
  }

  getEmailNames(id, isStatement?) {
    if (!isStatement) isStatement = null;
    return this.http
      .get(`${environment.API_URL}/client/getEmailNames/${id}/${isStatement}`)
      .pipe(catchError(this.handleError));
  }

  getWorkLocations() {
    return this.http
      .get(`${environment.API_URL}/company/workLocations`)
      .pipe(catchError(this.handleError));
  }

  getScheduleData() {
    return this.http
      .get(`${environment.API_URL}/users/schedulePreference/null`)
      .pipe(catchError(this.handleError));
  }

  getInvoiceHistory2(id) {
    return this.http
      .get(`${environment.API_URL}/staff/invoice2/${id}`)
      .pipe(catchError(this.handleError));
  }

  getInvoiceHistory2Limit(id, limit) {
    return this.http
      .get(`${environment.API_URL}/staff/invoice2/${id}/${limit}`)
      .pipe(catchError(this.handleError));
  }

  getClientCustomerBalance(id) {
    return this.http
      .get(`${environment.API_URL}/staff/invoice2/${id}/customerBalance`)
      .pipe(catchError(this.handleError));
  }

  getSlipTotalsByProjectMasterId(projectMastersArray, staffId, endDate) {
    return this.http
      .get(
        `${environment.API_URL}/company/projects/getSlipTotalsByProjectMasterId/${projectMastersArray}/${staffId}/${endDate}`
      )
      .pipe(catchError(this.handleError));
  }

  getStaffInfo(staffID: number) {
    return this.http
      .get(`${environment.API_URL}/company/costMethod/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  getSlipsByProjectMasterId(projectMastersArray, staffId, endDate) {
    return this.http
      .get(
        `${environment.API_URL}/company/projects/getSlipsByProjectMasterId/${projectMastersArray}/${staffId}/${endDate}`
      )
      .pipe(catchError(this.handleError));
  }

  addNote(clientId, obj) {
    return this.http
      .post(`${environment.API_URL}/staff/notes/${clientId}`, obj)
      .pipe(catchError(this.handleError));
  }

  createEmailSignature(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/emailSignature`, obj)
      .pipe(catchError(this.handleError));
  }

  chargePaymentProfile(postData: any) {
    return this.http
      .post(`${environment.API_URL}/authorize/chargeCustomerProfile`, postData)
      .pipe(catchError(this.handleError));
  }

  updateNote(clientId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/notes/${clientId}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateEngagement(obj) {
    return this.http
      .put(`${environment.API_URL}/company/updateEngagement`, obj)
      .pipe(catchError(this.handleError));
  }

  updateBillingHeader(obj, billingHeaderID) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/billing-header/${billingHeaderID}`, obj)
      .pipe(catchError(this.handleError));
  }

  // full obj req.body
  updateSalesTaxInBillingHeader(obj, billingHeaderID) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/tax-billing-header/${billingHeaderID}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateBillingHeaderObj(obj, billingHeaderID) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/tax-billing-header/${billingHeaderID}`, obj)
      .pipe(catchError(this.handleError));
  }

  batchUpdateBillingHeader(obj) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/billing-header/bulkUpdate`, obj)
      .pipe(catchError(this.handleError));
  }

  updateEmailSignature(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/emailSignature/${obj['EmailSignatureID']}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteDMSParentFolder(id) {
    return this.http
      .delete(`${environment.API_URL}/dms/parentFolder/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateTimeSheet(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/timeSheet/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteNote(clientId) {
    return this.http
      .delete(`${environment.API_URL}/staff/notes/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  addDMSParentFolder(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/parentFolder/create`, obj)
      .pipe(catchError(this.handleError));
  }

  createBillingDetailsRecord(obj: any) {
    return this.http
      .post(`${environment.API_URL}/billingDetail/create`, obj)
      .pipe(catchError(this.handleError));
  }

  createTimeSheet(obj) {
    return this.http
      .post(`${environment.API_URL}/company/timeSheet`, obj)
      .pipe(catchError(this.handleError));
  }

  updateDMSParentFolder(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/parentFolder/${obj.dmsParentID}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateContact(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/contact/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  clearPreviewPrint() {
    return this.http
      .get(`${environment.API_URL}/company/setting/clearPreviewPrint`)
      .pipe(catchError(this.handleError));
  }

  getOpenRetainersById(clientID: number) {
    return this.http
      .get(`${environment.API_URL}/accounting/getOpenRetainersById/[${clientID}]`)
      .pipe(catchError(this.handleError));
  }

  SlipMasterbyCompanyIDLevel1(paraams) {
    return this.http
      .get(`${environment.API_URL}/company/batchbilling/SlipMasterbyCompanyIDLevel1/${paraams}`)
      .pipe(catchError(this.handleError));
  }

  updateSlipMasterbyCompanyIDLevel1(object: any) {
    return this.http
      .put(`${environment.API_URL}/company/batchbilling/SlipMasterbyCompanyIDLevel1/`, object)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getinvoiceReview() {
    return this.http
      .get(`${environment.API_URL}/company/setting/invoiceReview`)
      .pipe(catchError(this.handleError));
  }

  getTimeAndExpenses() {
    return this.http
      .get(`${environment.API_URL}/company/setting/timeAndExpenses`)
      .pipe(catchError(this.handleError));
  }

  loadDefaultClientViewAPI(clientId) {
    let clientsStats = this.http.get(`${environment.API_URL}/staff/getClientsStats/${clientId}`);
    let clientsPaymentStats = this.http.get(
      `${environment.API_URL}/staff/getClientPaymentStats/${clientId}`
    );
    let clientsRevenueStats = this.http.get(
      `${environment.API_URL}/staff/getClientRevenueStats/${clientId}`
    );
    let clientsWIPStats = this.http.get(
      `${environment.API_URL}/staff/getClientWIPStats/${clientId}`
    );
    let clientsOver = this.http.get(`${environment.API_URL}/staff/getClientOver30/${clientId}`);

    return forkJoin([
      clientsStats,
      clientsPaymentStats,
      clientsRevenueStats,
      clientsWIPStats,
      clientsOver
    ]);
  }

  loadClientSettings(clientId) {
    let apione = this.http.get(`${environment.API_URL}/staff/client/setting/${clientId}`);
    let apiTwo = this.http.get(`${environment.API_URL}/staff/salesTax`);
    let apiThree = this.http.get(`${environment.API_URL}/staff/client/rates/${clientId}`);
    let apiFour = this.http.get(`${environment.API_URL}/company/terms`);
    let apiFive = this.http.get(`${environment.API_URL}/company/setting/invoiceOption`);
    return forkJoin([apione, apiTwo, apiThree, apiFour, apiFive]);
  }

  loadInvoiceReview(companyID) {
    let apione = this.http.get(`${environment.API_URL}/company/setting/activityList/`);
    let apiTwo = this.http.get(`${environment.API_URL}/company/terms`);
    let apiThree = this.http.get(`${environment.API_URL}/company/setting/invoiceOption`);
    let apiFour = this.http.get(`${environment.API_URL}/company/setting/companyMango/${companyID}`);
    let apiFive = this.http.get(`${environment.API_URL}/company/setting/clearPreviewPrint`);
    return forkJoin([apione, apiTwo, apiThree, apiFour, apiFive]);
  }

  loadManualInvoice(companyID) {
    let apione = this.http.get(`${environment.API_URL}/company/terms`);
    let apiTwo = this.http.get(`${environment.API_URL}/company/setting/companyMango/${companyID}`);
    let apiThree = this.http.get(`${environment.API_URL}/company/setting/invoiceOption`);
    return forkJoin([apione, apiTwo, apiThree]);
  }

  loadSharedInvoiceSuperAdmin(companyID) {
    let apione = this.http.get(`${environment.API_URL}/super-admin/company/terms/${companyID}`);
    let apiTwo = this.http.get(
      `${environment.API_URL}/super-admin/company/setting/companyMango/${companyID}`
    );
    let apiThree = this.http.get(
      `${environment.API_URL}/super-admin/company/setting/invoiceOption/${companyID}`
    );
    return forkJoin([apione, apiTwo, apiThree]);
  }

  loadEngagementViewSettings(projectID, clientId, companyID) {
    let apione = this.http.get(`${environment.API_URL}/staff/client/rates/${clientId}`);
    let apiTwo = this.http.get(`${environment.API_URL}/staff/client/setting/${clientId}`);
    let apiThree = this.http.get(`${environment.API_URL}/company/terms`);
    let apiFour = this.http.get(
      `${environment.API_URL}/company/projects/getProjectsById/${projectID}`
    );
    let apiFive = this.http.get(`${environment.API_URL}/staff/contact/${clientId}`);
    let apiSix = this.http.get(`${environment.API_URL}/company/engagement/${companyID}`);
    return forkJoin([apione, apiTwo, apiThree, apiFour, apiFive, apiSix]);
  }

  // loadRecurringInvoice(companyID) {
  //     let apione = this.http.get(`${environment.API_URL}/company/engagement/${companyID}`);
  //     let apiTwo = this.http.get(`${environment.API_URL}/recurringHeader`);
  //     let apithree = this.http.get(`${environment.API_URL}/company/setting/activityList/`);

  //     let apiFour = this.http.get(`${environment.API_URL}/company/recurringInvoiceGroup`);
  //     // let apiFour = this.http.get(`${environment.API_URL}/company/projects/getProjectsById/${projectID}`);
  //     // let apiFive = this.http.get(`${environment.API_URL}/staff/contact/${clientId}`);

  //     return forkJoin([apione, apiTwo, apithree, apiFour])
  // }

  loadScheduling(companyID, staffID, dateStr, userId) {
    let apione = this.http.get(`${environment.API_URL}/staff/calendar/appointments/resourceList`);
    let apiTwo = this.http.get(`${environment.API_URL}/company/setting/companyMango/${companyID}`);
    let apiThree = this.http.get(
      `${environment.API_URL}/staff/outlook/getStaffRefreshTokens/${staffID}`
    );
    let apiFour = this.http.get(
      `${environment.API_URL}/staff/calendar/appointmentsByDate/${dateStr}`
    );
    let apiFive = this.http.get(`${environment.API_URL}/users/schedulePreference/null`);
    let apiSix = this.http.get(`${environment.API_URL}/staff/users/${userId}`);
    return forkJoin([apione, apiTwo, apiThree, apiFour, apiFive, apiSix]);
  }

  loadUpcomingEvents(dateStr) {
    return this.http.get(`${environment.API_URL}/staff/calendar/appointmentsByDate/${dateStr}`);
  }

  loadDefaultAssignmentSettings(companyId) {
    let apione = this.http.get(`${environment.API_URL}/company/terms`);
    let apiTwo = this.http.get(`${environment.API_URL}/company/engagement/${companyId}`);
    return forkJoin([apione, apiTwo]);
  }

  // this is availble through out the application
  loadDefaultAPI(staffId) {
    let clientCount = this.http.get(`${environment.API_URL}/staff/clientCount`);
    let clientGroupCategory = this.http.get(`${environment.API_URL}/company/customerGroupCategory`);
    let staffList = this.http.get(`${environment.API_URL}/staff/getAllStaffList`);
    //let dmsStaffList = this.http.get(`${environment.API_URL}/ddm/getDDMStaffList`); getAllStaffList both are same
    let customerType = this.http.get(`${environment.API_URL}/company/customerType`);
    let shotHands = this.http.get(`${environment.API_URL}/company/shotHands`);
    let timeAndExpenses = this.http.get(`${environment.API_URL}/company/setting/timeAndExpenses`);
    let activityList = this.http.get(`${environment.API_URL}/company/setting/activities`);
    let expenses = this.http.get(`${environment.API_URL}/company/setting/expenses`);
    let invoiceOption = this.http.get(`${environment.API_URL}/company/setting/invoiceOption`);
    let noteCategories = this.http.get(`${environment.API_URL}/staff/noteCategories`);
    let planSubscription = this.http.get(`${environment.API_URL}/company/planSubscription`);
    let staffPermission = this.http.get(`${environment.API_URL}/staff/permission/${staffId}`);
    let emailTemplates = this.http.get(`${environment.API_URL}/dms/emailTemplates/dms`);
    let workLocations = this.http.get(`${environment.API_URL}/company/workLocations`);
    let staffListAll = this.http.get(`${environment.API_URL}/staff/getAllStaffList/all`);
    let userPermissions = this.http.get(`${environment.API_URL}/user-permissions/${staffId}`);

    return forkJoin([
      clientCount,
      clientGroupCategory,
      staffList,
      customerType,
      shotHands,
      timeAndExpenses,
      activityList,
      expenses,
      invoiceOption,
      noteCategories,
      planSubscription,
      staffPermission,
      emailTemplates,
      workLocations,
      staffListAll,
      userPermissions
    ]);
  }

  loadDefaultUserDropDownList() {
    let staffTitles = this.http.get(`${environment.API_URL}/company/staffTitles`);
    let staffDepartment = this.http.get(`${environment.API_URL}/company/staffDepartment`);
    return forkJoin([staffTitles, staffDepartment]);
  }

  getAppointmentsByDate(dateStr) {
    return this.http
      .get(`${environment.API_URL}/staff/calendar/appointmentsByDate/${dateStr}`)
      .pipe(catchError(this.handleError));
  }

  getFavReportsById() {
    return this.http
      .get(`${environment.API_URL}/reports/getFavReports/`)
      .pipe(catchError(this.handleError));
  }

  getALLReports() {
    return this.http.get(`${environment.API_URL}/reports/all`).pipe(catchError(this.handleError));
  }

  getLastInvoiceInfo() {
    return this.http
      .get(`${environment.API_URL}/company/setting/invoiceOption`)
      .pipe(catchError(this.handleError));
  }

  SlipMasterbyCompanyIDLevel1New(clientId, staffId, projectMasterId, dDate) {
    return this.http
      .get(
        `${environment.API_URL}/company/manualInvoice/SlipMasterbyCompanyIDLevel1New/${clientId}/${staffId}/${projectMasterId}/${dDate}`
      )
      .pipe(catchError(this.handleError));
  }

  getUserById(id) {
    return this.http
      .get(`${environment.API_URL}/staff/users/${id}`)
      .pipe(catchError(this.handleError));
  }

  getUserReports() {
    return this.http
      .get(`${environment.API_URL}/reports/getUserReports`)
      .pipe(catchError(this.handleError));
  }

  addFavorite(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/addReportAsFavorite`, obj)
      .pipe(catchError(this.handleError));
  }

  addUserFavourite(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/addUserToFavorite`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteFavorite(reportId) {
    return this.http
      .delete(`${environment.API_URL}/reports/removeReportFromFavorite/${reportId}`)
      .pipe(catchError(this.handleError));
  }

  removeUserFavourite(reportId) {
    return this.http
      .delete(`${environment.API_URL}/reports/removeUserFromFavorite/${reportId}`)
      .pipe(catchError(this.handleError));
  }

  updateClient(data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/staff/client/${data.ClientID}`, data)
      .pipe(catchError(this.handleError));
  }

  resetSystemWarningLock(companyId): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/staff/reset-locking/${companyId}`, {})
      .pipe(catchError(this.handleError));
  }

  addClient(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/client`, obj)
      .pipe(catchError(this.handleError));
  }

  sendSMTPEmail(obj) {
    return this.http
      .post(`${environment.API_URL}/sendinblue/email`, obj)
      .pipe(catchError(this.handleError));
  }

  sendSMTPEmailList(obj: Array<any>) {
    return this.http
      .post(`${environment.API_URL}/sendinblue/emaillist`, obj)
      .pipe(catchError(this.handleError));
  }

  async saveFileIntoAWS(url: string) {
    const headers = new HttpHeaders({
      Accept: 'text/html',
      'Content-Type': 'text/html;charset=UTF-8'
    });

    return this.http.get(url, { headers }).pipe(catchError(this.handleError)).toPromise();
  }

  createOrUpdateUser(obj) {
    return this.http
      .post(`${environment.API_URL}/sendinblue/create-or-update-user`, obj)
      .pipe(catchError(this.handleError));
  }

  getEmailLogs(events) {
    return this.http
      .get(`${environment.API_URL}/sendinblue/email-client-logs/${events}`)
      .pipe(catchError(this.handleError));
  }

  tokenizedPaymentsACHCard(obj) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .post(`${environment.SINGULAR_BILLPAY_API_PAYMENTS_URL}/SubmitTokenPayment`, obj, options)
      .pipe(catchError(this.handleError));
  }

  createCustomerCC(obj) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .post(`${environment.SINGULAR_BILLPAY_API_PAYMENTS_URL}/SubmitCCPayment`, obj, options)
      .pipe(catchError(this.handleError));
  }

  createACHCard(obj) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .post(`${environment.SINGULAR_BILLPAY_API_PAYMENTS_URL}/SubmitACHPayment`, obj, options)
      .pipe(catchError(this.handleError));
  }

  saveProfileIDinMango(obj) {
    return this.http
      .post(`${environment.API_URL}/authorize/createSingularCustomerProfile`, obj)
      .pipe(catchError(this.handleError));
  }

  saveCustomerProfile(postData: any) {
    return this.http
      .post(`${environment.API_URL}/authorize/createCustomerProfile`, postData)
      .pipe(catchError(this.handleError));
  }

  savePaymentProfile(postData: any) {
    return this.http
      .post(`${environment.API_URL}/authorize/createCustomerPaymentProfile`, postData)
      .pipe(catchError(this.handleError));
  }

  updateTimeAndExpenses(obj): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/company/setting/timeAndExpenses`, obj)
      .pipe(catchError(this.handleError));
  }

  updateProfileIDinMango(postData: any) {
    return this.http
      .put(
        `${environment.API_URL}/authorize/updateSingularCustomerProfile/${postData.CustomerCardID}`,
        postData
      )
      .pipe(catchError(this.handleError));
  }

  updatePaymentHeader(obj, paymentDetailID) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-header/${paymentDetailID}`, obj)
      .pipe(catchError(this.handleError));
  }

  handleOpenRetainer(obj, paymentDetailID, openInvoices) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-header/openRetainer/${paymentDetailID}`, {
        obj: obj,
        openInvoices: openInvoices
      })
      .pipe(catchError(this.handleError));
  }

  updateCustomerPortalSettings(cid, obj): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/company/setting/customerPortal/${cid}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateProject(obj) {
    return this.http
      .put(`${environment.API_URL}/company/projects/updateProject/${obj['ProjectMasterID']}`, obj)
      .pipe(catchError(this.handleError));
  }

  addActivity(obj) {
    return this.http
      .post(`${environment.API_URL}/company/setting/activities`, obj)
      .pipe(catchError(this.handleError));
  }

  updateActivity(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/activities/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteActivity(id) {
    return this.http
      .delete(`${environment.API_URL}/company/setting/activities/${id}`)
      .pipe(catchError(this.handleError));
  }

  getBillingHeaderById(id) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/billing-header/single/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteUserFromPlanSubscription(licensesToRemove: number) {
    return this.http
      .delete(`${environment.API_URL}/company/planSubscription/user`, {
        params: { licensesToRemove: licensesToRemove.toString() }
      })
      .pipe(catchError(this.handleError));
  }

  updatePlanForCompany(planId) {
    return this.http
      .put(`${environment.API_URL}/company/planSubscription`, { userPlanId: planId })
      .pipe(catchError(this.handleError));
  }

  addUserToSubscribedPlan(postData) {
    return this.http
      .post(`${environment.API_URL}/company/planSubscription/user`, postData)
      .pipe(catchError(this.handleError));
  }

  updateUserToSubscribedPlan(postData) {
    return this.http
      .put(`${environment.API_URL}/company/planSubscription/user`, postData)
      .pipe(catchError(this.handleError));
  }

  subscribePlanForCompany(obj) {
    return this.http
      .post(`${environment.API_URL}/company/planSubscription`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteUser(id) {
    return this.http
      .delete(`${environment.API_URL}/staff/users/${id}/false`)
      .pipe(catchError(this.handleError));
  }

  deleteProfile(clientId) {
    return this.http
      .delete(`${environment.API_URL}/authorize/deleteProfile/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  deleteAppointmentResource(id) {
    return this.http
      .delete(`${environment.API_URL}/staff/calendar/appointmentsResource/${id}`)
      .pipe(catchError(this.handleError));
  }

  getActivities() {
    return this.http
      .get(`${environment.API_URL}/company/setting/activities`)
      .pipe(catchError(this.handleError));
  }

  deleteAllInvoicesPaymentTime(companyId: number) {
    return this.http
      .delete(`${environment.API_URL}/deleteAllInvoicesPaymentTime/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  getInvoices(id) {
    return this.http
      .get(`${environment.API_URL}/staff/invoice/${id}`)
      .pipe(catchError(this.handleError));
  }

  getClientInvoices(id) {
    return this.http
      .get([`${environment.API_URL}`, `staff/client-invoice`, `${id}`].join('/'))
      .pipe(catchError(this.handleError));
  }

  getClientNameByStaxId(companyId, id) {
    return this.http
      .get(
        `${environment.API_URL}/cash-receipts/billing-header/getClientNameByStaxId/${companyId}/${id}`
      )
      .pipe(catchError(this.handleError));
  }

  getPaymentProfile(clientID: number, transType: string) {
    return this.http
      .get(`${environment.API_URL}/authorize/cardDetails/${clientID}/${transType}`)
      .pipe(catchError(this.handleError));
  }

  getDataByInvoiceNo(invoiceNo) {
    return this.http
      .get(`${environment.API_URL}/cashReceipts/getDataByInvoiceNo/${invoiceNo}`)
      .pipe(catchError(this.handleError));
  }

  getRateTemplates() {
    return this.http
      .get(`${environment.API_URL}/company/rateTemplates`)
      .pipe(catchError(this.handleError));
  }

  getBillingRate(staffId, clientId, serviceCodeId) {
    return this.http
      .get(`${environment.API_URL}/company/getBillingRate/${staffId}/${clientId}/${serviceCodeId}`)
      .pipe(catchError(this.handleError));
  }

  getBillingRateFromRateTemplate(clientId, serviceCodeId, staffId) {
    return this.http
      .get(
        `${environment.API_URL}/company/getBillingRateFromRateTemplate/${clientId}/${serviceCodeId}/${staffId}`
      )
      .pipe(catchError(this.handleError));
  }

  getPlanInformation() {
    return this.http
      .get(`${environment.API_URL}/company/planSubscription`)
      .pipe(catchError(this.handleError));
  }

  getSettingData(id) {
    return this.http
      .get(`${environment.API_URL}/staff/client/setting/${id}`)
      .pipe(catchError(this.handleError));
  }

  getSettingDataSuperAdmin(id) {
    return this.http
      .get(`${environment.API_URL}/super-admin/client/setting/${id}`)
      .pipe(catchError(this.handleError));
  }

  saveSettingData(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/setting/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  saveSettingDataSuperAdmin(id, obj) {
    return this.http
      .put(`${environment.API_URL}/super-admin/staff/client/setting/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateLaserSoftwareUpdate(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/setting/lasersoftware/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  reverseInvoice(billingHeaderID, isPreviewMode?) {
    let obj = { billingHeaderId: billingHeaderID, isPreviewMode };
    return this.http
      .put(`${environment.API_URL}/staff/invoiceReverse`, obj)
      .pipe(catchError(this.handleError));
  }

  reverseLateFee(lateFeeHeaderID, invoiceAmount) {
    let obj = { lateFeeHeaderID, invoiceAmount };
    return this.http
      .post(`${environment.API_URL}/invoice/reverse-late-fee`, obj)
      .pipe(catchError(this.handleError));
  }

  batchReverseInvoice(billingHeaderIDArray) {
    let obj = { billingHeaderIDArray: billingHeaderIDArray };
    return this.http
      .put(`${environment.API_URL}/staff/reverseMultipleInvoices`, obj)
      .pipe(catchError(this.handleError));
  }

  clearPreviewModeInvoices(companyId) {
    return this.http
      .put(`${environment.API_URL}/invoice/clearPreviewModeInvoices/${companyId}`, {})
      .pipe(catchError(this.handleError));
  }

  approveExpenseRecord(obj) {
    return this.http
      .put(`${environment.API_URL}/company/approveExpenseRecord`, obj)
      .pipe(catchError(this.handleError));
  }

  getTimeRecordsByServiceCodeId(id) {
    return this.http
      .get(`${environment.API_URL}/company/timeRecords/${id}`)
      .pipe(catchError(this.handleError));
  }

  getBanks() {
    return this.http.get(`${environment.API_URL}/company/banks`).pipe(catchError(this.handleError));
  }

  updateBanks(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/banks/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  //:@deprecate-stripe;
  /*;
    addNewCard(postData) {
        return this.http.post(`${environment.API_URL}/stripe/addNewCard`, postData).pipe(catchError(this.handleError));
    }

    getCardDetails() {
        return this.http.get(`${environment.API_URL}/stripe/cardDetails`).pipe(catchError(this.handleError));
    }

    getTransactions() {
        return this.http.get(`${environment.API_URL}/stripe/transactions`).pipe(catchError(this.handleError));
    }

    updateSubscriptionOnStripe(postData) {
        return this.http.post(`${environment.API_URL}/stripe/updateSubscription`, postData).pipe(catchError(this.handleError));
    }

    deactivateSubsOnStripe() {
        return this.http.delete(`${environment.API_URL}/stripe/deleteSubscription`).pipe(catchError(this.handleError));
    }
    */

  deactivateCompanyProfile() {
    return this.http
      .put(`${environment.API_URL}/company/deactivate`, {})
      .pipe(catchError(this.handleError));
  }

  bulkTimeSheetSave(companyId, postData) {
    return this.http
      .post(`${environment.API_URL}/company/timeSheet/bulkSave`, postData)
      .pipe(catchError(this.handleError));
  }

  updateBillingRateProfiles(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/rateTemplates/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateBillingRateActivityProfiles(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/activityCodes/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  addBillingRateActivityProfiles(obj) {
    return this.http
      .post(`${environment.API_URL}/company/activityCodes/`, obj)
      .pipe(catchError(this.handleError));
  }

  addBillingRateProfiles(obj) {
    return this.http
      .post(`${environment.API_URL}/company/rateTemplates/`, obj)
      .pipe(catchError(this.handleError));
  }

  saveBanks(obj) {
    return this.http
      .post(`${environment.API_URL}/company/banks`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteBanks(id) {
    return this.http
      .delete(`${environment.API_URL}/company/banks/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteBillingRateActivityStatus(id) {
    return this.http
      .delete(`${environment.API_URL}/company/activityCodes/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteBillingRateStatus(id, flag) {
    return this.http
      .delete(`${environment.API_URL}/company/rateTemplates/${id}/${flag}`)
      .pipe(catchError(this.handleError));
  }

  getProjects() {
    return this.http
      .get(`${environment.API_URL}/company/projects/getProjectsByCompany`)
      .pipe(catchError(this.handleError));
  }

  getProjects2(showInactive, searchValue, lastId, limit) {
    return this.http
      .get(
        `${environment.API_URL}/company/projects/getProjectsByCompanyNoDetails/${showInactive}/${searchValue}/${lastId}/${limit}`
      )
      .pipe(catchError(this.handleError));
  }

  getEngagementCount(CompanyId) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getProjectCountPerCompany/${CompanyId}`)
      .pipe(catchError(this.handleError));
  }

  getProjectsByClientId(clientId) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getProjects/${clientId}`)
      .pipe(catchError(this.handleError));
  }


  getProjectsAndEngagementByClientID(clientId) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getProjectsAndEngagementByClientID/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  getListOfUsers() {
    return this.http.get(`${environment.API_URL}/staff/users`).pipe(catchError(this.handleError));
  }

  getExpenseMasterList(billingHeaderId) {
    return this.http
      .get(
        `${environment.API_URL}/company/projects/getALLExpensesByBillingheaderID/${billingHeaderId}`
      )
      .pipe(catchError(this.handleError));
  }

  getExpenseMasterListSuperAdmin(billingHeaderId) {
    return this.http
      .get(
        `${environment.API_URL}/super-admin/company/projects/getALLExpensesByBillingheaderID/${billingHeaderId}`
      )
      .pipe(catchError(this.handleError));
  }

  getBillingDetailRecords(id) {
    return this.http
      .get([`${environment.API_URL}`, `billingDetail/getBillingDetailRecords`, `${id}`].join('/'))
      .pipe(catchError(this.handleError));
  }

  getBillingDetailRecordsSuperAdmin(id) {
    return this.http
      .get(
        [
          `${environment.API_URL}`,
          `super-admin/billingDetail/getBillingDetailRecords`,
          `${id}`
        ].join('/')
      )
      .pipe(catchError(this.handleError));
  }

  timeSlipsParentByClientId(id) {
    return this.http
      .get(`${environment.API_URL}/project/timeSlipsParentByClientId/${id}`)
      .pipe(catchError(this.handleError));
  }

  timeSlipsChildByClientId(id) {
    return this.http
      .get(`${environment.API_URL}/project/timeSlipsChildByClientId/${id}`)
      .pipe(catchError(this.handleError));
  }

  getSlipMasterList(startDate, endDate, projectMasterId, staffId, clientId) {
    return this.http
      .get(
        `${environment.API_URL}/project/searchData/${startDate}/${endDate}/${projectMasterId}/${staffId}/${clientId}`
      )
      .pipe(catchError(this.handleError));
  }

  getDMSFilesByClientId(id) {
    return this.http
      .get(`${environment.API_URL}/dms/getDMSFiles/${id}`)
      .pipe(catchError(this.handleError));
  }

  getGlobalTemplateNames() {
    return this.http
      .get(`${environment.API_URL}/dms/globalTemplateNames`)
      .pipe(catchError(this.handleError));
  }

  getGlobalTemplate(id) {
    return this.http
      .get(`${environment.API_URL}/dms/globalTemplate/${id}`)
      .pipe(catchError(this.handleError));
  }

  clientTemplateNames() {
    return this.http
      .get(`${environment.API_URL}/dms/clientTemplateNames`)
      .pipe(catchError(this.handleError));
  }

  getClientTemplate(id) {
    return this.http
      .get(`${environment.API_URL}/dms/clientTemplate/${id}`)
      .pipe(catchError(this.handleError));
  }

  getEmailTemplates(typeOfTemplate) {
    return this.http
      .get(`${environment.API_URL}/dms/emailTemplates/${typeOfTemplate}`)
      .pipe(catchError(this.handleError));
  }

  getEmailTemplateByTypeAndCompany(typeOfTemplate, companyId) {
    return this.http
      .get(`${environment.API_URL}/dms/emailTemplate/${typeOfTemplate}/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  getTimeRecordsByprojectMasterID(projectMasterID) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getTimeRecords/${projectMasterID}`)
      .pipe(catchError(this.handleError));
  }

  updateNewUserDefaults(
    uniqueId,
    defaultPermissionObj,
    defaultUserRatesObj,
    defaultcostMethodObj,
    defaultScheduleObj
  ) {
    let apione = this.http.put(
      `${environment.API_URL}/staff/permission/${uniqueId}`,
      defaultPermissionObj
    );
    let apiTwo = this.http.put(
      `${environment.API_URL}/user/rates/${uniqueId}`,
      defaultUserRatesObj
    );
    let apiThree = this.http.put(
      `${environment.API_URL}/company/costMethod/${uniqueId}`,
      defaultcostMethodObj
    );
    let apiFour = this.http.post(
      `${environment.API_URL}/users/schedulePreference/${uniqueId}`,
      defaultScheduleObj
    );
    return forkJoin([apione, apiTwo, apiThree, apiFour]);
  }

  getFilesAndContacts(clientId) {
    let apione = this.http.get(`${environment.API_URL}/staff/contact/${clientId}`);
    let apiTwo = this.http.get(`${environment.API_URL}/dms/getDMSFiles/${clientId}`);
    return forkJoin([apione, apiTwo]);
  }

  getUniqueIdFromResourceId(id) {
    return this.http
      .get(`${environment.API_URL}/staff/calendar/appointmentsResource/${id}`)
      .pipe(catchError(this.handleError));
  }

  getcostMethodData(id) {
    return this.http
      .get(`${environment.API_URL}/company/costMethod/${id}`)
      .pipe(catchError(this.handleError));
  }

  getUserRatesData(id) {
    return this.http
      .get(`${environment.API_URL}/user/rates/${id}`)
      .pipe(catchError(this.handleError));
  }

  createAppointmentResource(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/calendar/appointmentsResource`, obj)
      .pipe(catchError(this.handleError));
  }

  createUser(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/users/`, obj)
      .pipe(catchError(this.handleError));
  }

  updateAppointmentResource(obj) {
    return this.http
      .put(`${environment.API_URL}/staff/calendar/appointmentsResource/${obj['UniqueID']}`, obj)
      .pipe(catchError(this.handleError));
  }

  updatecostMethodDataById(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/costMethod/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateUserRatesDataById(id, obj) {
    return this.http
      .put(`${environment.API_URL}/user/rates/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  addSchedulePreference(id, obj) {
    return this.http
      .post(`${environment.API_URL}/users/schedulePreference/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  getEducationData(id) {
    return this.http
      .get(`${environment.API_URL}/users/education/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateEducationData(id, obj) {
    return this.http
      .put(`${environment.API_URL}/users/education/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteEducationData(id) {
    return this.http
      .delete(`${environment.API_URL}/users/education/${id}`)
      .pipe(catchError(this.handleError));
  }

  createEducationDataUser(id, obj) {
    return this.http
      .post(`${environment.API_URL}/users/education/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  getSchedulePreference(id) {
    return this.http
      .get(`${environment.API_URL}/users/schedulePreference/${id}`)
      .pipe(catchError(this.handleError));
  }

  getActivityList() {
    return this.http
      .get(`${environment.API_URL}/company/setting/activityList/`)
      .pipe(catchError(this.handleError));
  }

  updateSchedulePreferenceById(id, obj) {
    return this.http
      .put(`${environment.API_URL}/users/schedulePreference/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  loadInvoiceDefaults() {
    let apione = this.http.get(`${environment.API_URL}/company/terms`);
    let apiTwo = this.http.get(`${environment.API_URL}/company/setting/invoiceOption`);
    let apiThree = this.http.get(`${environment.API_URL}/company/setting/timeAndExpenses`);
    return forkJoin([apione, apiTwo, apiThree]);
  }

  loadHistoryDefaults(clientId, projectID) {
    let apione = this.http.get(`${environment.API_URL}/company/terms`);
    let apiTwo = this.http.get(`${environment.API_URL}/staff/client/setting/${clientId}`);
    let apithree = this.http.get(`${environment.API_URL}/company/setting/activityList/`);
    let apiFour = this.http.get(
      `${environment.API_URL}/company/projects/getInvoicesByProjectMasterId/${projectID}`
    );
    return forkJoin([apione, apiTwo, apithree, apiFour]);
  }

  loadListDefaults(companyId) {
    let apione = this.http.get(`${environment.API_URL}/company/terms`);
    let apiTwo = this.http.get(`${environment.API_URL}/company/noteCategories`);
    let apiThree = this.http.get(`${environment.API_URL}/company/staffTitles`);
    let apiFour = this.http.get(`${environment.API_URL}/company/staffDepartment`);
    let apiFive = this.http.get(`${environment.API_URL}/company/contactType`);
    let apiSix = this.http.get(`${environment.API_URL}/company/customerGroupCategory`);
    let apiSeven = this.http.get(`${environment.API_URL}/company/recurringInvoiceGroup`);
    let apiEight = this.http.get(`${environment.API_URL}/company/engagement/${companyId}`);
    let apiNine = this.http.get(`${environment.API_URL}/company/workLocations`);
    let apiTen = this.http.get(`${environment.API_URL}/company/customerType`);
    let apiElev = this.http.get(
      `${environment.API_URL}/company/companypaymentmethods/${companyId}`
    );
    return forkJoin([
      apione,
      apiTwo,
      apiThree,
      apiFour,
      apiFive,
      apiSix,
      apiSeven,
      apiEight,
      apiNine,
      apiTen,
      apiElev
    ]);
  }

  loadReportsDefaults(companyId) {
    let apione = this.http.get(`${environment.API_URL}/reports/all`);
    let apiTwo = this.http.get(`${environment.API_URL}/reports/getFavReports/`);
    let apiThree = this.http.get(`${environment.API_URL}/reports/getUserReports`);
    let apiFour = this.http.get(`${environment.API_URL}/company/engagement/${companyId}`);
    let apiFive = this.http.get(`${environment.API_URL}/company/locations/${companyId}`);
    let apiSix = this.http.get(`${environment.API_URL}/company/staffDepartment`);
    return forkJoin([apione, apiTwo, apiThree, apiFour, apiFive, apiSix]);
  }

  saveInvoice(obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/invoiceOption`, obj)
      .pipe(catchError(this.handleError));
  }

  getRecurringHeader() {
    return this.http
      .get(`${environment.API_URL}/recurringHeader`)
      .pipe(catchError(this.handleError));
  }

  updateRecurringInvoiceHeader(headerId, obj, updateDetailStatus?) {
    if (typeof updateDetailStatus == 'undefined') {
      updateDetailStatus = true;
    }

    return this.http
      .put(
        [
          `${environment.API_URL}/recurringInvoice/header/updateHeader/${headerId}`,
          [`updateDetailStatus=${updateDetailStatus}`].join('&')
        ].join('?'),
        obj
      )
      .pipe(catchError(this.handleError));
  }

  getRecurringInvoiceDetailsByHeaderId(headerId) {
    return this.http
      .get(`${environment.API_URL}/recurringInvoice/detail/getDetailByHeaderId/${headerId}`)
      .pipe(catchError(this.handleError));
  }

  deleteRecuringInvoiceDetail(recurringInvoiceDetailID) {
    return this.http
      .delete(`${environment.API_URL}/recurringInvoice/detail/${recurringInvoiceDetailID}`)
      .pipe(catchError(this.handleError));
  }

  createInvoiceHeader(obj) {
    return this.http
      .post(`${environment.API_URL}/recurringInvoice/header/createHeader`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteRecuringInvoiceExpense(recurringInvoiceExpenseID) {
    return this.http
      .delete(`${environment.API_URL}/recurringInvoice/expense/${recurringInvoiceExpenseID}`)
      .pipe(catchError(this.handleError));
  }

  createRecuringInvoiceExpense(obj) {
    return this.http
      .post(`${environment.API_URL}/recurringInvoice/expense/createExpense`, obj)
      .pipe(catchError(this.handleError));
  }

  createRecuringInvoiceDetail(obj) {
    return this.http
      .post(`${environment.API_URL}/recurringInvoice/detail/createDetail`, obj)
      .pipe(catchError(this.handleError));
  }

  updateRecuringInvoiceDetail(recurringInvoiceDetailId, obj) {
    return this.http
      .put(
        `${environment.API_URL}/recurringInvoice/detail/updateDetail/${recurringInvoiceDetailId}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  updateRecuringInvoiceExpense(recurringInvoiceExpenseId, obj) {
    return this.http
      .put(
        `${environment.API_URL}/recurringInvoice/expense/updateExpense/${recurringInvoiceExpenseId}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  getRecurringInvoiceExpensesByHeaderId(headerId) {
    return this.http
      .get(
        [
          `${environment.API_URL}`,
          `recurringInvoice/expense/getExpenseByHeaderId`,
          `${headerId}`
        ].join('/')
      )
      .pipe(catchError(this.handleError));
  }

  getRecurringInvoiceExpensesByHeaderIdCount(headerId) {
    return this.http
      .get(`${environment.API_URL}/recurringInvoice/expense/getExpenseByHeaderId/count/${headerId}`)
      .pipe(catchError(this.handleError));
  }

  getRecurringHeader1(companyId, query?) {
    let queryParams = `CompanyID=${companyId}`;

    if (query) {
      queryParams += `&${query}`;
    }

    return this.http
      .get(
        [
          [`${environment.API_URL}`, `recurringInvoice/header/getHeaderByCompanyId1`].join('/'),
          `${queryParams}`
        ].join('?')
      )
      .pipe(catchError(this.handleError));
  }

  recurringHeaderBulkUpdate(obj) {
    return this.http
      .put(`${environment.API_URL}/recurringInvoice/header/bulkUpdate`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateReleaseDates(obj) {
    return this.http
      .put(`${environment.API_URL}/recurringInvoice/detail/bulkUpdate`, obj)
      .pipe(catchError(this.handleError));
  }

  getRecurringInvoiceDetailsTimeRecordsParent(headerId, companyId) {
    return this.http
      .get(
        [
          `${environment.API_URL}`,
          `recurringInvoice/detail/timeRecordsParent`,
          `${headerId}`,
          `${companyId}`
        ].join('/')
      )
      .pipe(catchError(this.handleError));
  }

  getRecurringInvoiceDetailsTimeRecordsParentCount(headerId, companyId) {
    return this.http
      .get(
        `${environment.API_URL}/recurringInvoice/detail/timeRecordsParent/count/${headerId}/${companyId}`
      )
      .pipe(catchError(this.handleError));
  }

  getRecurringInvoiceEngagmentTimeRecords(
    headerId,
    companyId,
    projectMasterId,
    recurringInvoiceDetailId
  ) {
    return this.http
      .get(
        `${environment.API_URL}/recurringInvoice/detail/timeRecords/${headerId}/${companyId}/${projectMasterId}/${recurringInvoiceDetailId}`
      )
      .pipe(catchError(this.handleError));
  }

  deleteRecurringHeader(recurringHeaderID) {
    return this.http
      .delete(`${environment.API_URL}/recurringHeader/${recurringHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  deleteRecurringInvoiceHeader(recurringHeaderID) {
    return this.http
      .delete(`${environment.API_URL}/recurringInvoice/header/${recurringHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  saveRecurringHeader(obj) {
    return this.http
      .put(
        `${environment.API_URL}/company/setting/updateRecurringHeader/${obj['RecurringHeaderID']}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  createRecurringHeader(obj) {
    return this.http
      .post(`${environment.API_URL}/company/setting/createRecurringHeader`, obj)
      .pipe(catchError(this.handleError));
  }

  updateTopBottomMemoRecurringHeader(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/updateTopBottomMemoRecurringHeader/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  getTimeSheetsByClientId(clientId, serviceCode, startDate, endDate) {
    return this.http
      .get(
        `${environment.API_URL}/slipMaster/searchData/${clientId}/${serviceCode}/${startDate}/${endDate}`
      )
      .pipe(catchError(this.handleError));
  }

  getExpenses() {
    return this.http
      .get(`${environment.API_URL}/company/setting/expenses`)
      .pipe(catchError(this.handleError));
  }

  updateExpenses(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/expenses/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteExpenses(id) {
    return this.http
      .delete(`${environment.API_URL}/company/setting/expenses/${id}/false`)
      .pipe(catchError(this.handleError));
  }

  forceDeleteExpenses(id) {
    return this.http
      .delete(`${environment.API_URL}/company/setting/expenses/${id}/true`)
      .pipe(catchError(this.handleError));
  }

  saveExpenses(obj) {
    return this.http
      .post(`${environment.API_URL}/company/setting/expenses`, obj)
      .pipe(catchError(this.handleError));
  }

  getQuickbookIntegration() {
    return this.http
      .get(`${environment.API_URL}/company/quickbookIntegration`)
      .pipe(catchError(this.handleError));
  }

  getUserCredentails(userId) {
    return this.http
      .get(`${environment.API_URL}/staff/QB/users/${userId}`)
      .pipe(catchError(this.handleError));
  }

  getEngagementTypes(companyID) {
    return this.http
      .get(`${environment.API_URL}/company/engagement/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getALLExpenses(projectMasterID) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getALLExpenses/${projectMasterID}`)
      .pipe(catchError(this.handleError));
  }
  getExpensesByBilled(projectMasterID) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getExpensesByBilled/${projectMasterID}`)
      .pipe(catchError(this.handleError));
  }

  getClientsAddress(clientId) {
    return this.http
      .get(`${environment.API_URL}/staff/contact/clientAddress/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  getContactsByProjectId(projectMasterID) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getContacts/${projectMasterID}`)
      .pipe(catchError(this.handleError));
  }

  saveQuickbookIntegration(obj) {
    return this.http
      .put(`${environment.API_URL}/company/quickbookIntegration`, obj)
      .pipe(catchError(this.handleError));
  }

  getQBErrors(email: string, page: number, size: number): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams()
      .set('email', email)
      .set('page', page.toString())
      .set('size', size.toString());

    return this.http
      .get<HttpResponse<any>>(`${environment.QB_ENDPOINT_URL}getErrorMessage`, {
        headers: headers,
        params: params,
        observe: 'response'
      })
      .pipe(catchError(this.handleError));
  }

  getQBErrorDetails(errorCode: string): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().set('errorCode', errorCode);

    return this.http
      .get<HttpResponse<any>>(`${environment.QB_ENDPOINT_URL}getErrorDetails`, {
        headers: headers,
        params: params,
        observe: 'response'
      })
      .pipe(catchError(this.handleError));
  }

  updateDataStore(dataStoreId: string): Observable<HttpResponse<string>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const httpOptions = {
      headers: headers,
      observe: 'response' as 'response',
      responseType: 'text' as 'text'
    };

    return this.http.put(
      `${environment.QB_ENDPOINT_URL}updateDataStore/${dataStoreId}`,
      {},
      httpOptions
    );
  }

  getQBProfileData(email) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, method: 'GET' };
    return this.http
      .get(`${environment.QB_ENDPOINT_URL}getCompany/${email}`, options)
      .pipe(catchError(this.handleError));
  }

  addOrUpdateQB(obj) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .post(`${environment.QB_ENDPOINT_URL}addCompany`, obj, options)
      .pipe(catchError(this.handleError));
  }

  getQBAllowedModules(email): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, method: 'GET' };
    return this.http
      .get<HttpResponse<any>>(`${environment.QB_ENDPOINT_URL}getAllowedModulesList/${email}`, {
        headers: headers,
        observe: 'response'
      })
      .pipe(catchError(this.handleError));
  }

  saveModuleDateExclusion(exclusionForm: any): Observable<HttpResponse<string>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const httpOptions = {
      headers: headers,
      observe: 'response' as 'response',
      responseType: 'text' as 'text'
    };

    return this.http.post(
      `${environment.QB_ENDPOINT_URL}saveModuleExclusion`,
      exclusionForm.value,
      httpOptions
    );
  }

  addOrUpdateWebQB(obj) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .post(`${environment.QB_ENDPOINT_URL}addQBOCompany`, obj, options)
      .pipe(catchError(this.handleError));
  }

  getDefaultQBOItems(email: string): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().set('email', email);

    return this.http
      .get<HttpResponse<any>>(`${environment.QB_ENDPOINT_URL}qbo/getQBOItems`, {
        headers: headers,
        params: params,
        observe: 'response'
      })
      .pipe(catchError(this.handleError));
  }

  updateEngagements(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/engagement/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateNoteCategories(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/noteCategories/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateStaffTitles(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/staffTitles/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateStaffDept(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/staffDepartment/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateContactType(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/contactType/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateTerm(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/terms/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateGroup(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/customerGroupCategory/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateInvoiceGroup(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/recurringInvoiceGroup/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateCustomerType(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/customerType/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateUserEventType(eventTypeId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/eventType/${eventTypeId}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteUserEventType(eventTypeId) {
    return this.http
      .delete(`${environment.API_URL}/staff/eventType/${eventTypeId}`)
      .pipe(catchError(this.handleError));
  }

  deleteNoteCategories(id) {
    return this.http
      .delete(`${environment.API_URL}/company/noteCategories/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteStaffTitles(id) {
    return this.http
      .delete(`${environment.API_URL}/company/staffTitles/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteStaffDept(id) {
    return this.http
      .delete(`${environment.API_URL}/company/staffDepartment/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteContactType(id) {
    return this.http
      .delete(`${environment.API_URL}/company/contactType/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteCustomerType(id) {
    return this.http
      .delete(`${environment.API_URL}/company/customerType/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteTerm(id) {
    return this.http
      .delete(`${environment.API_URL}/company/terms/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteGroup(id) {
    return this.http
      .delete(`${environment.API_URL}/company/customerGroupCategory/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteInvoiceGroup(id) {
    return this.http
      .delete(`${environment.API_URL}/company/recurringInvoiceGroup/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteEngagement(id) {
    return this.http
      .delete(`${environment.API_URL}/company/engagement/${id}`)
      .pipe(catchError(this.handleError));
  }

  saveNoteCategories(obj) {
    return this.http
      .post(`${environment.API_URL}/company/noteCategories`, obj)
      .pipe(catchError(this.handleError));
  }

  saveStaffTitles(obj) {
    return this.http
      .post(`${environment.API_URL}/company/staffTitles`, obj)
      .pipe(catchError(this.handleError));
  }

  saveEngagements(obj) {
    return this.http
      .post(`${environment.API_URL}/company/engagement/`, obj)
      .pipe(catchError(this.handleError));
  }

  saveStaffDept(obj) {
    return this.http
      .post(`${environment.API_URL}/company/staffDepartment`, obj)
      .pipe(catchError(this.handleError));
  }

  saveContactType(obj) {
    return this.http
      .post(`${environment.API_URL}/company/contactType`, obj)
      .pipe(catchError(this.handleError));
  }

  saveTerm(obj) {
    return this.http
      .post(`${environment.API_URL}/company/terms`, obj)
      .pipe(catchError(this.handleError));
  }

  saveGroup(obj) {
    return this.http
      .post(`${environment.API_URL}/company/customerGroupCategory`, obj)
      .pipe(catchError(this.handleError));
  }

  saveInvoiceGroup(obj) {
    return this.http
      .post(`${environment.API_URL}/company/recurringInvoiceGroup`, obj)
      .pipe(catchError(this.handleError));
  }

  saveCustomerType(obj) {
    return this.http
      .post(`${environment.API_URL}/company/customerType`, obj)
      .pipe(catchError(this.handleError));
  }

  saveUserEventType(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/eventType`, obj)
      .pipe(catchError(this.handleError));
  }

  updateWorkLocations(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/workLocations/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteWorkLocations(id) {
    return this.http
      .delete(`${environment.API_URL}/company/workLocations/${id}`)
      .pipe(catchError(this.handleError));
  }

  addWorkLocations(obj) {
    return this.http
      .post(`${environment.API_URL}/company/workLocations`, obj)
      .pipe(catchError(this.handleError));
  }

  updatePaymentMethods(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/companypaymentmethods/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deletePaymentMethods(id) {
    return this.http
      .delete(`${environment.API_URL}/company/companypaymentmethods/${id}`)
      .pipe(catchError(this.handleError));
  }

  addPaymentMethods(obj) {
    return this.http
      .post(`${environment.API_URL}/company/companypaymentmethods`, obj)
      .pipe(catchError(this.handleError));
  }

  getPaymentMethods(companyId) {
    return this.http
      .get(`${environment.API_URL}/company/companypaymentmethods/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  getNoteCategories() {
    return this.http
      .get(`${environment.API_URL}/company/noteCategories`)
      .pipe(catchError(this.handleError));
  }

  getStaffTitles() {
    return this.http
      .get(`${environment.API_URL}/company/staffTitles`)
      .pipe(catchError(this.handleError));
  }

  getStaffDept() {
    return this.http
      .get(`${environment.API_URL}/company/staffDepartment`)
      .pipe(catchError(this.handleError));
  }

  getTerms() {
    return this.http.get(`${environment.API_URL}/company/terms`).pipe(catchError(this.handleError));
  }

  getGroups() {
    return this.http
      .get(`${environment.API_URL}/company/customerGroupCategory`)
      .pipe(catchError(this.handleError));
  }

  getCustomerType() {
    return this.http
      .get(`${environment.API_URL}/company/customerType`)
      .pipe(catchError(this.handleError));
  }

  getInvoiceGroups() {
    return this.http
      .get(`${environment.API_URL}/company/recurringInvoiceGroup`)
      .pipe(catchError(this.handleError));
  }

  getUserEventTypeList() {
    return this.http
      .get(`${environment.API_URL}/staff/eventType`)
      .pipe(catchError(this.handleError));
  }

  getEngagements(companyId) {
    return this.http
      .get(`${environment.API_URL}/company/engagement/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerEngagementTypesById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerEngagementTypesById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerRecurringInvoiceGroupById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/recurringInvoiceById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerNotesById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerNotesById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerContactsById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerContactsById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerClientTypesById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerClientTypesById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerTitlesById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerTitlesById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerDeptById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerDeptById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerGroupsById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerGroupsById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerGroupsByClientId(ClientID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerGroupByClientId/${ClientID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getCustomerStaffListById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerStaffListById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getCustomerClientTermsById(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/customerClientTermsById/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getContactDetails(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/staff/contactDetails/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getInvoiceOption() {
    return this.http
      .get(`${environment.API_URL}/company/setting/invoiceOption`)
      .pipe(catchError(this.handleError));
  }

  getInvoiceHistory(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/staff/invoice/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getInvoiceList(queryParam: any) {
    return this.http
      .get(`${environment.API_URL}/slipMaster/searchByBillingHeaderOrClient?` + queryParam)
      .pipe(catchError(this.handleError));
  }

  getGroupPermissions() {
    return this.http
      .get(`${environment.API_URL}/dms/groupPermissions`)
      .pipe(catchError(this.handleError));
  }

  getClientFolders(clientID) {
    return this.http
      .get(`${environment.API_URL}/fileManager/getClientDMS/${clientID}`)
      .pipe(catchError(this.handleError));
  }

  changeClientDMSParent(obj) {
    return this.http
      .put(`${environment.API_URL}/fileManager/changeClientDMSParent`, obj)
      .pipe(catchError(this.handleError));
  }

  changeFilesClientDMSParent(obj) {
    return this.http
      .put(`${environment.API_URL}/fileManager/files/changeClientDMSParent`, obj)
      .pipe(catchError(this.handleError));
  }

  loadDefaultDMSViewAPI() {
    let fetchMasterEmail = this.http.get(`${environment.API_URL}/dms/fetchMasterEmailTemplate/dms`);
    let groupPermissions = this.http.get(
      `${environment.API_URL}/user-permissions/dms-default-permissions`
    );
    let clients = this.http.get(
      `${environment.API_URL}/staff/getAllClientListWithClientNameClientId`
    );
    let users = this.http.get(`${environment.API_URL}/staff/users`);

    return forkJoin([fetchMasterEmail, groupPermissions, clients, users]);
  }

  createEmailTemplate(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/emailTemplate`, obj)
      .pipe(catchError(this.handleError));
  }

  updateEmailTemplate(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/emailTemplate/${obj.EmailTemplateID}`, obj)
      .pipe(catchError(this.handleError));
  }

  editClientTemplate(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/clientTemplate/${obj.dmsClientTemplatesID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteEmailTemplate(id) {
    return this.http
      .delete(`${environment.API_URL}/dms/emailTemplate/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteClientTemplateNames(id) {
    return this.http
      .delete(`${environment.API_URL}/dms/clientTemplateNames/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteClientTemplate(id) {
    return this.http
      .delete(`${environment.API_URL}/dms/clientTemplate/${id}`)
      .pipe(catchError(this.handleError));
  }

  bulkCreateEmailTemplates(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/bulkCreateEmailTemplates`, obj)
      .pipe(catchError(this.handleError));
  }

  getEmailTemplate(id) {
    return this.http
      .get(`${environment.API_URL}/dms/emailTemplate/${id}`)
      .pipe(catchError(this.handleError));
  }

  createGroupPermissions(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/groupPermissions/create`, obj)
      .pipe(catchError(this.handleError));
  }

  createClientTemplateNames(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/clientTemplateNames/create`, obj)
      .pipe(catchError(this.handleError));
  }

  createClientTemplates(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/dmsClientTemplates/create`, obj)
      .pipe(catchError(this.handleError));
  }

  updateGroupPermissions(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/groupPermissions/${obj.dmsGroupPermissionsID}`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateParentFolder(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/dragdropupdates/update`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkClientTemplateNames(postData) {
    return this.http
      .post(`${environment.API_URL}/dms/bulkClientTemplateNames/create`, postData)
      .pipe(catchError(this.handleError));
  }

  updateBlueChildren(obj, id) {
    return this.http
      .put(`${environment.API_URL}/dms/updateBlueChildren/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  updatefolderAndChildren(obj, id) {
    return this.http
      .put(`${environment.API_URL}/dms/updatefolderAndChildren/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  applyParentYellowChildrens(obj, id, isParent) {
    return this.http
      .put(`${environment.API_URL}/dms/updateYellowChildren/${id}/${isParent}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateClientTemplateNames(obj) {
    return this.http
      .put(`${environment.API_URL}/dms/clientTemplateNames/${obj.primaryID}`, obj)
      .pipe(catchError(this.handleError));
  }

  applyClientTemplatesYellowChildrens(obj, id, isParent) {
    return this.http
      .put(`${environment.API_URL}/dms/updateClientTemplatesYellowChildren/${id}/${isParent}`, obj)
      .pipe(catchError(this.handleError));
  }

  getExpenseMangerData(createDate, DDate) {
    return this.http
      .get(`${environment.API_URL}/company/getExpenseMangerData/${createDate}/${DDate}`)
      .pipe(catchError(this.handleError));
  }

  getClientsExpensedocuments(expenseMasterId) {
    return this.http
      .get(`${environment.API_URL}/staff/expense/docs/${expenseMasterId}`)
      .pipe(catchError(this.handleError));
  }

  addNewTodo(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/todo`, obj)
      .pipe(catchError(this.handleError));
  }

  updateTodo(id, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/todo/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  getCodes() {
    return this.http.get(`${environment.API_URL}/company/shotHands`);
  }

  updateCode(id, obj) {
    return this.http
      .put(`${environment.API_URL}/company/shotHands/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  saveCode(obj) {
    return this.http
      .post(`${environment.API_URL}/company/shotHands`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteCode(id) {
    return this.http
      .delete(`${environment.API_URL}/company/shotHands/${id}`)
      .pipe(catchError(this.handleError));
  }

  getMailChimpAccountDetails() {
    return this.http
      .get(`${environment.API_URL}/mailchimp/getAaccountDetails`)
      .pipe(catchError(this.handleError));
  }

  getMailChimpList() {
    return this.http
      .get(`${environment.API_URL}/mailchimp/getLists`)
      .pipe(catchError(this.handleError));
  }

  getSndInBlueAccountDetails() {
    return this.http
      .get(`${environment.API_URL}/sendinblue/account`)
      .pipe(catchError(this.handleError));
  }

  getSendinblueList() {
    return this.http
      .get(`${environment.API_URL}/sendinblue/contacts/lists`)
      .pipe(catchError(this.handleError));
  }

  updateEmailautomation(obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/emailautomation`, obj)
      .pipe(catchError(this.handleError));
  }

  getListOfClientsForTaks() {
    return this.http
      .get(
        `${environment.API_URL}/staff/clientDetails/true/active?offset=0&limit=2000&sortField=ClientName&sortOrder=1&search=`
      )
      .pipe(catchError(this.handleError));
  }

  getListOfClientsDetails(query?: string) {
    return this.http
      .get(`${environment.API_URL}/staff/clientDetails/true/active?${query}`)
      .pipe(catchError(this.handleError));
  }

  bulkMailChimpListCreate(postData) {
    return this.http
      .post(`${environment.API_URL}/mailchimp/createlists`, postData)
      .pipe(catchError(this.handleError));
  }

  getClientHistoryByCompanyId(cID) {
    return this.http
      .get(`${environment.API_URL}/ddm/getClientHistoryByCompanyId/${cID}`)
      .pipe(catchError(this.handleError));
  }

  fetchDDMSettings(companyID) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmSettings/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  createDDMSettings(postData) {
    return this.http
      .post(`${environment.API_URL}/ddm/ddmSettings/create`, postData)
      .pipe(catchError(this.handleError));
  }

  updateDDMSettings(postData) {
    return this.http
      .put(`${environment.API_URL}/ddm/ddmSettings/${postData.ddmSettingsID}`, postData)
      .pipe(catchError(this.handleError));
  }

  getDisplayCompanyByCompanyId(companyID) {
    return this.http
      .get(`${environment.API_URL}/ddm/displayCompanyByCompanyId/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  deleteCompanyTemplateHeader(id) {
    return this.http
      .delete(`${environment.API_URL}/ddm/companyTemplateHeader/delete/${id}`)
      .pipe(catchError(this.handleError));
  }

  getProjectNames(cID) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmCompanyProjectNames/${cID}`)
      .pipe(catchError(this.handleError));
  }

  async asyncGetProjectList(
    dueDate,
    status,
    userAssignedId,
    managerID,
    clientID,
    projectHeaderID,
    tagID,
    companyId,
    clientGroup,
    _default,
    dateRange,
    dateToFilter,
    tagIds,
    statusIds,
    repeatValues,
    projectMasterID,

    /*;
      @todo-note:
        Please put all parameters inside the option data.
      @todo-note;

      @note:
        Do not add more parameter above and/or below this.
        All parameter should now go inside the option data.
      @note;
    */
    option,

    urlParams?,
    completionDate?
  ) {
    option = option || {};

    const pageIndex = option.pageIndex || 0;

    const nextPage = option.nextPage >= 0 ? option.nextPage : -1;

    const pageSize = option.pageSize;

    const _slicePaginationStatus = option._slicePaginationStatus;

    const _sqlPaginationStatus = option._sqlPaginationStatus;

    const searchQuery = option.searchQuery || undefined;

    const sortQuery = option.sortQuery || undefined;

    let uriPath = [
      `${environment.API_URL}/ddm/getProjectList`,

      `${dueDate}`,
      `${status}`,
      `${userAssignedId}`,
      `${managerID}`,
      `${clientID}`,
      `${projectHeaderID}`,
      `${tagID}`,
      `${companyId}`,
      `${clientGroup}`,
      `${_default}`,
      `${dateRange}`,
      `${dateToFilter}`,
      `${tagIds}`,
      `${statusIds}`,
      `${repeatValues}`,
      `${projectMasterID}`
    ].join('/');

    if (completionDate) {
      uriPath += `/${completionDate}`;
    }

    uriPath = [
      uriPath,
      [
        urlParams?.length > 0 ? urlParams : undefined,
        pageIndex > 0 ? `pageIndex=${pageIndex}` : undefined,
        nextPage >= 0 ? `nextPage=${nextPage}` : undefined,
        pageSize > 0 ? `pageSize=${pageSize}` : undefined,
        _slicePaginationStatus === true
          ? `_slicePaginationStatus=${_slicePaginationStatus}`
          : undefined,
        _sqlPaginationStatus === true ? `_sqlPaginationStatus=${_sqlPaginationStatus}` : undefined,
        typeof searchQuery != 'undefined'
          ? `searchQuery=${encodeURIComponent(searchQuery)}`
          : undefined,
        typeof sortQuery != 'undefined' ? `sortQuery=${encodeURIComponent(sortQuery)}` : undefined
      ]
        .filter(Boolean)
        .join('&')
    ].join('?');

    return this.http.get(uriPath).pipe(catchError(this.handleError)).toPromise();
  }

  getProjectList(
    dueDate,
    status,
    userAssignedId,
    managerID,
    clientID,
    projectHeaderID,
    tagID,
    companyId,
    clientGroup,
    _default,
    dateRange,
    dateToFilter,
    tagIds,
    statusIds,
    repeatValues,
    projectMasterID,

    /*;
      @todo-note:
        Please put all parameters inside the option data.
      @todo-note;

      @note:
        Do not add more parameter above and/or below this.
        All parameter should now go inside the option data.
      @note;
    */
    option,

    urlParams?,
    completionDate?
  ) {
    option = option || {};

    const pageIndex = option.pageIndex || 0;

    const nextPage = option.nextPage >= 0 ? option.nextPage : -1;

    const pageSize = option.pageSize;

    const _slicePaginationStatus = option._slicePaginationStatus;

    const _sqlPaginationStatus = option._sqlPaginationStatus;

    const searchQuery = option.searchQuery || undefined;

    const sortQuery = option.sortQuery || undefined;

    let uriPath = [
      `${environment.API_URL}/ddm/getProjectList`,

      `${dueDate}`,
      `${status}`,
      `${userAssignedId}`,
      `${managerID}`,
      `${clientID}`,
      `${projectHeaderID}`,
      `${tagID}`,
      `${companyId}`,
      `${clientGroup}`,
      `${_default}`,
      `${dateRange}`,
      `${dateToFilter}`,
      `${tagIds}`,
      `${statusIds}`,
      `${repeatValues}`,
      `${projectMasterID}`
    ].join('/');

    if (completionDate) {
      uriPath += `/${completionDate}`;
    }

    uriPath = [
      uriPath,
      [
        urlParams?.length > 0 ? urlParams : undefined,
        pageIndex > 0 ? `pageIndex=${pageIndex}` : undefined,
        nextPage >= 0 ? `nextPage=${nextPage}` : undefined,
        pageSize > 0 ? `pageSize=${pageSize}` : undefined,
        _slicePaginationStatus === true
          ? `_slicePaginationStatus=${_slicePaginationStatus}`
          : undefined,
        _sqlPaginationStatus === true ? `_sqlPaginationStatus=${_sqlPaginationStatus}` : undefined,
        typeof searchQuery != 'undefined'
          ? `searchQuery=${encodeURIComponent(searchQuery)}`
          : undefined,
        typeof sortQuery != 'undefined' ? `sortQuery=${encodeURIComponent(sortQuery)}` : undefined
      ]
        .filter(Boolean)
        .join('&')
    ].join('?');

    return this.http.get(uriPath).pipe(
      catchError(error => {
        if (error == 'Unauthorized! Invalid access.') {
          this.handleError(error);
          return of({ error: error, status: 403 });
        } else {
          this.handleError(error);
          return of({ error: error });
        }
      })
    );
  }

  fetchprojectDetailsByHeaderId(uniqueID, option?) {
    option = option || {};

    const pageIndex = option.pageIndex || 0;

    const nextPage = option.nextPage >= 0 ? option.nextPage : -1;

    const pageSize = option.pageSize;

    const _slicePaginationStatus = option._slicePaginationStatus;

    const _sqlPaginationStatus = option._sqlPaginationStatus;

    const taskListPaginateStatus = option.taskListPaginateStatus;

    return this.http
      .get(
        [
          `${environment.API_URL}/ddm/projectDetailsByHeaderId/${uniqueID}`,
          [
            pageIndex > 0 ? `pageIndex=${pageIndex}` : undefined,
            nextPage >= 0 ? `nextPage=${nextPage}` : undefined,
            pageSize > 0 ? `pageSize=${pageSize}` : undefined,
            _slicePaginationStatus === true
              ? `_slicePaginationStatus=${_slicePaginationStatus}`
              : undefined,
            _sqlPaginationStatus === true
              ? `_sqlPaginationStatus=${_sqlPaginationStatus}`
              : undefined,
            taskListPaginateStatus === true
              ? `taskListPaginateStatus=${taskListPaginateStatus}`
              : undefined
          ]
            .filter(Boolean)
            .join('&')
        ].join('?')
      )
      .pipe(catchError(this.handleError));
  }

  getTasksList(
    dueDate,
    userAssignedId,
    clientID,
    projectHeaderID,
    companyId,
    status,
    clientGroup,
    statusTask,
    dateRange,
    statusIDs,

    /*;
      @todo-note:
        Please put all parameters inside the option data.
      @todo-note;

      @note:
        Do not add more parameter above and/or below this.
        All parameter should now go inside the option data.
      @note;
    */
    option
  ) {
    option = option || {};

    const pageIndex = option.pageIndex || 0;

    const nextPage = option.nextPage >= 0 ? option.nextPage : -1;

    const pageSize = option.pageSize;

    const _slicePaginationStatus = option._slicePaginationStatus;

    const _sqlPaginationStatus = option._sqlPaginationStatus;

    const searchQuery = option.searchQuery || undefined;

    const sortQuery = option.sortQuery || undefined;

    const taskTypeViewQuery = option.taskTypeViewQuery || undefined;

    const uriPath = [
      [
        `${environment.API_URL}/ddm/getTasksList`,

        `${dueDate}`,
        `${userAssignedId}`,
        `${clientID}`,
        `${projectHeaderID}`,
        `${companyId}`,
        `${status}`,
        `${clientGroup}`,
        `${statusTask}`,
        `${dateRange}`,
        `${statusIDs}`
      ].join('/'),
      [
        pageIndex > 0 ? `pageIndex=${pageIndex}` : undefined,
        nextPage >= 0 ? `nextPage=${nextPage}` : undefined,
        pageSize > 0 ? `pageSize=${pageSize}` : undefined,
        _slicePaginationStatus === true
          ? `_slicePaginationStatus=${_slicePaginationStatus}`
          : undefined,
        _sqlPaginationStatus === true ? `_sqlPaginationStatus=${_sqlPaginationStatus}` : undefined,
        typeof searchQuery != 'undefined'
          ? `searchQuery=${encodeURIComponent(searchQuery)}`
          : undefined,
        typeof sortQuery != 'undefined' ? `sortQuery=${encodeURIComponent(sortQuery)}` : undefined,
        typeof taskTypeViewQuery != 'undefined'
          ? `taskTypeViewQuery=${encodeURIComponent(taskTypeViewQuery)}`
          : undefined
      ]
        .filter(Boolean)
        .join('&')
    ].join('?');

    return this.http.get(uriPath).pipe(catchError(this.handleError));
  }

  async asyncGetTasksList(
    dueDate,
    userAssignedId,
    clientID,
    projectHeaderID,
    companyId,
    status,
    clientGroup,
    statusTask,
    dateRange,
    statusIDs,

    /*;
      @todo-note:
        Please put all parameters inside the option data.
      @todo-note;

      @note:
        Do not add more parameter above and/or below this.
        All parameter should now go inside the option data.
      @note;
    */
    option
  ) {
    option = option || {};

    const pageIndex = option.pageIndex || 0;

    const nextPage = option.nextPage >= 0 ? option.nextPage : -1;

    const pageSize = option.pageSize;

    const _slicePaginationStatus = option._slicePaginationStatus;

    const _sqlPaginationStatus = option._sqlPaginationStatus;

    const searchQuery = option.searchQuery || undefined;

    const sortQuery = option.sortQuery || undefined;

    const taskTypeViewQuery = option.taskTypeViewQuery || undefined;

    const uriPath = [
      [
        `${environment.API_URL}/ddm/getTasksList`,

        `${dueDate}`,
        `${userAssignedId}`,
        `${clientID}`,
        `${projectHeaderID}`,
        `${companyId}`,
        `${status}`,
        `${clientGroup}`,
        `${statusTask}`,
        `${dateRange}`,
        `${statusIDs}`
      ].join('/'),
      [
        pageIndex > 0 ? `pageIndex=${pageIndex}` : undefined,
        nextPage >= 0 ? `nextPage=${nextPage}` : undefined,
        pageSize > 0 ? `pageSize=${pageSize}` : undefined,
        _slicePaginationStatus === true
          ? `_slicePaginationStatus=${_slicePaginationStatus}`
          : undefined,
        _sqlPaginationStatus === true ? `_sqlPaginationStatus=${_sqlPaginationStatus}` : undefined,
        typeof searchQuery != 'undefined'
          ? `searchQuery=${encodeURIComponent(searchQuery)}`
          : undefined,
        typeof sortQuery != 'undefined' ? `sortQuery=${encodeURIComponent(sortQuery)}` : undefined,
        typeof taskTypeViewQuery != 'undefined'
          ? `taskTypeViewQuery=${encodeURIComponent(taskTypeViewQuery)}`
          : undefined
      ]
        .filter(Boolean)
        .join('&')
    ].join('?');

    return this.http.get(uriPath).pipe(catchError(this.handleError)).toPromise();
  }

  updateProjectHeader(uniqueID, obj) {
    return this.http
      .put(`${environment.API_URL}/ddm/projectHeader/update/${uniqueID}`, obj)
      .pipe(catchError(this.handleError));
  }

  createProjectHeader(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/projectHeader/create`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteBulkProjectHeader(obj) {
    return this.http
      .put(`${environment.API_URL}/ddm/projectHeader/bulkdelete`, obj)
      .pipe(catchError(this.handleError));
  }

  fetchGetProjectHeader(uniqueID) {
    return this.http
      .get(`${environment.API_URL}/ddm/projectHeader/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  updateProjectDetails(uniqueID, obj) {
    return this.http
      .put(`${environment.API_URL}/ddm/projectDetails/update/${uniqueID}`, obj)
      .pipe(catchError(this.handleError));
  }

  async asyncUpdateProjectDetails(uniqueID, obj) {
    return this.http
      .put(`${environment.API_URL}/ddm/projectDetails/update/${uniqueID}`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getProjectFeed(companyID) {
    return this.http
      .get(`${environment.API_URL}/ddm/getProjectFeed/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getDdmDashboardStaff(companyID, dateRange) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmDashboardStaff/${companyID}/${dateRange}`)
      .pipe(catchError(this.handleError));
  }

  getDdmDashboardMain(cID, uId, projectTags, dateRange) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmDashboardMain/${cID}/${uId}/${projectTags}/${dateRange}`)
      .pipe(catchError(this.handleError));
  }

  getProjectsForDashboard(cID, uId, projectTags, dateRange) {
    return this.http
      .get(
        `${environment.API_URL}/ddm/ddmDashboardProjects/${cID}/${uId}/${projectTags}/${dateRange}`
      )
      .pipe(catchError(this.handleError));
  }

  getDashboardTaskData(cID, uId, dateRange) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmDashboardMain/task/${cID}/${uId}/${dateRange}`)
      .pipe(catchError(this.handleError));
  }

  async getAsyncDashboardTaskData(cID, uId, dateRange) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmDashboardMain/task/${cID}/${uId}/${dateRange}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getCompanyTemplateHeaderByCompanyId(cID) {
    return this.http
      .get(`${environment.API_URL}/ddm/companyTemplateHeaderByCompanyId/${cID}`)
      .pipe(catchError(this.handleError));
  }

  getCompanyTemplateDetails(companyTemplateHeaderId) {
    return this.http
      .get(`${environment.API_URL}/ddm/companyTemplateHeader/${companyTemplateHeaderId}`)
      .pipe(catchError(this.handleError));
  }

  getCompanyTemplateHeader(companyTemplateHeaderID) {
    return this.http
      .get(
        `${environment.API_URL}/ddm/companyTemplateDetailsBytemplateHeaderID/${companyTemplateHeaderID}`
      )
      .pipe(catchError(this.handleError));
  }

  deleteCompanyTemplateDetails(id) {
    return this.http
      .delete(`${environment.API_URL}/ddm/companyTemplateDetails/delete/${id}`)
      .pipe(catchError(this.handleError));
  }

  createCompanyTemplateHeader(postData) {
    return this.http
      .post(`${environment.API_URL}/ddm/companyTemplateHeader/create`, postData)
      .pipe(catchError(this.handleError));
  }

  updateCompanyTemplateDetails(postData) {
    return this.http
      .put(
        `${environment.API_URL}/ddm/companyTemplateHeader/update/${postData.CompanyTemplateHeaderID}`,
        postData
      )
      .pipe(catchError(this.handleError));
  }

  fetchProjectDocuments(id) {
    return this.http
      .get(`${environment.API_URL}/ddm/projectdocuments/${id}`)
      .pipe(catchError(this.handleError));
  }

  fetchprojectsNotes(projectHeaderID) {
    return this.http
      .get(`${environment.API_URL}/ddm/projectsNotes/${projectHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  createProjectNote(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/projects/create`, obj)
      .pipe(catchError(this.handleError));
  }

  updateProjectNote(projectNotesID, obj) {
    return this.http
      .put(`${environment.API_URL}/ddm/projectsNotes/${projectNotesID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteProjectNote(noteID) {
    return this.http
      .delete(`${environment.API_URL}/ddm/projects/${noteID}`)
      .pipe(catchError(this.handleError));
  }

  createProjectNames(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/ddmCompanyProjectNames/create`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteProjectDetails(uniqueID) {
    return this.http
      .delete(`${environment.API_URL}/ddm/projectDetails/delete/${uniqueID}`)
      .pipe(catchError(this.handleError));
  }

  getMasterTemplateDetails(masterTemplatgeHeaderID) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmMasterTemplateDetails/${masterTemplatgeHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  addCompanyTemplateDetails(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/companyTemplateDetails/create`, obj)
      .pipe(catchError(this.handleError));
  }

  getMasterTemplateHeader() {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmMasterTemplateHeader`)
      .pipe(catchError(this.handleError));
  }

  ddmProjectHeaderbyClientID(
    dueDate,
    status,
    userAssignedId,
    managerID,
    clientID,
    projectHeaderID,
    tagID,
    companyId
  ) {
    return this.http
      .get(
        `${environment.API_URL}/ddm/ddmProjectHeaderbyClientID/${dueDate}/${status}/${userAssignedId}/${managerID}/${clientID}/${projectHeaderID}/${tagID}/${companyId}`
      )
      .pipe(catchError(this.handleError));
  }

  ddmCustomerbyClientID(clientId) {
    return this.http
      .get(`${environment.API_URL}/ddm/ddmCustomerbyClientID/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  ddmDeleteProjectHeader(id) {
    return this.http
      .delete(`${environment.API_URL}/ddm/projectHeader/delete/${id}`)
      .pipe(catchError(this.handleError));
  }

  bulkCreateProjectHeaderAndDetails(postData) {
    return this.http
      .post(`${environment.API_URL}/ddm/bulkCreateProjectHeaderAndDetails`, postData)
      .pipe(catchError(this.handleError));
  }

  register(userData) {
    return this.http
      .post(`${environment.API_URL}/signup`, userData)
      .pipe(catchError(this.handleError));
  }

  // createOrUpdateUser(obj) {
  //     return this.http.post(`${environment.API_URL}/mail/createOrUpdateUser`, obj).pipe(catchError(this.handleError));
  // }

  getActiveUserDetailsByEmailId(email) {
    return this.http
      .get(`${environment.API_URL}/mail/getActiveUserDetailsByEmailId/${email}`)
      .pipe(catchError(this.handleError));
  }

  createCampaignLists(obj) {
    return this.http
      .post(`${environment.API_URL}/activeCampaign/createlist`, obj)
      .pipe(catchError(this.handleError));
  }

  getDashboardWidgets(query) {
    return this.http
      .get(`${environment.API_URL}/staff/dashboardWidgets?${query}`)
      .pipe(catchError(this.handleError));
  }

  getResourceList() {
    return this.http
      .get(`${environment.API_URL}/staff/calendar/appointments/resourceList`)
      .pipe(catchError(this.handleError));
  }

  getTodoList() {
    return this.http
      .get(`${environment.API_URL}/staff/todo/null`)
      .pipe(catchError(this.handleError));
  }

  getTodoListWithFilters(
    companyId,
    staffId,
    staffIdPrivate,
    clientId,
    priorities,
    categories,
    openDateFilter,
    completedDateFilter,
    dueDateFilter
  ) {
    return this.http
      .get(
        `${environment.API_URL}/staff/filter/todo/private/${companyId}/${staffId}/${staffIdPrivate}/${clientId}/${priorities}/${categories}/${openDateFilter}/${completedDateFilter}/${dueDateFilter}`
      )
      .pipe(catchError(this.handleError));
  }

  getTodoListPrivate(staffIdPrivate) {
    return this.http
      .get(`${environment.API_URL}/staff/todo/private/null/${staffIdPrivate}`)
      .pipe(catchError(this.handleError));
  }

  getTodoListByClientID(ClientID, status, dateRange) {
    return this.http
      .get(`${environment.API_URL}/staff/todo/client/${ClientID}/${status}/${dateRange}`)
      .pipe(catchError(this.handleError));
  }

  deleteTodo(id) {
    return this.http
      .delete(`${environment.API_URL}/staff/todo/${id}`)
      .pipe(catchError(this.handleError));
  }

  getCityCountryStateByZip(zip) {
    return this.http
      .get(`${environment.GOOGLE_API_URL}/address=${zip}`)
      .pipe(catchError(this.handleError));
  }

  updateSetupStatus(obj) {
    return this.http
      .put(`${environment.API_URL}/company/setupStatus`, obj)
      .pipe(catchError(this.handleError));
  }

  createProject(postData) {
    return this.http
      .post(`${environment.API_URL}/company/projects/createProject`, postData)
      .pipe(catchError(this.handleError));
  }

  getCSVList() {
    return this.http
      .get(`${environment.API_URL}/clients/csv-list`)
      .pipe(catchError(this.handleError));
  }

  getCSVImportData(customerCSVReference) {
    return this.http
      .get(`${environment.API_URL}/clients/csv-import/${customerCSVReference}`)
      .pipe(catchError(this.handleError));
  }

  getTaxCSVMapping(TaxVendor) {
    return this.http
      .get(`${environment.API_URL}/clients/csv-taxImports-mapping/${TaxVendor}`)
      .pipe(catchError(this.handleError));
  }

  processCSV(postData, type) {
    let url = '';
    if (type == 'OI-Mango-Billing') {
      url = '/clients/csv-importInvoices';
    } else if (type == 'OT-Mango-Billing') {
      url = `/clients/csv-importExpensives`;
    } else if (type == 'CU-Mango-Billing') {
      url = `/clients/csv-importClientsUpdate`;
    } else if (type == 'CN-Mango-Billing') {
      url = `/clients/csv-importClientNotes`;
    } else if (type == 'PM-Mango-Billing') {
      url = `/clients/csv-importPayments`;
    } else if (type == 'PMIT-Mango-Billing') {
      url = `/clients/csv-import-IT-Header`;
    } else if (type == 'PDIT-Mango-Billing') {
      url = `/clients/csv-import-IT-Details`;
    } else if (type == 'EB-Mango-Billing-Engagement-Budget') {
      url = `/clients/csv/engage-budget/import`;
    } else {
      url = `/clients/csv-import`;
    }

    return this.http
      .post(`${environment.API_URL}${url}`, postData)
      .pipe(catchError(this.handleError));
  }

  processTaxImport(postData) {
    return this.http
      .post(`${environment.API_URL}/clients/csv-taxImports`, postData)
      .pipe(catchError(this.handleError));
  }

  fetchStats(date) {
    return this.http
      .get(`${environment.API_URL}/admin/dashboardStats/${date}`)
      .pipe(catchError(this.handleError));
  }

  removeCSV(id) {
    return this.http
      .delete(`${environment.API_URL}/clients/csv-remove/${id}`)
      .pipe(catchError(this.handleError));
  }

  uploadCSV(postData, uploadType) {
    return this.http
      .post(`${environment.API_URL}/clients/csv-upload/${uploadType}`, postData)
      .pipe(catchError(this.handleError));
  }

  getDashboardDataByStaffId(staffId): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/admin/dashboardStatsStaff/${staffId}`)
      .pipe(catchError(this.handleError));
  }

  getDashboardDataByCompany(): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/admin/dashboardStats`)
      .pipe(catchError(this.handleError));
  }

  requestEnrollment(companyId) {
    let obj = {
      clientId: `${environment.lexcharge_clientId}`,
      clientSecret: `${environment.lexcharge_clientSecret}`,
      externalStyleUrl: '',
      formCompletionCallbackUrl: `${environment.API_URL}` + '/company/lexChargeOnboardCallBack',
      merchantCompletionCallbackUrl:
        `${environment.API_URL}` + '/company/lexChargeMerchantAccountStatusCallBack',
      externalOperatingAccountId: companyId
    };
    const headers = new HttpHeaders(obj);
    let options = { headers: headers };
    return this.http
      .post(`${environment.lexcharge_url}/onboarding`, obj, options)
      .pipe(catchError(this.handleError));
  }

  processLexAuth(token) {
    let obj = {
      client_id: `${environment.lexcharge_clientId}`,
      client_secret: `${environment.lexcharge_clientSecret}`,
      grant_type: 'refresh_token',
      refresh_token: token
    };
    const headers = new HttpHeaders(obj);
    let options = { headers: headers };
    return this.http
      .post(`${environment.lexcharge_url}/authentication/token`, obj, options)
      .pipe(catchError(this.handleError));
  }

  processLexPaymentsWithToken(companyData) {
    let reqBody = {
      externalClientId: companyData.uniqueId,
      paymentToken: `${companyData.Token}`,
      amount: Number(companyData.Amount)
    };

    const headers = new HttpHeaders({ authorization: companyData.LexAccessToken });
    let options = { headers: headers };
    return this.http
      .post(
        `${environment.lexcharge_url}/Payments/${companyData.LexOperatingMerchantAccountId}/transactions/process-payment
`,
        reqBody,
        options
      )
      .pipe(catchError(this.handleError));
  }
  processLexPayments(companyData, clientData, formData, companyId) {
    let typeOfTransaction = formData.PaymentType == 'Credit Card' ? 'CC' : 'ACH';
    let obj = {};
    obj['externalId'] = clientData.ClientID.toString() + '$' + typeOfTransaction;
    obj['invoiceId'] = companyId.toString();
    obj['formCompletionCallbackUrl'] = `${environment.API_URL}/company/lexChargeSubmitCallBack`;
    obj['externalStyleUrl'] = `https://uat-portal.lexcharge.com/assets/hosted-payment-styles.css`;
    // obj['redirectUrl'] = ``;  create an empty html page  try this and see if it works.
    obj['payerName'] = clientData.ContactPerson;
    obj['payerLastName'] = '';
    obj['paymentAmount'] = formData.PaymentAmount;
    obj['address1'] = clientData.BusStreet1;
    obj['address2'] = clientData.BusStreet2;
    obj['city'] = clientData.BusCity;
    obj['state'] = clientData.BusState;
    obj['postalCode'] = clientData.BusZip;
    obj['country'] = 'USA'; //clientData.BusCountry;
    obj['description'] = clientData.ClientName;
    obj['processAmount'] = true;
    obj['storePaymentInformation'] = true;
    obj['isAch'] = formData.PaymentType == 'ACH' ? true : false;

    const headers = new HttpHeaders({ authorization: companyData.LexAccessToken });
    let options = { headers: headers };
    return this.http
      .post(
        `${environment.lexcharge_url}/Payments/${companyData.LexOperatingMerchantAccountId}/transactions/hosted-payment
`,
        obj,
        options
      )
      .pipe(catchError(this.handleError));
  }

  updateLexObjject(obj) {
    return this.http
      .put(`${environment.API_URL}/company/updateLexObject`, obj)
      .pipe(catchError(this.handleError));
  }

  fetchSuperAdminDashboardStats() {
    return this.http
      .get(`${environment.API_URL}/admin/dashboardStatsForSuperAdmin`)
      .pipe(catchError(this.handleError));
  }

  fetchSuperAdminCPA() {
    return this.http
      .get(`${environment.API_URL}/admin/cpaForSuperAdmin`)
      .pipe(catchError(this.handleError));
  }

  getPMAllStaffList() {
    return this.http
      .get(`${environment.API_URL}/staff/getPMAllStaffList`)
      .pipe(catchError(this.handleError));
  }

  getMobilePaymentsPMAllStaffList(companyId) {
    return this.http
      .get(`${environment.API_URL}/staff/MobilePayments/getPMAllStaffList/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  getCompanyNames(query) {
    return this.http
      .get(`${environment.API_URL}/admin/companyNames?${query}`)
      .pipe(catchError(this.handleError));
  }

  getCardOfCompany() {
    return this.http
      .get(`${environment.API_URL}/stripe/cardDetails`)
      .pipe(catchError(this.handleError));
  }

  loadDefaultExportAPI(companyId, startDate, endDate) {
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');

    let invoices = this.http.get(
      `${environment.API_URL}/cashReceipts/getInvoiceDataFromDate/${startDate}/${endDate}`
    );
    let openTimeRecords = this.http.get(
      `${environment.API_URL}/cashReceipts/getOpenTimeRecordsFromDate/${startDate}/${endDate}`
    );
    let users = this.http.get(`${environment.API_URL}/staff/users`);
    let activities = this.http.get(`${environment.API_URL}/company/setting/activities`);
    let expensesCode = this.http.get(
      `${environment.API_URL}/cashReceipts/getExpensesCodeByCompanyId`
    );
    let groupCategoryList = this.http.get(
      `${environment.API_URL}/company/getCustomerGroupCategoryList`
    );
    let customerType = this.http.get(`${environment.API_URL}/company/customerType`);
    let engagementType = this.http.get(`${environment.API_URL}/company/engagement/${companyId}`);
    let payments = this.http.get(
      `${environment.API_URL}/cash-receipts/export-payments/${companyId}/${startDate}/${endDate}`
    );
    let schedule = this.http.get(
      `${environment.API_URL}/staff/calendar/exportAppointment/${companyId}/${startDate}/${endDate}`
    );

    return forkJoin([
      invoices,
      openTimeRecords,
      users,
      activities,
      expensesCode,
      groupCategoryList,
      customerType,
      engagementType,
      payments,
      schedule
    ]);
  }

  getScheduleFromDate(startDate, endDate, companyId) {
    return this.http.get(
      `${environment.API_URL}/staff/calendar/exportAppointment/${companyId}/${startDate}/${endDate}`
    );
  }

  getPaymentsFromDate(startDate, endDate, companyId) {
    return this.http.get(
      `${environment.API_URL}/cash-receipts/export-payments/${companyId}/${startDate}/${endDate}`
    );
  }

  getInvoiceDataFromDate(startDate, endDate) {
    return this.http
      .get(`${environment.API_URL}/cashReceipts/getInvoiceDataFromDate/${startDate}/${endDate}`)
      .pipe(catchError(this.handleError));
  }

  getOpenTimeRecordsFromDate(startDate, endDate) {
    return this.http
      .get(`${environment.API_URL}/cashReceipts/getOpenTimeRecordsFromDate/${startDate}/${endDate}`)
      .pipe(catchError(this.handleError));
  }

  clearCustomerStatement() {
    return this.http
      .put(`${environment.API_URL}/reports/clearCustomerStatement`, {})
      .pipe(catchError(this.handleError));
  }

  getStatementStatements(companyId, balance, invoiceDate) {
    return this.http
      .get(
        `${environment.API_URL}/reports/statement-printing/${companyId}/${balance}/${invoiceDate}`
      )
      .pipe(catchError(this.handleError));
  }

  deleteProjectTemplateHeader(id) {
    return this.http
      .delete(`${environment.API_URL}/ddm/ddmCompanyProjectNames/${id}`)
      .pipe(catchError(this.handleError));
  }

  loadDefaultSuperAdminAPI(companyId) {
    let apiOne = this.http.get(`${environment.API_URL}/superadmin/getManageAccounts/${companyId}`);
    let apiTwo = this.http.get(`${environment.API_URL}/superadmin/getStaffDetails`);
    let apiThree = this.http.get(`${environment.API_URL}/superadmin/getCustmorDetails`);
    let apiFour = this.http.get(`${environment.API_URL}/superadmin/getRevenueDetails`);
    let apiFive = this.http.get(`${environment.API_URL}/superadmin/getBilledRevenueDetails`);
    return forkJoin([apiOne, apiTwo, apiThree, apiFour, apiFive]);
  }

  getCustomerDetails() {
    return this.http
      .get(`${environment.API_URL}/superadmin/getCustmorDetails`)
      .pipe(catchError(this.handleError));
  }

  getManageAccounts(companyIdRef) {
    return this.http
      .get(`${environment.API_URL}/superadmin/getManageAccounts/${companyIdRef}`)
      .pipe(catchError(this.handleError));
  }

  getRevenueDetails() {
    return this.http
      .get(`${environment.API_URL}/superadmin/getRevenueDetails`)
      .pipe(catchError(this.handleError));
  }

  getBilledRevenueDetails() {
    return this.http
      .get(`${environment.API_URL}/superadmin/getBilledRevenueDetails`)
      .pipe(catchError(this.handleError));
  }

  getAllStaffListByCId(cid): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/company/getAllStaffListByCId/${cid}`)
      .pipe(catchError(this.handleError));
  }

  updateSettingsByCompanyId(companyId, obj) {
    return this.http
      .put(`${environment.API_URL}/company/setting/updateSettingsById/${companyId}`, obj)
      .pipe(catchError(this.handleError));
  }

  fetchLangKeys(lang: string) {
    return this.http
      .get(`${environment.API_URL}/language/keys/${lang}?${Math.floor(Math.random() * 9999) + 1}`)
      .pipe(catchError(this.handleError));
  }

  addLangKey(postData: any) {
    return this.http
      .post(`${environment.API_URL}/language/newkey`, postData)
      .pipe(catchError(this.handleError));
  }

  updateLangKey(postData: any) {
    return this.http
      .put(`${environment.API_URL}/language/updatekey`, postData)
      .pipe(catchError(this.handleError));
  }

  removeLangKey(lang: string, key: string) {
    return this.http
      .delete(`${environment.API_URL}/language/${lang}/${key}`)
      .pipe(catchError(this.handleError));
  }

  getMerchantSetupViewData() {
    return this.http
      .get(`${environment.API_URL}/company/merchant`)
      .pipe(catchError(this.handleError));
  }

  updateMerchantSetupView(obj: any) {
    return this.http
      .put(`${environment.API_URL}/company/merchant`, obj)
      .pipe(catchError(this.handleError));
  }

  doRefundAction(postData: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, method: 'POST' };
    return this.http
      .post(`${environment.SINGULAR_BILLPAY_API_PAYMENTS_URL}/SubmitCCVoid`, postData, options)
      .pipe(catchError(this.handleError));
  }

  enrollUser(postData: any) {
    return this.http
      .post(`${environment.API_URL}/cc/CreateMerchant`, postData)
      .pipe(catchError(this.handleError));
  }

  updateCreditCardsSetting(obj: any) {
    return this.http
      .put(`${environment.API_URL}/company/creditCardsSetting`, obj)
      .pipe(catchError(this.handleError));
  }

  cancelEnrollStatus(postData: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, method: 'POST' };
    return this.http
      .post(`${environment.SINGULAR_BILLPAY_API_URL}/profile/delete`, postData, options)
      .pipe(catchError(this.handleError));
  }

  getReports(obj: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let requestArg = { headers: headers, method: 'POST' };
    return this.http
      .post(`${environment.SINGULAR_BILLPAY_API_PAYMENTS_URL}/GetTransactionList`, obj, requestArg)
      .pipe(catchError(this.handleError));
  }

  doACHRefundAction(postData: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, method: 'POST' };
    return this.http
      .post(
        `${environment.SINGULAR_BILLPAY_API_PAYMENTS_URL}/SubmitACHVoidReturn`,
        postData,
        options
      )
      .pipe(catchError(this.handleError));
  }

  getClientStatements(dateStr, isShowAll: boolean = false) {
    return this.http
      .get(`${environment.API_URL}/reports/client-statements/${dateStr}/${isShowAll}`)
      .pipe(catchError(this.handleError));
  }

  getCompanyLocations(id) {
    return this.http
      .get(`${environment.API_URL}/company/locations/${id}`)
      .pipe(catchError(this.handleError));
  }

  getCompanyLocationsSuperAdmin(id) {
    return this.http
      .get(`${environment.API_URL}/super-admin/company/locations/${id}`)
      .pipe(catchError(this.handleError));
  }

  getCustomTableTemplates(companyId) {
    return this.http
      .get(`${environment.API_URL}/userDefinedFields/company/customTables/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  getCustomTableTemplatesWithClient(companyId, clientId) {
    return this.http
      .get(`${environment.API_URL}/userDefinedFields/company/customTables/${companyId}/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  getCustomTableClient(companyId, clientId) {
    return this.http
      .get(`${environment.API_URL}/userDefinedFields/client/customTables/${companyId}/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  deleteCustomTableClient(customTableClientId) {
    return this.http
      .delete(`${environment.API_URL}/userDefinedFields/client/customTables/${customTableClientId}`)
      .pipe(catchError(this.handleError));
  }

  getCustomTableAllClients(companyId) {
    return this.http
      .get(`${environment.API_URL}/userDefinedFields/client/customTables/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  postCustomTableTemplates(obj) {
    return this.http
      .post(`${environment.API_URL}/userDefinedFields/company/customTables`, obj)
      .pipe(catchError(this.handleError));
  }

  postCustomTableClient(obj) {
    return this.http
      .post(`${environment.API_URL}/userDefinedFields/client/customTables`, obj)
      .pipe(catchError(this.handleError));
  }

  putCustomTableTemplates(customTablesCompanyID, obj) {
    return this.http
      .put(
        `${environment.API_URL}/userDefinedFields/company/customTables/${customTablesCompanyID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  putCustomTableClient(customTablesClientID, obj) {
    return this.http
      .put(
        `${environment.API_URL}/userDefinedFields/client/customTables/${customTablesClientID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  deleteCustomTableTemplates(customTablesCompanyID) {
    return this.http
      .delete(
        `${environment.API_URL}/userDefinedFields/company/customTables/${customTablesCompanyID}`
      )
      .pipe(catchError(this.handleError));
  }

  getCompanyLocation(id) {
    return this.http
      .get(`${environment.API_URL}/company/location/${id}`)
      .pipe(catchError(this.handleError));
  }

  addCompanyLocation(postData: any) {
    return this.http
      .post(`${environment.API_URL}/company/location`, postData)
      .pipe(catchError(this.handleError));
  }

  updateCompanyLocation(id, obj: any) {
    return this.http
      .put(`${environment.API_URL}/company/location/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteCompanyLocation(id) {
    return this.http
      .delete(`${environment.API_URL}/company/location/${id}`)
      .pipe(catchError(this.handleError));
  }

  getLastInvoiceDate(id) {
    return this.http
      .get(`${environment.API_URL}/superadmin/getLastInvoiceDate/${id}`)
      .pipe(catchError(this.handleError));
  }

  getLastTimeRecordDate(id) {
    return this.http
      .get(`${environment.API_URL}/superadmin/getLastTimeRecordDate/${id}`)
      .pipe(catchError(this.handleError));
  }

  getLocation(address: string) {
    address = encodeURI(address);
    return this.http
      .get(`${environment.GOOGLE_API_URL}address='${address}'`)
      .pipe(catchError(this.handleError));
  }

  getUsersSelectedColsByUserId(id) {
    return this.http
      .get(`${environment.API_URL}/staff/users/selectedCols/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateUserSelectedColsByUserId(id, obj: any) {
    return this.http
      .put(`${environment.API_URL}/staff/users/selectedCols/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  getCustomerAddresses(ClientID) {
    return this.http
      .get(`${environment.API_URL}/customerAddresses/${ClientID}`)
      .pipe(catchError(this.handleError));
  }

  addCustomerAddresses(postData: any) {
    return this.http
      .post(`${environment.API_URL}/customerAddresses/`, postData)
      .pipe(catchError(this.handleError));
  }

  updateCustomerAddresses(CustomerAddressID, obj: any) {
    return this.http
      .put(`${environment.API_URL}/customerAddresses/${CustomerAddressID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteCustomerAddresses(CustomerAddressID) {
    return this.http
      .delete(`${environment.API_URL}/customerAddresses/${CustomerAddressID}`)
      .pipe(catchError(this.handleError));
  }

  getVendorAPIKeys(postData) {
    return this.http
      .post(`${environment.IS_API_URL}/firms/vendorAPIKeys`, postData)
      .pipe(catchError(this.handleError));
  }

  updateVendorAPIKeys(obj) {
    delete obj['PortalPaymentNotifications'];
    delete obj['MangoPaymentNotification'];
    return this.http
      .put(`${environment.API_URL}/vendor/saveImagineshareApiKey/${obj.mangoCompanyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getIshareClients(token) {
    return this.http
      .get(`${environment.IS_API_URL}/clients/getClients?vendorAPIToken=${token}`)
      .pipe(catchError(this.handleError));
  }

  getIshareFolders(clientID, token) {
    return this.http
      .get(`${environment.API_URL}/files/getFolder/${clientID}?vendorAPIToken=${token}`)
      .pipe(catchError(this.handleError));
  }

  enrollStax(postData) {
    return this.http
      .post(`${environment.STAX_API}/register`, postData)
      .pipe(catchError(this.handleError));
  }

  enrollHybridStax(postData) {
    return this.http
      .post(`${environment.API_URL}/stax-enrollment/admin/enroll`, postData)
      .pipe(catchError(this.handleError));
  }

  enrollStatusStax(MId) {
    return this.http
      .get(`${environment.API_URL}/stax-enrollment/merchant/${MId}/registration`)
      .pipe(catchError(this.handleError));
  }

  enrollassumeStax(MId) {
    return this.http
      .post(`${environment.API_URL}/stax-enrollment/merchant/${MId}/assume`, {})
      .pipe(catchError(this.handleError));
  }

  getStaxMerchantapiKey(StaxMerchantID, obj) {
    return this.http
      .post(`${environment.API_URL}/stax-enrollment/merchant/${StaxMerchantID}/apikey`, obj)
      .pipe(catchError(this.handleError));
  }

  getMerchantDetails(StaxMerchantID) {
    return this.http
      .get(`${environment.API_URL}/stax-enrollment/merchant/${StaxMerchantID}`)
      .pipe(catchError(this.handleError));
  }

  getTransactionDetails(token, params) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      accept: 'application/json'
    });
    let options = { headers: headers };
    return this.http
      .get(`${environment.STAX_API}/transaction${params}`, options)
      .pipe(catchError(this.handleError));
  }

  // getSSOToken(token, params) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.get(`${environment.STAX_API}/ephemeral${params}`, options).pipe(catchError(this.handleError));
  // }

  // sTaxRefund(token, id, obj) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.post(`${environment.STAX_API}/transaction/${id}/refund`, obj, options).pipe(catchError(this.handleError));
  // }

  // sTaxVoid(token, id, obj) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.post(`${environment.STAX_API}/transaction/${id}/void`, obj, options).pipe(catchError(this.handleError));
  // }

  processIShareClients(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/processIShareClients`, postData)
      .pipe(catchError(this.handleError));
  }

  processIShareFolders(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/processIShareFolders`, postData)
      .pipe(catchError(this.handleError));
  }

  processMangoClients(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/processMangoClients`, postData)
      .pipe(catchError(this.handleError));
  }

  processMangoFolders(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/processMangoFolders`, postData)
      .pipe(catchError(this.handleError));
  }

  syncIShareMangofolders(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/syncIShareMangofolders`, postData)
      .pipe(catchError(this.handleError));
  }

  createCreditCardDetails(postData) {
    return this.http
      .post(`${environment.API_URL}/Stax/createCreditCardDetails`, postData)
      .pipe(catchError(this.handleError));
  }

  updateCreditCardDetails(customerCardID, obj) {
    return this.http
      .put(`${environment.API_URL}/Stax/updateCreditCardDetails/${customerCardID}`, obj)
      .pipe(catchError(this.handleError));
  }

  performStaxActions(postData, CID, eventType) {
    return this.http
      .post(`${environment.API_URL}/stax/${CID}/${eventType}`, postData)
      .pipe(catchError(this.handleError));
  }

  performStaxClickPayActions(postData, CID, eventType) {
    return this.http
      .post(`${environment.API_URL}/stax/mobile/${CID}/${eventType}`, postData)
      .pipe(catchError(this.handleError));
  }

  // updateStaxTransaction(id, obj, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.put(`${environment.STAX_API}/transaction/${id}`, obj, options).pipe(catchError(this.handleError));
  // }

  getUsrSelFiltersByUserId(id) {
    return this.http
      .get(`${environment.API_URL}/staff/users/selectedFilters/${id}`)
      .pipe(catchError(this.handleError));
  }

  updateUsrSelFiltersByUserId(id, obj: any) {
    return this.http
      .put(`${environment.API_URL}/staff/users/selectedFilters/${id}`, obj)
      .pipe(catchError(this.handleError));
  }

  mapIShareMangoClients(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/mapIShareMangoClients`, postData)
      .pipe(catchError(this.handleError));
  }

  mapIShareMangoFolders(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/mapIShareMangoFolders`, postData)
      .pipe(catchError(this.handleError));
  }

  validateapiKey(postData) {
    return this.http
      .post(`${environment.API_URL}/dms/IS/validateapiKey`, postData)
      .pipe(catchError(this.handleError));
  }

  validateIShareKey(postData) {
    return this.http
      .post(`${environment.IS_API_URL}/mango/validateapiKey`, postData)
      .pipe(catchError(this.handleError));
  }

  getRelatedPaymentByBHID(billingHeaderID, clientID) {
    return this.http
      .get(`${environment.API_URL}/staff/getRelatedPayment/${billingHeaderID}/${clientID}`)
      .pipe(catchError(this.handleError));
  }

  outlookWebhookRegistration(staffID) {
    return this.http
      .get(`${environment.API_URL}/staff/outlook/webhookRegistration/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  outlookDeleteWebhook(staffID) {
    return this.http
      .get(`${environment.API_URL}/staff/outlook/deleteWebhook/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  syncIShareMangoClients(postData) {
    return this.http
      .post(`${environment.API_URL}/vendor/syncIShareMangoClients`, postData)
      .pipe(catchError(this.handleError));
  }

  getPaymentHeaderAndDetailsByStaxID(StaxId) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/getPaymentHeaderAndDetailsByStaxID/${StaxId}`)
      .pipe(catchError(this.handleError));
  }

  getPaymentDetailsByToken(token) {
    return this.http
      .get(`${environment.API_URL}/stax/getPaymentDetailsByToken/${token}`)
      .pipe(catchError(this.handleError));
  }

  getShareDetailsByToken(token) {
    return this.http
      .post(`${environment.API_URL}/dms/getShareDetailsByToken/${token}`, {})
      .pipe(catchError(this.handleError));
  }

  deleteBillingHeaderMobilePayment(id, cid) {
    return this.http
      .delete(`${environment.API_URL}/cash-receipts/mobilepayment/billing-header/${id}/${cid}`)
      .pipe(catchError(this.handleError));
  }

  billingHeaderMobilePayment(obj, billingHeaderID) {
    return this.http
      .put(
        `${environment.API_URL}/cash-receipts/mobilepayment/billing-header/${billingHeaderID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  createBillingHeaderMobilePayment(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/mobilepayment/billing-header`, obj)
      .pipe(catchError(this.handleError));
  }

  createMobilePayPaymentHeader(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/mobilepayment/payment-header`, obj)
      .pipe(catchError(this.handleError));
  }

  createMobilePayPaymentDetail(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/mobilepayment/payment-detail`, obj)
      .pipe(catchError(this.handleError));
  }

  getDepositedPaymentHeaderById(paymentToBankID: number) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/payment-header/payment-to-bank/${paymentToBankID}`)
      .pipe(catchError(this.handleError));
  }

  getDepositedById(paymentToBankID: number) {
    return this.http
      .get(`${environment.API_URL}/cash-receipts/payment-to-bank/single/${paymentToBankID}`)
      .pipe(catchError(this.handleError));
  }

  updateDepositedById(paymentToBankID: number, obj) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-to-bank/${paymentToBankID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteDepositedByPaymentToBankID(paymentToBankID: number) {
    return this.http
      .delete(`${environment.API_URL}/cash-receipts/payment-to-bank/${paymentToBankID}`)
      .pipe(catchError(this.handleError));
  }

  reverseDeposit(paymentToBankID, obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-to-bank/reverse/${paymentToBankID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getDMSArchivedFiles(clientId) {
    return this.http
      .get(`${environment.API_URL}/fileManager/getDMSArchivedFiles/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  updateArchivedFiles(clientId, obj) {
    return this.http
      .put(`${environment.API_URL}/fileManager/updateArchivedFiles/${clientId}`, obj)
      .pipe(catchError(this.handleError));
  }

  removeArchivedFiles(clientId, obj) {
    return this.http
      .put(`${environment.API_URL}/fileManager/removeArchivedFiles/${clientId}`, obj)
      .pipe(catchError(this.handleError));
  }

  saveDMSShareToken(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/saveDMSShareToken`, obj)
      .pipe(catchError(this.handleError));
  }

  getTaskStatusSummary(companyID, staffID, dateRange) {
    return this.http
      .get(
        `${environment.API_URL}/ddm/ddmDashboardMain/taskStatusSummary/${companyID}/${staffID}/${dateRange}`
      )
      .pipe(catchError(this.handleError));
  }

  getTaskSummary(companyID, staffID, dateRange) {
    return this.http
      .get(
        `${environment.API_URL}/ddm/ddmDashboardMain/taskSummary/${companyID}/${staffID}/${dateRange}`
      )
      .pipe(catchError(this.handleError));
  }

  getTotalAndCompletedTasks() {
    return this.http
      .get(`${environment.API_URL}/ddm/getTotalAndCompletedTasks`)
      .pipe(catchError(this.handleError));
  }

  verifyValidUrl(token) {
    return this.http
      .get(`${environment.API_URL}/dms/verifyValidUrl/${token}`)
      .pipe(catchError(this.handleError));
  }

  verifyQuestionandAnswer(obj) {
    return this.http
      .post(`${environment.API_URL}/dms/verifyQuestionandAnswer/${obj.PublicURL}`, obj)
      .pipe(catchError(this.handleError));
  }

  getFirmDetails(token) {
    return this.http
      .get(`${environment.API_URL}/dms/getFirmDetails/${token}`)
      .pipe(catchError(this.handleError));
  }

  createLinkshareEmail(obj, companyID) {
    return this.http
      .post(`${environment.API_URL}/company/createLinkshareEmail/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getLinkshareEmails(companyID) {
    return this.http
      .get(`${environment.API_URL}/dms/getLinkshareEmails/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getMicrosoftLogin() {
    return this.http
      .get(`${environment.API_URL}/staff/microsoft/loginUrl`)
      .pipe(catchError(this.handleError));
  }

  loginWithMicrosoftToken(body) {
    return this.http
      .post(`${environment.API_URL}/staff/microsoft/loginWithToken`, body)
      .pipe(catchError(this.handleError));
  }

  getStaffMSSettings(staffID) {
    return this.http
      .get(`${environment.API_URL}/staff/StaffMSSettings/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  getEnableMSLoginUrl() {
    return this.http
      .get(`${environment.API_URL}/staff/microsoft/enableMSLoginUrl`)
      .pipe(catchError(this.handleError));
  }

  updateMSLoginSettings(obj, staffID) {
    return this.http
      .put(`${environment.API_URL}/staff/microsoft/updateMSLoginSettings/${staffID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getUserDetailsByCode(body) {
    return this.http
      .post(`${environment.API_URL}/staff/microsoft/userDetailsByCode`, body)
      .pipe(catchError(this.handleError));
  }

  setupTFA(body) {
    return this.http
      .post(`${environment.API_URL}/client/tfa/setup`, body)
      .pipe(catchError(this.handleError));
  }

  getTFA() {
    return this.http
      .get(`${environment.API_URL}/client/tfa/setup`)
      .pipe(catchError(this.handleError));
  }

  deleteTFA(staffID) {
    return this.http
      .delete(`${environment.API_URL}/client/tfa/setup/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  verifyTFA(body) {
    return this.http
      .post(`${environment.API_URL}/client/tfa/verify`, body)
      .pipe(catchError(this.handleError));
  }

  getIsEnableTFA(companyID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/isEnableTFA/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  deleteCompanyByID(companyID) {
    return this.http
      .delete(`${environment.API_URL}/company/deleteCompanyByID/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getIsMicrosoftLogin(companyID) {
    return this.http
      .get(`${environment.API_URL}/company/setting/isMicrosoftLogin/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getMangoIshareClients(obj) {
    return this.http
      .post(`${environment.API_URL}/company/getIShareCompanyData`, obj)
      .pipe(catchError(this.handleError));
  }

  getEmailNamesByCompanyID(companyID, isStatement?) {
    if (!isStatement) isStatement = null;
    return this.http
      .get(`${environment.API_URL}/client/getEmailNamesByCompanyID/${companyID}/${isStatement}`)
      .pipe(catchError(this.handleError));
  }

  getContactsByCompanyID(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/contactsByCompanyID/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  updateSlipmasterByBillingHeaderID(billingHeaderID, obj) {
    return this.http
      .put(`${environment.API_URL}/company/slipmaster/${billingHeaderID}`, obj)
      .pipe(catchError(this.handleError));
  }

  sendUserEmailInvite(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/sendUserEmailInvite`, obj)
      .pipe(catchError(this.handleError));
  }

  getRateTemplateNames(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/client/rateTemplateNames/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  checkRateTemplateNames(rateTemplateNameId) {
    return this.http
      .get(`${environment.API_URL}/staff/client/rateTemplateNames/check/${rateTemplateNameId}`)
      .pipe(catchError(this.handleError));
  }

  getRateTemplateDetails(companyID, rateTemplateNameId) {
    return this.http
      .get(
        `${environment.API_URL}/staff/client/rateTemplateDetails/${companyID}/${rateTemplateNameId}`
      )
      .pipe(catchError(this.handleError));
  }

  addRateTemplateDetail(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/client/rateTemplateDetails`, obj)
      .pipe(catchError(this.handleError));
  }

  putRateTemplateDetail(rateTemplateDetailsId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/rateTemplateDetails/${rateTemplateDetailsId}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteRateTemplateDetail(rateTemplateDetailsId) {
    return this.http
      .delete(`${environment.API_URL}/staff/client/rateTemplateDetails/${rateTemplateDetailsId}`)
      .pipe(catchError(this.handleError));
  }

  rateTemplateDetailBulkDelete(data) {
    return this.http
      .put(`${environment.API_URL}/staff/client/rateTemplateDetails/bulkDelete`, data)
      .pipe(catchError(this.handleError));
  }

  getRateLevelExceptions(companyID, rateTemplateNameId) {
    return this.http
      .get(
        `${environment.API_URL}/staff/client/rateLevelExceptions/${companyID}/${rateTemplateNameId}`
      )
      .pipe(catchError(this.handleError));
  }

  getRateLevels(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/client/rateLevels/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getStaffRateLevels(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/client/staffRateLevels/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  putStaffRateLevel(staffId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/staffRateLevels/${staffId}`, obj)
      .pipe(catchError(this.handleError));
  }

  getClientRateTemplates(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/client/clientRateTemplates/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  putClientRateTemplates(clientId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/clientRateTemplates/${clientId}`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateClientRateTemplates(obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/clientRateTemplates/bulkUpdate`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateStaffRateLevels(obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/staffRateLevels/bulkUpdate`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteRateTemplateName(rateTemplateNamesId) {
    return this.http
      .delete(`${environment.API_URL}/staff/client/rateTemplateNames/${rateTemplateNamesId}`)
      .pipe(catchError(this.handleError));
  }

  deleteRateLevel(rateLevelId) {
    return this.http
      .delete(`${environment.API_URL}/staff/client/rateLevels/${rateLevelId}`)
      .pipe(catchError(this.handleError));
  }

  addRateLevel(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/client/rateLevels`, obj)
      .pipe(catchError(this.handleError));
  }

  putRateLevel(obj, rateLevelId) {
    return this.http
      .put(`${environment.API_URL}/staff/client/rateLevels/${rateLevelId}`, obj)
      .pipe(catchError(this.handleError));
  }

  addRateTemplateName(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/client/rateTemplateNames`, obj)
      .pipe(catchError(this.handleError));
  }

  putRateTemplateName(rateTemplateNamesId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/rateTemplateNames/${rateTemplateNamesId}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteRateLevelExceptions(rateLevelExceptionsId) {
    return this.http
      .delete(`${environment.API_URL}/staff/client/rateLevelExceptions/${rateLevelExceptionsId}`)
      .pipe(catchError(this.handleError));
  }

  putRateLevelExceptions(obj, rateLevelExceptionsId) {
    return this.http
      .put(`${environment.API_URL}/staff/client/rateLevelExceptions/${rateLevelExceptionsId}`, obj)
      .pipe(catchError(this.handleError));
  }

  postRateLevelExceptions(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/client/rateLevelExceptions`, obj)
      .pipe(catchError(this.handleError));
  }

  updatePaymentDetail(obj, paymentDetailID) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-detail/${paymentDetailID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getActivityRateTemplates(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/client/activityRateTemplates/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  putActivityRateTemplate(serviceCodeId, obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/activityRateTemplates/${serviceCodeId}`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateActivityRateTemplates(obj) {
    return this.http
      .put(`${environment.API_URL}/staff/client/activityRateTemplates/bulkUpdate`, obj)
      .pipe(catchError(this.handleError));
  }

  getAppointmentByClientID(clientID, range) {
    return this.http
      .get(`${environment.API_URL}/staff/calendar/appointmentsByClientID/${clientID}/${range}`)
      .pipe(catchError(this.handleError));
  }

  updateLastLateFeeDate(companyID, obj) {
    return this.http
      .put(`${environment.API_URL}/invoice/lastLateFeeDate/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getStaffOtherSettings() {
    return this.http
      .get(`${environment.API_URL}/staff/other-settings`)
      .pipe(catchError(this.handleError));
  }

  getAllDepositsByCompanyID(obj) {
    return this.http
      .get(
        `${environment.API_URL}/cash-receipts/payment-to-bank/deposits/${obj.CompanyID}/${obj.DateFrom}/${obj.DateTo}/${obj.BankID}`
      )
      .pipe(catchError(this.handleError));
  }

  getAllPHeaderByPaymentToBankID(paymentToBankID) {
    return this.http
      .get(
        `${environment.API_URL}/cash-receipts/payment-header/payment-to-bank-details/${paymentToBankID}`
      )
      .pipe(catchError(this.handleError));
  }

  createMangoData(companyID, staffId) {
    return this.http
      .get(`${environment.API_URL}/company/insertDummyData/${companyID}/${staffId}`)
      .pipe(catchError(this.handleError));
  }

  deleteMangoData(companyID) {
    return this.http
      .get(`${environment.API_URL}/company/deleteDummyData/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  batchCreateTimeRecords(obj) {
    return this.http
      .post(`${environment.API_URL}/company/batch-time-sheet`, obj)
      .pipe(catchError(this.handleError));
  }

  batchCreateBillingDetails(obj) {
    return this.http
      .post(`${environment.API_URL}/billingDetail/batch-create`, obj)
      .pipe(catchError(this.handleError));
  }

  createUpdateHistory(obj) {
    return this.http
      .post(`${environment.API_URL}/update-history`, obj)
      .pipe(catchError(this.handleError));
  }

  createBatchUpdateHistory(obj) {
    return this.http
      .post(`${environment.API_URL}/update-history/batch`, obj)
      .pipe(catchError(this.handleError));
  }

  getAllUpdateHistories(dateRange) {
    return this.http
      .get(`${environment.API_URL}/update-history/${dateRange.From}/${dateRange.To}`)
      .pipe(catchError(this.handleError));
  }

  editUpdateHistory(obj, updateHistoryID) {
    delete obj.UpdateHistoryID;
    return this.http
      .put(`${environment.API_URL}/update-history/${updateHistoryID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteUpdateHistory(updateHistoryID) {
    return this.http
      .delete(`${environment.API_URL}/update-history/${updateHistoryID}`)
      .pipe(catchError(this.handleError));
  }

  createFeatureRequest(obj) {
    return this.http
      .post(`${environment.API_URL}/feature-request`, obj)
      .pipe(catchError(this.handleError));
  }

  getAllFeatureRequests(dateRange) {
    return this.http
      .get(`${environment.API_URL}/feature-request/${dateRange.From}/${dateRange.To}`)
      .pipe(catchError(this.handleError));
  }

  updateClientContacts(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/updateClientContacts/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  updateInvalidEmails(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/updateInvalidEmails/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getTimeRecordWithoutBD(billingHeaderID) {
    return this.http
      .get(`${environment.API_URL}/company/timeRecordWithoutBD/${billingHeaderID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getTimeRecordWithoutBDSuperAdmin(billingHeaderID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/timeRecordWithoutBD/${billingHeaderID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getTimeRecordsByBHID(billingHeaderID) {
    return this.http
      .get(`${environment.API_URL}/company/timeRecordsByBHID/${billingHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  getTimeRecordsByBHIDSuperAdmin(billingHeaderID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/timeRecordsByBHID/${billingHeaderID}`)
      .pipe(catchError(this.handleError));
  }

  getLastEndTime(currDate) {
    return this.http
      .get(`${environment.API_URL}/staff/last-end-time/${currDate}`)
      .pipe(catchError(this.handleError));
  }

  reversePaymentDetails(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-headers/reverse-payment-details`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateBulkProjectHeader(postData) {
    return this.http
      .post(`${environment.API_URL}/ddm/bulkUpdateBulkProjectHeader`, postData)
      .pipe(catchError(this.handleError));
  }

  bulkUpdateBulkProjectDetails(postData) {
    return this.http
      .post(`${environment.API_URL}/ddm/bulkUpdateBulkProjectDetails`, postData)
      .pipe(catchError(this.handleError));
  }

  reversePayment(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-headers/reverse-payment`, obj)
      .pipe(catchError(this.handleError));
  }

  getEmailcontactsByClientID(id) {
    return this.http
      .get(`${environment.API_URL}/client/contact/[${id}]`)
      .pipe(catchError(this.handleError));
  }

  getPaymentMethodInfo(clientId) {
    return this.http
      .get(`${environment.API_URL}/Stax/getPaymentMethodInfo/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  bulkUpdatePaymentHeader(obj) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/payment-headers/bulkUpdatePaymentHeader`, obj)
      .pipe(catchError(this.handleError));
  }

  staxUpdateRecurringInvoiceHeader(recuuringHeaderId, obj) {
    return this.http
      .put(`${environment.API_URL}/Stax/staxUpdateRecurringInvoiceHeader/${recuuringHeaderId}`, obj)
      .pipe(catchError(this.handleError));
  }

  // createScheduledInvoice(postData, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.post(`${environment.STAX_API}/invoice/schedule/`, postData, options).pipe(catchError(this.handleError));
  // }

  // deleteScheduledInvoice(scheduleId, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.delete(`${environment.STAX_API}/invoice/schedule/${scheduleId}`, options).pipe(catchError(this.handleError));
  // }

  // updateScheduledInvoice(scheduleId, data, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.put(`${environment.STAX_API}/invoice/schedule/${scheduleId}`, data, options).pipe(catchError(this.handleError));
  // }

  // createTransactionEvent(postData, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.post(`${environment.STAX_API}/webhook`, postData, options).pipe(catchError(this.handleError));
  // }

  // getScheduledInvoice(scheduleId, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.get(`${environment.STAX_API}/invoice/schedule/${scheduleId}`, options).pipe(catchError(this.handleError));
  // }

  // getwebhooksEvents(StaxMerchantAPIKey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + StaxMerchantAPIKey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.get(`${environment.STAX_API}/webhook`, options).pipe(catchError(this.handleError));
  // }

  getUnassignedProjectsByClientID(clientID) {
    return this.http
      .get(`${environment.API_URL}/ddm/unassigned-projects/${clientID}`)
      .pipe(catchError(this.handleError));
  }

  getBudgets(budgetYear, projectMasterID, clientID, ddmProjectHeaderID, companyID) {
    return this.http
      .get(
        `${environment.API_URL}/budgets/${budgetYear}/${projectMasterID}/${clientID}/${ddmProjectHeaderID}/${companyID}`
      )
      .pipe(catchError(this.handleError));
  }

  updateBudget(obj, budgetsID, companyID) {
    return this.http
      .put(`${environment.API_URL}/budgets/${budgetsID}/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  createBudget(obj) {
    return this.http.post(`${environment.API_URL}/budgets`, obj).pipe(catchError(this.handleError));
  }

  deleteBudget(budgetsID, companyID) {
    return this.http
      .delete(`${environment.API_URL}/budgets/${budgetsID}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  updateTimeRecordsByDDMProjectHeaderID(obj, ddmProjectHeaderID, companyID) {
    return this.http
      .put(
        `${environment.API_URL}/company/timeRecordsByDDMProjectHeader/${ddmProjectHeaderID}/${companyID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  sendBudgetAlert(obj) {
    return this.http
      .post(`${environment.API_URL}/budgets/alert`, obj)
      .pipe(catchError(this.handleError));
  }

  getCompanyDefaultNotifications(companyID) {
    return this.http
      .get(`${environment.API_URL}/budgets-notifications/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  updateCompanyDefaultNotification(obj, companyID) {
    return this.http
      .put(`${environment.API_URL}/budgets-notifications/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  bulkCreateBudgets(obj, companyID) {
    return this.http
      .post(`${environment.API_URL}/budgets/bulk-create/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteFeatureRequest(featureRequestID) {
    return this.http
      .delete(`${environment.API_URL}/feature-request/${featureRequestID}`)
      .pipe(catchError(this.handleError));
  }

  deleteFeatureRequestBatch(obj) {
    return this.http
      .post(`${environment.API_URL}/feature-request-batch/delete`, obj)
      .pipe(catchError(this.handleError));
  }

  updateFeatureRequestBatch(obj) {
    return this.http
      .post(`${environment.API_URL}/feature-request-batch/update`, obj)
      .pipe(catchError(this.handleError));
  }

  sendUserEmailForRequest(obj) {
    return this.http
      .post(`${environment.API_URL}/feature-request/email-user`, obj)
      .pipe(catchError(this.handleError));
  }

  getClientOrigAndBillingPartners(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/orig-and-billing-partners/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getBudgetsTasks(ddmProjectHeaderID, budgetsID, companyID) {
    return this.http
      .get(`${environment.API_URL}/budgets-tasks/${ddmProjectHeaderID}/${budgetsID}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  createBudgetsTasks(obj) {
    return this.http
      .post(`${environment.API_URL}/budgets-tasks`, obj)
      .pipe(catchError(this.handleError));
  }

  updateBudgetsTasks(obj, budgetsDetailID, companyID) {
    return this.http
      .put(`${environment.API_URL}/budgets-tasks/${budgetsDetailID}/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getBudgetsTasksTemplate(companyTemplateHeaderID, companyID) {
    return this.http
      .get(`${environment.API_URL}/budgets-tasks-template/${companyTemplateHeaderID}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  showCapacityPlanningMenuChange(isEnabled: boolean) {
    this.capacityPlanningMenu.next(isEnabled);
  }

  async showCapacityPlanningMenu(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/showCapacityPlanningMenu/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getUnassignedTask(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getUnassignedTask/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getStaffTask(companyID, staffID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getStaffTask/${companyID}/${staffID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getUnassignedTaskCountWeeks(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getUnassignedTasksCount/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getStaffTaskCountWeeks(companyID, staffID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getStaffTasksCount/${companyID}/${staffID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getStaffTaskAndTime(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getStaffTaskAndTime/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getUnassignedTaskAndTime(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getUnassignedTaskAndTime/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  batchUpdateProjectTemplateDetails(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/project-details-batch-update`, obj)
      .pipe(catchError(this.handleError));
  }

  getClientWithNoData(companyID) {
    return this.http
      .get(`${environment.API_URL}/staff/client/getClientsWithNoData/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getEngagementsWithNoData(companyID, searchText, lastId, limit) {
    return this.http
      .get(
        `${environment.API_URL}/company/projects/getProjectsWithNoData/${companyID}/${lastId}/${searchText}/${limit}`
      )
      .pipe(catchError(this.handleError));
  }

  getClientsWithNoEngagement(companyID, Inactive) {
    return this.http
      .get(`${environment.API_URL}/staff/client/clientWithNoEngagement/${companyID}/${Inactive}`)
      .pipe(catchError(this.handleError));
  }

  deleteClientsWithNoData(ClientIDArray) {
    let obj = { ClientIDArray: ClientIDArray };
    return this.http
      .put(`${environment.API_URL}/staff/clients/deleteClientWithNoData`, obj)
      .pipe(catchError(this.handleError));
  }

  getCustomersByEngagementTypeID(engagementTypeID, companyID) {
    return this.http
      .get(`${environment.API_URL}/clients-by-engagement-type/${engagementTypeID}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  updateClientLvlTimeBudgets(projectMasterID, companyID) {
    return this.http
      .get(`${environment.API_URL}/budgets-update-parent/${projectMasterID}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  rollOverBudgets(obj) {
    return this.http
      .post(`${environment.API_URL}/budgets-rollover`, obj)
      .pipe(catchError(this.handleError));
  }

  batchCreateOrUpdateBudgetDetails(list) {
    return this.http
      .post(`${environment.API_URL}/budgets/details-batch-create-update`, list)
      .pipe(catchError(this.handleError));
  }

  updateBudgetsByDDMProjectHeaderID(obj, ddmProjectHeaderID, companyID) {
    return this.http
      .put(
        `${environment.API_URL}/budgets-by-projheaderid/update/${ddmProjectHeaderID}/${companyID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  deleteEngagementsWithNoData(ids) {
    return this.http
      .put(`${environment.API_URL}/company/projects/deleteProjectsWithNoData`, ids)
      .pipe(catchError(this.handleError));
  }

  getDocCountByDate(startDate, endDate, companyID) {
    return this.http
      .get(`${environment.API_URL}/dms/doc-count-expense/${startDate}/${endDate}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getAllTodoStaff() {
    return this.http
      .get(`${environment.API_URL}/staff/getAllStaffList/all`)
      .pipe(catchError(this.handleError));
  }

  bulkEngagementUpdate(postData) {
    return this.http
      .post(`${environment.API_URL}/company/projects/bulkUpdateProjects`, postData)
      .pipe(catchError(this.handleError));
  }

  getSalesTaxByRecurringHeaderID(companyId, clientId, headerId) {
    return this.http
      .get(
        `${environment.API_URL}/Stax/getSalesTaxByRecurringHeaderID/${companyId}/${clientId}/${headerId}`
      )
      .pipe(catchError(this.handleError));
  }
  getSumOpenTimeAndExpense() {
    return this.http
      .get(`${environment.API_URL}/company/timeSheet/sum-open-time-expense`)
      .pipe(catchError(this.handleError));
  }

  getStaxPaymenMethod(customerId, apikey) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + apikey,
      'Content-Type': 'application/json',
      accept: 'application/json'
    });
    let options = { headers: headers };
    return this.http
      .get(`${environment.STAX_API}/customer/${customerId}/payment-method`, options)
      .pipe(catchError(this.handleError));
  }

  copyBudgetsToNewProject(postData) {
    return this.http
      .post(`${environment.API_URL}/budgets-for-new-project`, postData)
      .pipe(catchError(this.handleError));
  }

  getBudgetEngagementAndYearOptions(clientID, ddmProjectHeaderID, companyID) {
    return this.http
      .get(`${environment.API_URL}/budgets-years/${clientID}/${ddmProjectHeaderID}/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  // getCalculatedSurcharge(payment_method_id, amount, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.get(`${environment.STAX_API}/surcharge/review?payment_method_id=${payment_method_id}&total=${amount}`, options).pipe(catchError(this.handleError));
  // }

  // getCardType(payment_method_id, apikey) {
  //     const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + apikey, 'Content-Type': 'application/json', 'accept': 'application/json' });
  //     let options = { headers: headers }
  //     return this.http.get(`${environment.STAX_API}/payment-method/${payment_method_id}`, options).pipe(catchError(this.handleError));
  // }

  getIntegrationStatus(companyID) {
    return this.http
      .get(`${environment.API_URL}/vendor/getIntegrationStatus/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getPreviousBalance(clientID, invoiceDate, invoiceLessPayments) {
    return this.http
      .get(
        `${environment.API_URL}/cash-receipts/billing-header/getPreviousBalance/${clientID}/${invoiceDate}/${invoiceLessPayments}`
      )
      .pipe(catchError(this.handleError));
  }

  getUserLogs(from, to, companyId) {
    return this.http
      .get(`${environment.API_URL}/client/logs/${from}/${to}/${companyId}`)
      .pipe(catchError(this.handleError));
  }

  addUserLogs(data) {
    return this.http
      .post(`${environment.API_URL}/client/users/logs`, data)
      .pipe(catchError(this.handleError));
  }

  getUserPermissions() {
    return this.http
      .get(`${environment.API_URL}/user-permissions/master-permissions`)
      .pipe(catchError(this.handleError));
  }

  createStaffRole(postData) {
    return this.http
      .post(`${environment.API_URL}/user-permissions/staff-roles`, postData)
      .pipe(catchError(this.handleError));
  }

  updateStaffRole(obj, staffRoleID) {
    return this.http
      .put(`${environment.API_URL}/user-permissions/staff-roles/${staffRoleID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteStaffRole(staffRoleID) {
    return this.http
      .delete(`${environment.API_URL}/user-permissions/staff-roles/${staffRoleID}`)
      .pipe(catchError(this.handleError));
  }

  getUserPermissionsForDMS(clientID, dmsClientTemplateNameID) {
    return this.http
      .get(`${environment.API_URL}/user-permissions/dms/${clientID}/${dmsClientTemplateNameID}`)
      .pipe(catchError(this.handleError));
  }

  updateDmsStaffRoles(obj) {
    return this.http
      .post(`${environment.API_URL}/user-permissions/dms-staff-roles`, obj)
      .pipe(catchError(this.handleError));
  }

  updateDefaultDMSPermissions(obj) {
    return this.http
      .post(`${environment.API_URL}/user-permissions/dms-default-permissions`, obj)
      .pipe(catchError(this.handleError));
  }

  insertIntialStaffRoles(obj) {
    return this.http
      .post(`${environment.API_URL}/user-permissions/initial-staff-roles`, obj)
      .pipe(catchError(this.handleError));
  }

  updateUserExceptions(obj, staffID) {
    return this.http
      .put(`${environment.API_URL}/user-permissions/exceptions/${staffID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getUserExceptions(staffID) {
    return this.http
      .get(`${environment.API_URL}/user-permissions/exceptions/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  createTimer(data) {
    return this.http.post(`${environment.API_URL}/timer`, data).pipe(catchError(this.handleError));
  }

  updateTimer(data) {
    return this.http
      .put(`${environment.API_URL}/timer/${data.TimerID}`, data)
      .pipe(catchError(this.handleError));
  }

  getTimers(staffID) {
    return this.http
      .get(`${environment.API_URL}/timer/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  deleteTimer(timerID) {
    return this.http
      .delete(`${environment.API_URL}/timer/${timerID}`)
      .pipe(catchError(this.handleError));
  }

  createOrUpdateTimer(obj) {
    return this.http
      .post(`${environment.API_URL}/timer/create-or-update`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteAllTimers(staffID) {
    return this.http
      .delete(`${environment.API_URL}/timers/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  getBudgetTaskActualAmt(year, projectDetailsID) {
    return this.http
      .get(`${environment.API_URL}/budget-task/actual-amount/${year}/${projectDetailsID}`)
      .pipe(catchError(this.handleError));
  }

  updatePaymentDetailsByPaymentHeaderID(data, paymentHeaderID) {
    return this.http
      .put(
        `${environment.API_URL}/cash-receipts/payment-details-by-payment-header-id/${paymentHeaderID}`,
        data
      )
      .pipe(catchError(this.handleError));
  }

  recurringreGenarateMangoData(obj, loginCompanyId) {
    return this.http
      .post(`${environment.API_URL}/Stax/recurringPaymentCallback/${loginCompanyId}`, obj)
      .pipe(catchError(this.handleError));
  }

  getPermissionByStaffId(staffID) {
    return this.http
      .get(`${environment.API_URL}/user-permissions/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  getProjectsByStaffID(staffID) {
    return this.http
      .get(`${environment.API_URL}/company/projects/getProjectsByStaffID/${staffID}`)
      .pipe(catchError(this.handleError));
  }

  getHostedPaymentDetailsByToken(token) {
    return this.http
      .get(`${environment.API_URL}/hosted/getHostedPaymentDetailsByToken/${token}`)
      .pipe(catchError(this.handleError));
  }

  transferAdvancePayment(obj) {
    return this.http
      .post(`${environment.API_URL}/staff/transfer-advance`, obj)
      .pipe(catchError(this.handleError));
  }

  isHostedPaymentTokenValid(obj) {
    return this.http
      .post(`${environment.API_URL}/hosted/isHostedPaymentTokenValid`, obj)
      .pipe(catchError(this.handleError));
  }

  createHostedPaymentToken(obj) {
    return this.http
      .post(`${environment.API_URL}/hosted/createHostedPaymentToken`, obj)
      .pipe(catchError(this.handleError));
  }

  getOpenInvoices(clientId) {
    return this.http
      .get(`${environment.API_URL}/hosted/getOpenInvoices/${clientId}`)
      .pipe(catchError(this.handleError));
  }

  cloneProjectToAnotherClient(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/clone-project`, obj)
      .pipe(catchError(this.handleError));
  }

  awsBucketMaskUpload(option) {
    return this.http
      .post(
        `${environment.API_URL}/aws-bucket-mask/upload`,

        option
      )
      .pipe(catchError(this.handleError));
  }

  awsBucketMaskDeleteObjects(option) {
    return this.http
      .post(
        `${environment.API_URL}/aws-bucket-mask/delete-objects`,

        option
      )
      .pipe(catchError(this.handleError));
  }

  awsBucketMaskDeleteObject(option) {
    return this.http
      .post(
        `${environment.API_URL}/aws-bucket-mask/delete-object`,

        option
      )
      .pipe(catchError(this.handleError));
  }

  awsBucketMaskGetSignedUrl(namespace, option) {
    return this.http
      .post(
        `${environment.API_URL}/aws-bucket-mask/get-signed-url/${namespace}`,

        option
      )
      .pipe(catchError(this.handleError));
  }

  bulkUpdateBillingRate(obj) {
    return this.http
      .post(`${environment.API_URL}/company/timeSheet/bulkSave-billing-rate`, obj)
      .pipe(catchError(this.handleError));
  }

  reverseNSF(obj) {
    return this.http
      .post(`${environment.API_URL}/cash-receipts/payment-headers/reverse-nsf`, obj)
      .pipe(catchError(this.handleError));
  }

  addTrelloCard(obj) {
    return this.http
      .post(`${environment.API_URL}/trello/cards`, obj)
      .pipe(catchError(this.handleError));
  }

  getAdminUserCredentails(userId) {
    return this.http
      .get(`${environment.API_URL}/superadmin/user-creds/getUserCreds/${userId}`)
      .pipe(catchError(this.handleError));
  }

  updateUserPassword(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/superadmin/user-creds/updatePassword/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  sendTrialWelcomeEmail(obj) {
    return this.http
      .post(`${environment.API_URL}/sendinblue/send-welcome-email`, obj)
      .pipe(catchError(this.handleError));
  }

  verifyISToken(obj, isSyncIShareClients = false) {
    return this.http
      .post(`${environment.API_URL}/integration/IS/verify/${isSyncIShareClients}`, obj)
      .pipe(catchError(this.handleError));
  }

  removeISToken() {
    return this.http
      .delete(`${environment.API_URL}/integration/IS/remove-token`)
      .pipe(catchError(this.handleError));
  }

  loadIShareSettings() {
    return this.http
      .get(`${environment.API_URL}/integration/IS/settings`)
      .pipe(catchError(this.handleError));
  }

  activateOrDeactivateIntegration(obj) {
    return this.http.put(
      `${environment.API_URL}/integration/IS/activate-deactivate-integration`,
      obj
    );
  }

  getAllELTerms() {
    return this.http.get(`${environment.API_URL}/el-term/all`).pipe(catchError(this.handleError));
  }

  getELTermByID(id) {
    return this.http.get(`${environment.API_URL}/el-term/${id}`).pipe(catchError(this.handleError));
  }

  createELTerm(data): Observable<any> {
    return this.http
      .post(`${environment.API_URL}/el-term/create`, data)
      .pipe(catchError(this.handleError));
  }

  updateELTerm(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/el-term/update/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  deleteELTerm(id): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}/el-term/delete/${id}`)
      .pipe(catchError(this.handleError));
  }

  getELEmailTemplates(): Observable<any> {
    return this.http.get(`${environment.API_URL}/el-email`).pipe(catchError(this.handleError));
  }

  getELEmailTemplateById(id): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/el-email/${id}`)
      .pipe(catchError(this.handleError));
  }

  getELEmailsById(id): Observable<any> {
    return this.http
      .get(`${environment.API_URL}/el-email/el-emails/${id}`)
      .pipe(catchError(this.handleError));
  }

  addELEmailTemplate(obj): Observable<any> {
    return this.http
      .post(`${environment.API_URL}/el-email`, obj)
      .pipe(catchError(this.handleError));
  }

  updateELEmailTemplates(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/el-email/${id}`, data)
      .pipe(catchError(this.handleError));
  }
  getAllELServices() {
    return this.http
      .get(`${environment.API_URL}/el-service/all`)
      .pipe(catchError(this.handleError));
  }

  getELServiceByID(id) {
    return this.http
      .get(`${environment.API_URL}/el-service/${id}`)
      .pipe(catchError(this.handleError));
  }

  createELService(data): Observable<any> {
    return this.http
      .post(`${environment.API_URL}/el-service/create`, data)
      .pipe(catchError(this.handleError));
  }

  updateELService(id, data): Observable<any> {
    return this.http
      .put(`${environment.API_URL}/el-service/update/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  deleteELService(id): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}/el-service/delete/${id}`)
      .pipe(catchError(this.handleError));
  }

  getELSettings() {
    return this.http.get(`${environment.API_URL}/el-settings`).pipe(catchError(this.handleError));
  }

  updateELSettings(id, data) {
    return this.http
      .put(`${environment.API_URL}/el-settings/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  createELSettings(data) {
    return this.http
      .post(`${environment.API_URL}/el-settings`, data)
      .pipe(catchError(this.handleError));
  }

  getELProposals(params) {
    return this.http
      .get(
        `${environment.API_URL}/el-proposals?client=${
          params?.selClient?.ClientID ?? 'All'
        }&startDate=${params.startDate ?? 'All'}&expiring=${params.dueDate ?? 'All'}&dateFrom=${
          params.dateFrom ?? ''
        }&dateTo=${params.dateTo ?? ''}&createdDate=${params.createdDate ?? 'All'}&status=${
          params.status ?? 'All'
        }&limit=${params.limit ?? null}`
      )
      .pipe(catchError(this.handleError));
  }

  getELProposalSummary() {
    return this.http
      .get(`${environment.API_URL}/el-proposal-summary`)
      .pipe(catchError(this.handleError));
  }

  getELClientCount() {
    return this.http
      .get(`${environment.API_URL}/el-proposal-client-count`)
      .pipe(catchError(this.handleError));
  }

  createELProposal(obj) {
    return this.http
      .post(`${environment.API_URL}/el-proposals/create-proposal`, obj)
      .pipe(catchError(this.handleError));
  }

  updateELProposal(obj) {
    return this.http
      .put(`${environment.API_URL}/el-proposal/${obj.ELProposalID}`, obj)
      .pipe(catchError(this.handleError));
  }

  updateClientELBilling(body) {
    return this.http
      .put(`${environment.API_URL}/el-proposal/client/${body.ClientID}`, body)
      .pipe(catchError(this.handleError));
  }

  getELProposalAuditsById(id) {
    return this.http
      .get(`${environment.API_URL}/el-proposal-audit/get-proposal-audit-records/${id}`)
      .pipe(catchError(this.handleError));
  }

  createELProposalAudit(obj) {
    return this.http
      .post(`${environment.API_URL}/el-proposal-audit/create-proposal-audit-record`, obj)
      .pipe(catchError(this.handleError));
  }

  fetchClientBilling(dueDate, dateFrom, dateTo) {
    return this.http
      .get(`${environment.API_URL}/el-proposal/fetch-billing-logs/${dueDate}/${dateFrom}/${dateTo}`)
      .pipe(catchError(this.handleError));
  }

  getIShareClients() {
    return this.http
      .get(`${environment.API_URL}/integration/IS/clients`)
      .pipe(catchError(this.handleError));
  }

  syncMappedClients(obj) {
    return this.http
      .post(`${environment.API_URL}/integration/IS/sync-mapped-clients`, obj)
      .pipe(catchError(this.handleError));
  }

  getClientsForDMS() {
    return this.http
      .get(`${environment.API_URL}/staff/getAllClientListWithClientNameClientId`)
      .pipe(catchError(this.handleError));
  }

  getCsvImportLogsById(csvId) {
    return this.http
      .get(`${environment.API_URL}/csv-import-log/${csvId}`)
      .pipe(catchError(this.handleError));
  }

  async fetchEmailBody(obj): Promise<any> {
    try {
      return await this.http
        .post(`${environment.API_URL}/sendinblue/email-with-content`, obj)
        .pipe(catchError(this.handleError))
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  getLocationUseById(CompanyMangoLocationID: number) {
    return this.http
      .get(`${environment.API_URL}/company/setting/getLocationUseById/${CompanyMangoLocationID}`)
      .pipe(catchError(this.handleError));
  }

  copyTemplatesToCompany(companyId) {
    return this.http
      .post(`${environment.API_URL}/el-configure/${companyId}`, {})
      .pipe(catchError(this.handleError));
  }

  exportDocumentsToIShare(obj) {
    return this.http
      .post(`${environment.API_URL}/integration/IS/export-documents`, obj)
      .pipe(catchError(this.handleError));
  }

  getELEmailMasterTemplates() {
    return this.http
      .get(`${environment.API_URL}/el-proposals/master-template/email`)
      .pipe(catchError(this.handleError));
  }

  saveELEmailMasterTemplates(obj) {
    return this.http
      .put(`${environment.API_URL}/el-proposals/master-template/email`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteELMasterTemplates(type, id) {
    return this.http
      .delete(`${environment.API_URL}/el-proposals/master-template/${type}/${id}`)
      .pipe(catchError(this.handleError));
  }

  getELServiceMasterTemplates() {
    return this.http
      .get(`${environment.API_URL}/el-proposals/master-template/service`)
      .pipe(catchError(this.handleError));
  }

  saveELServiceMasterTemplates(obj) {
    return this.http
      .put(`${environment.API_URL}/el-proposals/master-template/service`, obj)
      .pipe(catchError(this.handleError));
  }

  getELTermMasterTemplates() {
    return this.http
      .get(`${environment.API_URL}/el-proposals/master-template/term`)
      .pipe(catchError(this.handleError));
  }

  saveELTermMasterTemplates(obj) {
    return this.http
      .put(`${environment.API_URL}/el-proposals/master-template/term`, obj)
      .pipe(catchError(this.handleError));
  }

  getIShareUserApiToken() {
    return this.http.get(`${environment.API_URL}/integration/IS/user-api-token`);
  }
  updateInvoiceDateByBillingHeaderID(id, data) {
    return this.http
      .put(`${environment.API_URL}/cash-receipts/update-invoice-date/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  countBillingWorksheets(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/count-billing-worksheets`, obj)
      .pipe(catchError(this.handleError));
  }

  countClientProfitSummary(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/count-client-profit-summary`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getOpenTimeAgingSummaryByBalance(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/count-open-time-aging-summary-by-balance`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  getWIPDetail(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/count-wip-detail`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  countUserBillableNonBillableHours(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/count-billable-nonBillableHours`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  countClientStatementBatchPayment(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/count-client-statement-batch-payment`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  generateReportForEmail(obj) {
    return this.http
      .post(`${environment.API_URL}/reports/generate-report-for-email`, obj)
      .pipe(catchError(this.handleError));
  }


  createISFolder(obj) {
    return this.http
      .post(`${environment.API_URL}/integration/IS/create-folder`, obj)
      .pipe(catchError(this.handleError));
  }

  sendEmailToNextUserAssigned(obj) {
    return this.http
      .post(`${environment.API_URL}/ddm/send-email-to-next-user`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteProposal(id) {
    return this.http
      .delete(`${environment.API_URL}/el-proposal/${id}`)
      .pipe(catchError(this.handleError));
  }

  toggleDMSIShare(obj) {
    return this.http.put(`${environment.API_URL}/integration/IS/activate-deactivate-dms`, obj);
  }

  async nylasAuthLinkGenerator(obj) {
    return this.http.post(`${environment.API_URL}/nylas/generate-auth-url`, obj).toPromise();
  }

  getNylasEmails(page, limit, folder, nylas_id) {
    return this.http.get(
      `${environment.API_URL}/nylas/get-emails/${page}/${limit}/${folder}/${nylas_id}`
    );
  }

  getEmailByFilter(id, text) {
    return this.http.get(
      `${environment.API_URL}/nylas/get-email-addresses-filter?BillingPartnerID=${id}&EngagementName=${text}`
    );
  }

  readNylasEmailByMessageID(messageID, id) {
    return this.http.get(`${environment.API_URL}/nylas/read-email/${messageID}/${id}`);
  }

  async getNylasEmailFileByFileID(fileID, id) {
    return this.http.get(`${environment.API_URL}/nylas/get-email-file/${fileID}/${id}`).toPromise();
  }

  sendNylasEmail(obj) {
    return this.http.post(`${environment.API_URL}/nylas/send-email`, obj);
  }

  checkAllowCompanyEmials() {
    return this.http.get(`${environment.API_URL}/nylas/check-company-emails-role`).toPromise();
  }

  getNylasReadContacts() {
    return this.http.get(`${environment.API_URL}/nylas/get-all-read-contacts`).toPromise();
  }

  getNylasUnreadContacts() {
    return this.http.get(`${environment.API_URL}/nylas/get-all-unread-contacts`).toPromise();
  }

  addUnreadContact(obj) {
    return this.http.put(`${environment.API_URL}/nylas/add-unread-contact`, obj).toPromise();
  }

  removeUnreadContact(obj) {
    return this.http.put(`${environment.API_URL}/nylas/remove-unread-contact`, obj).toPromise();
  }

  async getNylasAllContactsEnableAndRead() {
    return this.http.get(`${environment.API_URL}/nylas/get-all-enable-read-contacts`).toPromise();
  }

  async getNylasContacts() {
    return this.http.get(`${environment.API_URL}/nylas/get-all-contacts`).toPromise();
  }

  async getNylasContact() {
    return this.http.get(`${environment.API_URL}/nylas/get-contact`).toPromise();
  }

  async updateNylasContact(obj) {
    return this.http.put(`${environment.API_URL}/nylas/update-contact`, obj).toPromise();
  }

  async getNylasContactsByStaff() {
    return this.http.get(`${environment.API_URL}/nylas/get-all-staff-contacts`).toPromise();
  }

  async getNylasReadContactsByStaff() {
    return this.http.get(`${environment.API_URL}/nylas/get-all-read-staff-contacts`).toPromise();
  }

  getIsDMSIshareValue() {
    return this.http
      .get(`${environment.API_URL}/integration/IS/is-dms-ishare`)
      .pipe(catchError(this.handleError));
  }

  createIShareFirmAndAuthenticate(obj) {
    return this.http.post(`${environment.API_URL}/integration/IS/create-firm`, obj);
  }

  getAllBillingHeadersByCompanyID(companyID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/get-all-billing-headers/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getAllTimeSlipsByCompanyID(companyID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/get-all-billing-headers-slips/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getBillingHeaderByClientIDAndCompanyID(companyID, clientID) {
    return this.http
      .get(
        `${environment.API_URL}/super-admin/get-all-billing-headers-by-client-id/${companyID}/${clientID}`
      )
      .pipe(catchError(this.handleError));
  }

  getAllPaymentHeadersByCompanyID(companyID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/get-all-payment-headers/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getAllPaymentDetailsByCompanyID(companyID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/get-all-payment-details/${companyID}`)
      .pipe(catchError(this.handleError));
  }

  getPaymentDetailByClientIDAndCompanyID(companyID, clientID) {
    return this.http
      .get(
        `${environment.API_URL}/super-admin/get-all-payment-details-by-clientid/${companyID}/${clientID}`
      )
      .pipe(catchError(this.handleError));
  }

  linkPaymentDetailsToBillingHeader(companyID, obj) {
    return this.http
      .post(
        `${environment.API_URL}/super-admin/link-payment-details-to-billing-header/${companyID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  updatePaymentHeaderSuperAdmin(companyID, paymentHeaderID, obj) {
    return this.http
      .put(
        `${environment.API_URL}/super-admin/update-payment-header/${companyID}/${paymentHeaderID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  updateBillingHeaderSuperAdmin(companyID, billingHeaderID, obj) {
    return this.http
      .put(
        `${environment.API_URL}/super-admin/update-billing-header/${companyID}/${billingHeaderID}`,
        obj
      )
      .pipe(catchError(this.handleError));
  }

  createOrUpdatePaymentDetailSuperAdmin(companyID, obj) {
    return this.http
      .post(`${environment.API_URL}/super-admin/create-or-update-payment-detail/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  deleteBillingHeaderSuperAdmin(companyID, billingHeaderID) {
    return this.http
      .delete(
        `${environment.API_URL}/super-admin/delete-billing-header/${companyID}/${billingHeaderID}`
      )
      .pipe(catchError(this.handleError));
  }

  deletePaymentHeaderSuperAdmin(companyID, paymentHeaderID) {
    return this.http
      .delete(
        `${environment.API_URL}/super-admin/delete-payment-header/${companyID}/${paymentHeaderID}`
      )
      .pipe(catchError(this.handleError));
  }

  deletePaymentDetailSuperAdmin(companyID, paymentDetailID) {
    return this.http
      .delete(
        `${environment.API_URL}/super-admin/delete-payment-detail/${companyID}/${paymentDetailID}`
      )
      .pipe(catchError(this.handleError));
  }

  async addAutomaticBudget(project: any) {
    return this.http
      .post(`${environment.API_URL}/capacity-planning/addAutomaticBudget/`, project)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  projectWithNoEngagement(companyId: number, EngagementTypeID: number) {
    return this.http
      .get(
        `${environment.API_URL}/capacity-planning/projecttWithNoEngagement/${companyId}/${EngagementTypeID}`
      )
      .pipe(catchError(this.handleError));
  }

  updateStaxEnum(staxEnumID, obj) {
    return this.http
      .put(`${environment.API_URL}/stax/enums/${staxEnumID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getStaxEnums() {
    return this.http.get(`${environment.API_URL}/stax/enums`).pipe(catchError(this.handleError));
  }

  updateProjectWithNoEngagement(projects: any) {
    return this.http
      .put(`${environment.API_URL}/capacity-planning/projecttWithNoEngagementUpdate/`, projects)
      .pipe(catchError(this.handleError));
  }

  async registerUserToHubSpot(obj) {
    return this.http
      .post(`${environment.API_URL}/hubspot/register-user-to-hubspot`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  canUpdateStax() {
    return this.http
      .get(`${environment.API_URL}/stax/super-admin/access`)
      .pipe(catchError(this.handleError));
  }

  updateStaxValues(obj: any, companyID) {
    return this.http
      .put(`${environment.API_URL}/stax/super-admin/values/${companyID}`, obj)
      .pipe(catchError(this.handleError));
  }

  getSuperAdminCompanySettings(companyID) {
    return this.http
      .get(`${environment.API_URL}/super-admin/company/setting/companyMango/${companyID}`)
      .pipe(catchError(this.handleError));
  }
  //CAPACITY PLANNING JOBS START
  async getUnassignedJobCountWeeks(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getUnassignedJobsCount/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getUnassignedJob(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getUnassignedJob/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getStaffJobCountWeeks(companyID, staffID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getStaffJobsCount/${companyID}/${staffID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getStaffJob(companyID, staffID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getStaffJob/${companyID}/${staffID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getStaffJobAndTime(companyID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getStaffJobAndTime/${companyID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async updateProjectCC(projectHeaderID, obj) {
    return this.http
      .put(`${environment.API_URL}/capacity-planning/projectHeader/update/${projectHeaderID}`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async updateCCTask(projectHeaderID, obj) {
    return this.http
      .put(`${environment.API_URL}/capacity-planning/CCTask/update/${projectHeaderID}`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async updateLicenseInfo(obj) {
    return this.http
      .put(`${environment.API_URL}/update-licenses`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getallProjectData(ProjectHeaderID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getallProjectData/${ProjectHeaderID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async getallStafftData(CompanyID, StaffID) {
    return this.http
      .get(`${environment.API_URL}/capacity-planning/getallStafftData/${CompanyID}/${StaffID}`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async updateDueDateTaskWeekDay(obj) {
    return this.http
      .put(`${environment.API_URL}/capacity-planning/updateDueDateTaskWeekDay/`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  //CAPACITY PLANNING JOBS END

  canUpdateHistory() {
    return this.http
      .get(`${environment.API_URL}/update-history/can-update-history`)
      .pipe(catchError(this.handleError));
  }

  getELProposalsSentCount() {
    return this.http
      .get(`${environment.API_URL}/el-proposals-sent-count`)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async updateAllProjectHeaderCompleted(obj) {
    return this.http
      .put(`${environment.API_URL}/updateAllProjectHeaderCompleted/`, obj)
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  async generatePentahoReport(obj) {
    return this.http
      .post(`${environment.API_URL}/pentaho-api-repos/generate`, obj)
      .pipe(catchError(this.handleError))
      .toPromise() as Promise<{message: string, uri: string}>;
  }
}
